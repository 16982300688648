import React from "react";
import ReactDOM from "react-dom";
import "assets/css/App.css";
import { ChakraProvider } from "@chakra-ui/react";
import theme from "./theme/theme";
//import { AccountProvider } from 'contexts/AccountContext';
//import * as serviceWorkerRegistration from './serviceWorkerRegistration';

import App from "./app";

ReactDOM.render(
  //{/*<AccountProvider>*/}
  <ChakraProvider theme={theme}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </ChakraProvider>,
  //{/*</AccountProvider>*/}
  document.getElementById("root")
);

//serviceWorkerRegistration.register();
