import React, { useState } from "react";
import {
    Box,
    Heading,
    Text,
    Center,
    FormHelperText,
    Select,
    InputGroup,
    InputRightAddon,
    NumberInput,
    NumberInputField,
    Radio,
    RadioGroup,
    HStack,
    Button,
    SimpleGrid,
    FormControl,
    FormLabel,
    Input,
    Flex,
    Stack,
    Textarea,
    Avatar,
} from "@chakra-ui/react";
import NETWORKS from "contracts/Networks.json";

export default function CreateBuyAd(props) {
    const { setBuyStep, buyStep, buyLoading, buyAd, setBuyAd, assets, currencies, paymentMethods, handleCreateBuyAd, chainId, actionType } = props;
    const [stepTitle, setStepTitle] = useState("Instructions");
    return (
        <>
            <Box width="100%" mt="5">
                <Box width="100%" mt="7" mb="7" textAlign="center">
                    <Heading fontSize={{ sm: "sm", md: "md" }} color="white">
                        {stepTitle}
                    </Heading>
                </Box>
                <Box width="100%">
                    <ol className="FormStepList">
                        <li className="FormStepList-item">
                            <span className={`FormStepIcon ${buyStep === 1 ? "is-passed" : ""}`}>
                                <span className="FormStepIcon-circle">1</span>
                                <Text color="white" fontSize={{ sm: "sm", md: "md" }}>
                                    Instructions
                                </Text>
                            </span>
                        </li>
                        <li className="FormStepList-item">
                            <span
                                className={`FormStepIcon ${buyStep === 1 ? "is-active" : ""} ${buyStep === 2 ? "is-passed" : ""
                                    }`}
                            >
                                <span className="FormStepIcon-circle">2</span>
                                <Text color="white" fontSize={{ sm: "sm", md: "md" }}>Offer Preferences</Text>
                            </span>
                        </li>
                        <li className="FormStepList-item">
                            <span
                                className={`FormStepIcon  ${buyStep === 3 ? "is-active" : ""} ${buyStep === 3 ? "is-passed" : ""
                                    }`}
                            >
                                <span className="FormStepIcon-circle">3</span>
                                <Text color="white" fontSize={{ sm: "sm", md: "md" }}>Review & Submit</Text>
                            </span>
                        </li>
                    </ol>
                </Box>
            </Box>

            {buyStep === 1 && (
                <Box width="100%" mt="5" mb="20">
                  <Center>
                    <Stack spacing={6} w={"full"} maxW={"xl"} p={5} my={2} borderRadius="20" borderWidth="1px" borderColor="brand.500">
                        <Box>
                            <Heading fontSize={{ sm: "md", md: "lg" }} color="white">
                                1. Set Offer Preferences
                            </Heading>
                            <Text color="white" fontSize={{ sm: "sm", md: "md" }}>
                                Select the desired currency, specify the amount, set your preferred payment method, and determine the exchange rate. Ensure that all the details accurately reflect your trading intentions
                            </Text>
                        </Box>
                        <Box>
                            <Heading fontSize={{ sm: "md", md: "lg" }} color="white">
                                2. Review Your Offer
                            </Heading>
                            <Text color="white" fontSize={{ sm: "sm", md: "md" }}>Carefully review all the details of your offer to ensure accuracy. Once you are satisfied with the information, submit your offer for publishing.</Text>
                        </Box>
                        <Box>
                            <Heading fontSize={{ sm: "md", md: "lg" }} color="white">
                                3. Share Your Offer
                            </Heading>
                            <Text color="white" fontSize={{ sm: "sm", md: "md" }}>
                                Share the offer URL with potential trade partners. This will allow them to view and engage with your offer
                            </Text>
                        </Box>
                    </Stack>
                   </Center>
       
                    <Box with="100%" textAlign="center" mt="2">
                        <Button
                            variant={"outline"}
                            size="md"
                            color="white"
                            fontSize={{ sm: "sm", md: "md" }}
                            colorScheme="secondary.500"
                            onClick={(e) => {
                                setBuyStep(2);
                                setStepTitle("Offer Preferences");
                            }}
                        >
                            Continue
                        </Button>
                    </Box>
                </Box>
            )}

            {buyStep === 2 && (
                <Box width="100%" mt="5" mb="50">
                    <Center>
                        <Stack spacing={4} w={"full"} maxW={"2xl"} my={2} p={{sm: 3, md: 7}} borderRadius="10" borderWidth="1px" borderColor="brand.500">
                            <FormControl>
                                <FormLabel color={"white"} fontWeight="bold">
                                    <Flex>
                                        <Text mt="1" fontSize={{ sm: "sm", md: "md" }}>Asset</Text> &nbsp;&nbsp;
                                        {chainId !== undefined && <Avatar mt="0" src={NETWORKS[chainId]['ICONURL']} size="sm" />}
                                    </Flex>
                                </FormLabel>
                                <RadioGroup value={buyAd.asset}>
                                    <Stack spacing={5} direction='row' >
                                        {
                                            assets !== null && assets.map((asset) => (
                                                <>
                                                    <Radio size='md' key={asset._id} value={asset.symbol}
                                                        borderColor="secondary.500"
                                                        onChange={(e) => {
                                                            setBuyAd(prevBuyAd => ({
                                                                ...prevBuyAd,
                                                                assetPrice: asset.rateToDollar["$numberDecimal"],
                                                                asset: asset.symbol,
                                                                price: Number(asset.rateToDollar["$numberDecimal"] * prevBuyAd.currencyRate * Number(prevBuyAd.factor / 100)).toFixed(2)
                                                            }));
                                                        }}
                                                    ><Text color="white" fontSize={{ sm: "sm", md: "md" }}>{asset.symbol.toUpperCase()}</Text></Radio> &nbsp;&nbsp;&nbsp;
                                                </>
                                            ))
                                        }
                                    </Stack>
                                </RadioGroup>
                            </FormControl>


                            <FormControl mt="3">
                                <FormLabel color={"white"} fontWeight="bold" fontSize={{ sm: "sm", md: "md" }}>
                                    Currency
                                </FormLabel>
                                <Select
                                    borderColor="brand.500"
                                    color="white"
                                    required={true}
                                    placeholder="Select Currency"
                                    value={buyAd.currency}
                                    onChange={(e) => {
                                        const currency = currencies.find(currency => currency.symbol === e.target.value);
                                        setBuyAd(prevBuyAd => ({
                                            ...prevBuyAd,
                                            currency: currency.symbol,
                                            price: Number(currency.rateToDollar["$numberDecimal"] * prevBuyAd.assetPrice * Number(prevBuyAd.factor / 100)).toFixed(2),
                                            currencyRate: currency.rateToDollar["$numberDecimal"]
                                        }));
                                    }}>
                                    {
                                        currencies !== null && currencies.map((currency) => (
                                            <>
                                                <option key={currency._id} value={currency.symbol} fontSize={{ sm: "sm", md: "md" }}>{currency.symbol.toUpperCase()} </option>
                                            </>
                                        ))
                                    }
                                </Select>
                            </FormControl>


                            <FormControl mt="3">
                                <FormLabel color={"white"} fontWeight="bold" fontSize={{ sm: "sm", md: "md" }}>
                                    Price Type
                                </FormLabel>
                                <RadioGroup value={buyAd.priceType}>
                                    <Stack spacing={5} direction='row'>
                                        <Radio key={1} size='md' value={"floating"}
                                            borderColor="secondary.500"
                                            onChange={(e) => {
                                                setBuyAd(prevBuyAd => ({
                                                    ...prevBuyAd,
                                                    priceType: "floating"
                                                }));
                                            }}
                                        > <Text color="white" fontSize={{ sm: "sm", md: "md" }}>Floating</Text></Radio>&nbsp;&nbsp;&nbsp;
                                        <Radio key={1} size='md' value={"fixed"}
                                            borderColor="secondary.500"
                                            onChange={(e) => {
                                                setBuyAd(prevBuyAd => ({
                                                    ...prevBuyAd,
                                                    priceType: "fixed"
                                                }));
                                            }}> <Text color="white" fontSize={{ sm: "sm", md: "md" }}>Fixed</Text></Radio>&nbsp;&nbsp;&nbsp;
                                    </Stack>
                                </RadioGroup>
                            </FormControl>

                            <SimpleGrid columns={2} spacing="2" mt="3">
                                <Box>
                                    <FormLabel color={"white"} fontWeight="bold" fontSize={{ sm: "sm", md: "md" }}>
                                        Price
                                    </FormLabel>
                                    <Box>
                                        <Text color="white" fontSize={{ sm: "sm", md: "md" }}>
                                            {buyAd.currency} {new Intl.NumberFormat('en-US', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(Number(buyAd.assetPrice) * Number(buyAd.currencyRate) * Number(buyAd.factor / 100))}
                                        </Text>
                                    </Box>
                                </Box>
                                <Box>
                                    <FormControl>
                                        <FormLabel color={"white"} fontWeight="bold" fontSize={{ sm: "sm", md: "md" }}>
                                            Adjust Price
                                        </FormLabel>
                                        <Box>
                                            <HStack maxW='320px'>
                                                <Button
                                                    background="secondary.500"
                                                    onClick={() => {
                                                        const newFactor = (Number(buyAd.factor) - 0.01).toFixed(2);
                                                        setBuyAd(prevBuyAd => ({
                                                            ...prevBuyAd,
                                                            factor: newFactor,
                                                            price: (Number(prevBuyAd.assetPrice) * Number(prevBuyAd.currencyRate) * (newFactor / 100)).toFixed(2)
                                                        }));
                                                    }}
                                                >
                                                    -
                                                </Button>
                                                <Input
                                                    borderColor="brand.500"
                                                    value={buyAd.factor}
                                                    color="white"
                                                    onChange={(e) => {
                                                        const newFactor = e.target.value;
                                                        setBuyAd(prevBuyAd => ({
                                                            ...prevBuyAd,
                                                            factor: newFactor,
                                                            price: (Number(prevBuyAd.assetPrice) * Number(prevBuyAd.currencyRate) * (newFactor / 100)).toFixed(2)
                                                        }));
                                                    }}
                                                />
                                                <Button
                                                    background="secondary.500"
                                                    onClick={() => {
                                                        const newFactor = (Number(buyAd.factor) + 0.01).toFixed(2);
                                                        setBuyAd(prevBuyAd => ({
                                                            ...prevBuyAd,
                                                            factor: newFactor,
                                                            price: (Number(prevBuyAd.assetPrice) * Number(prevBuyAd.currencyRate) * (newFactor / 100)).toFixed(2)
                                                        }));
                                                    }}
                                                >
                                                    +
                                                </Button>
                                            </HStack>
                                            {buyAd.priceType === "floating" && <Text mt="2" textAlign="center" color="white" fontSize={{ sm: "sm", md: "md" }}>Also price factor</Text>}
                                        </Box>
                                    </FormControl>
                                </Box>
                            </SimpleGrid>


                            <FormControl>
                                <FormLabel color={'white'} fontWeight="bold" fontSize={{ sm: "sm", md: "md" }}>
                                    Total Amount
                                </FormLabel>
                                <NumberInput defaultValue={buyAd.totalAsset} borderColor="brand.500">
                                    <NumberInputField
                                        color="white"
                                        required={true}
                                        onChange={(e) => {
                                            setBuyAd(prevBuyAd => ({
                                                ...prevBuyAd,
                                                totalAsset: e.target.value
                                            }));
                                        }}
                                    />
                                </NumberInput>
                                <FormHelperText>
                                    {buyAd.totalAsset}  {buyAd?.asset?.toUpperCase()}  = {new Intl.NumberFormat('en-US', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(Number(buyAd.price) * Number(buyAd.totalAsset))} {buyAd.currency}
                                </FormHelperText>
                            </FormControl>

                            <Stack spacing={5} direction='row'>
                                <Box>
                                    <FormControl>
                                        <FormLabel color={'white'} fontWeight="bold" fontSize={{ sm: "sm", md: "md" }}>
                                            Min Order Limit
                                        </FormLabel>

                                        <InputGroup size='sm' borderColor="brand.500">
                                            <NumberInput defaultValue={buyAd.lowerLimit} borderColor="brand.500">
                                                <NumberInputField
                                                    color="white"
                                                    required={true}
                                                    onChange={(e) => {
                                                        setBuyAd(prevBuyAd => ({
                                                            ...prevBuyAd,
                                                            lowerLimit: e.target.value
                                                        }));
                                                    }}
                                                />
                                            </NumberInput>
                                            <InputRightAddon background="secondary.500">{buyAd.currency}</InputRightAddon>
                                        </InputGroup>

                                    </FormControl>
                                </Box>

                                <Box>
                                    <FormControl>
                                        <FormLabel color={'white'} fontWeight="bold" fontSize={{ sm: "sm", md: "md" }}>
                                            Max Order Limit
                                        </FormLabel>
                                        <InputGroup size='sm'>
                                            <NumberInput defaultValue={buyAd.upperLimit} borderColor="brand.500">
                                                <NumberInputField
                                                    color="white"
                                                    required={true}
                                                    onChange={(e) => {
                                                        setBuyAd(prevBuyAd => ({
                                                            ...prevBuyAd,
                                                            upperLimit: e.target.value
                                                        }));
                                                    }}
                                                />
                                            </NumberInput>
                                            <InputRightAddon background="secondary.500">{buyAd.currency}</InputRightAddon>
                                        </InputGroup>
                                    </FormControl>
                                </Box>
                            </Stack>

                            <FormControl>
                                <FormLabel color={'white'} fontWeight="bold" fontSize={{ sm: "sm", md: "md" }}>
                                    Payment Time Limit
                                </FormLabel>
                                <Select
                                    borderColor="brand.500"
                                    color="white"
                                    required={true}
                                    placeholder="Select Deadline"
                                    value={buyAd.deadline}
                                    fontSize={{ sm: "sm", md: "md" }}
                                    onChange={(e) => {
                                        setBuyAd(prevBuyAd => ({
                                            ...prevBuyAd,
                                            deadline: e.target.value,
                                        }));
                                    }}
                                >
                                    <option value="15">15 Min</option>
                                    <option value="30">30 Min</option>
                                    <option value="45">45 Min</option>
                                    <option value="60">60 Min</option>
                                </Select>
                            </FormControl>

                            <FormControl>
                                <FormLabel color={'white'} fontWeight="bold" fontSize={{ sm: "sm", md: "md" }}>
                                    Payment Option
                                </FormLabel>
                                <Select
                                    borderColor="brand.500"
                                    color="white"
                                    fontSize={{ sm: "sm", md: "md" }}
                                    required={true}
                                    placeholder="Select Payment Option"
                                    defaultValue={buyAd.paymentMethod}
                                    onChange={(e) => {
                                        setBuyAd(prevBuyAd => ({
                                            ...prevBuyAd,
                                            paymentMethod: e.target.value,
                                        }));
                                    }}
                                >
                                    {
                                        paymentMethods && paymentMethods.map((method) => {
                                            //let det = JSON.parse(method.details);
                                            return (
                                                <option value={method._id}>{method?.name}</option>
                                            )
                                        })
                                    }
                                </Select>
                            </FormControl>

                            <FormControl>
                                <FormLabel color={'white'} fontWeight="bold" fontSize={{ sm: "sm", md: "md" }}>
                                    Instruction
                                </FormLabel>
                                <Textarea
                                    borderColor="brand.500"
                                    color="white"
                                    required={true}
                                    defaultValue={buyAd.notes}
                                    onChange={(e) => {
                                        setBuyAd(prevBuyAd => ({
                                            ...prevBuyAd,
                                            notes: e.target.value,
                                        }));
                                    }}
                                    size='sm'
                                />
                            </FormControl>


                            <Box with="100%">
                                <Heading size="sm" color="white" fontSize={{ sm: "sm", md: "md" }}>Counterpart Conditions</Heading>
                            </Box>

                            <FormControl mt="3">
                                <FormLabel color={"white"} fontWeight="bold" fontSize={{ sm: "sm", md: "md" }}>
                                    KYC Status
                                </FormLabel>
                                <RadioGroup value={buyAd.conditions.kycVerified}>
                                    <Stack spacing={5} direction='row'>
                                        <Radio size='md' name="kycVerified" value={"true"}
                                            borderColor="secondary.500"
                                            onChange={(e) => {
                                                setBuyAd(prevBuyAd => ({
                                                    ...prevBuyAd,
                                                    conditions: { kycVerified: e.target.value }
                                                }));
                                            }}
                                        > <Text color="white" fontSize={{ sm: "sm", md: "md" }}>Verified Users</Text></Radio>&nbsp;&nbsp;

                                        <Radio size='md' name="kycVerified" value={"false"}
                                            borderColor="secondary.500"
                                            onChange={(e) => {
                                                setBuyAd(prevBuyAd => ({
                                                    ...prevBuyAd,
                                                    conditions: { kycVerified: e.target.value }
                                                }));
                                            }}
                                        > <Text color="white" fontSize={{ sm: "sm", md: "md" }}>Everyone</Text></Radio>&nbsp;&nbsp;

                                    </Stack>
                                </RadioGroup>
                            </FormControl>

                            <Box width={{ sm: "100%", md: "100%" }}>
                                <Flex justifyContent="space-between" width="100%">
                                    <Button
                                        mt="2"
                                        fontSize={{ sm: "sm", md: "md" }}
                                        onClick={(e) => {
                                            setBuyStep(1);
                                            setStepTitle("Instructions");
                                        }}
                                    >
                                        Prev
                                    </Button>
                                    <Button
                                        background="secondary.500"
                                        color="white"
                                        fontSize={{ sm: "sm", md: "md" }}
                                        _hover={{ color: "#000000", background: "#ffffff" }}
                                        mt="2"
                                        onClick={(e) => {
                                            setBuyStep(3);
                                            setStepTitle("Review and Submit");
                                        }}
                                    >
                                        Next
                                    </Button>
                                </Flex>
                            </Box>
                        </Stack>
                    </Center>

                </Box>
            )}

            {buyStep === 3 && (
                <Box width="100%" mt="50" mb="50">
                    <Center>
                        <Stack spacing={6} w={"full"} maxW={"2xl"} p={7} borderRadius="20" my={2} borderWidth="1px" borderColor="secondary.500">

                            <SimpleGrid columns={2} spacing="2">
                                <Box>
                                    <Heading fontSize={{ sm: "sm", md: "md" }} color="white">Asset</Heading>
                                    <Text color="white" fontSize={{ sm: "sm", md: "md" }}>{buyAd.asset}</Text>
                                </Box>
                                <Box>
                                    <Heading fontSize={{ sm: "sm", md: "md" }} color="white">Currency</Heading>
                                    <Text color="white" fontSize={{ sm: "sm", md: "md" }}>{buyAd.currency}</Text>
                                </Box>
                            </SimpleGrid>

                            <SimpleGrid columns={2} spacing="2" mt="2">
                                <Box>
                                    <Heading fontSize={{ sm: "sm", md: "md" }} color="white">Asset Amount</Heading>
                                    <Text color="white" fontSize={{ sm: "sm", md: "md" }}>{new Intl.NumberFormat('en-US', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 }).format((buyAd.totalAsset))} {buyAd.asset}</Text>
                                </Box>
                                <Box>
                                    <Heading fontSize={{ sm: "sm", md: "md" }} color="white">Estimated Amount</Heading>
                                    <Text color="white" fontSize={{ sm: "sm", md: "md" }}>{new Intl.NumberFormat('en-US', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(Number(buyAd.price) * Number(buyAd.totalAsset))} {buyAd.currency}</Text>
                                </Box>
                            </SimpleGrid>


                            <SimpleGrid columns={2} spacing="2" mt="2">
                                <Box>
                                    <Heading fontSize={{ sm: "sm", md: "md" }} color="white">Price</Heading>
                                    <Text color="white" fontSize={{ sm: "sm", md: "md" }}>{new Intl.NumberFormat('en-US', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(Number(buyAd.assetPrice) * Number(buyAd.currencyRate) * Number(buyAd.factor / 100))} {buyAd.currency} / {buyAd.asset}</Text>
                                </Box>
                                <Box>
                                    <Heading fontSize={{ sm: "sm", md: "md" }} color="white">Estimated Fee</Heading>
                                    <Text color="white" fontSize={{ sm: "sm", md: "md" }}>{new Intl.NumberFormat('en-US', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(Number(buyAd.totalAsset) * 0.01)} {buyAd.asset} </Text>
                                </Box>
                            </SimpleGrid>


                            <SimpleGrid columns={2} spacing="2" mt="2">
                                <Box>
                                    <Heading color="white" fontSize={{ sm: "sm", md: "md" }}>Order Limit</Heading>
                                    <Text color="white" fontSize={{ sm: "sm", md: "md" }}>{new Intl.NumberFormat('en-US', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(buyAd.lowerLimit)} {buyAd.currency} - {new Intl.NumberFormat('en-US', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(buyAd.upperLimit)} {buyAd.currency}</Text>
                                </Box>
                                <Box>
                                    <Heading color="white" fontSize={{ sm: "sm", md: "md" }}>Payment Method</Heading>
                                    <Text color="white" fontSize={{ sm: "sm", md: "md" }}>
                                        {paymentMethods.find((p) => p._id === buyAd.paymentMethod)?.name}</Text>
                                </Box>
                            </SimpleGrid>


                            <SimpleGrid columns={2} spacing="2" mt="2">
                                <Box>
                                    <Heading color="white" fontSize={{ sm: "sm", md: "md" }}>KYC Status</Heading>
                                    <Text color="white" fontSize={{ sm: "sm", md: "md" }}>{buyAd.conditions.kycVerified === "true" ? "Verified User" : "Everyone"} </Text>
                                </Box>
                                <Box>
                                </Box>
                            </SimpleGrid>

                            <Box width={{ sm: "100%", md: "70%" }} pt="10">
                                <Flex justifyContent="space-between" width="100%">
                                    <Button
                                        mt="2"
                                        onClick={(e) => {
                                            setBuyStep(2);
                                            setStepTitle("Ad Preferences");
                                        }}
                                    >
                                        Prev
                                    </Button>
                                    <Button
                                        background="secondary.500"
                                        color="white"
                                        _hover={{ color: "#000000", background: "#ffffff" }}
                                        mt="2"
                                        isLoading={buyLoading}
                                        loadingText="Please Wait"
                                        onClick={(e) => {
                                            handleCreateBuyAd();
                                        }}
                                    >
                                        Submit
                                    </Button>
                                </Flex>
                            </Box>
                        </Stack>
                    </Center>
                </Box>
            )}
        </>
    );
};
