import React from "react";
import { Icon } from "@chakra-ui/react";
import { FiUsers, FiUser, FiHome, FiAlertCircle, FiAirplay, FiUserPlus, FiShield } from "react-icons/fi";
import {
  FaHandshake, FaCog, FaGavel, FaAlert, FaHistory
} from "react-icons/fa";

import Dashboard from "views/admin/dashboard";
import TradeLayout from "views/admin/trades/layout";
import ActiveBuyTrades from "views/admin/trades/activebuytrades";
import ActiveSellTrades from "views/admin/trades/activeselltrades";
import BuyTrades from "views/admin/trades/buytrades";
import SellTrades from "views/admin/trades/selltrades";
import DisputedBuyTrades from "views/admin/trades/disputedbuytrades";
import DisputedSellTrades from "views/admin/trades/disputedselltrades";
import AssignedDisputedBuyTrades from "views/admin/trades/assigneddisputedbuytrades";
import AssignedDisputedSellTrades from "views/admin/trades/assigneddisputedselltrades";
import ResolvedDisputedBuyTrades from "views/admin/trades/resolveddisputedbuytrades";
import ResolvedDisputedSellTrades from "views/admin/trades/resolveddisputedselltrades";

import Trade from "views/admin/trades/trade";

import SettingsLayout from "views/admin/settings/layout";
import Roles from "views/admin/settings/roles";
import AddRole from "views/admin/settings/addrole";

import Users from "views/admin/users";
import Admins from "views/admin/users/admins";
import UserLayout from "views/admin/users/layout";
import AddUser from "views/admin/users/adduser";


const routes = [
  {
    name: "Dashboard",
    nameAbbrv: "Dashboard",
    layout: "/admin",
    path: "dashboard",
    icon: <Icon as={FiHome} width="20px" height="20px" />,
    component: <Dashboard />,
    collapse: false,
    show: true,
    allow: ["Admin", "Manage Trades"],
  },
  {
    name: "Trades",
    nameAbbrv: "Trades",
    layout: "/admin",
    path: "trades",
    icon: <Icon as={FaHandshake} width="20px" height="20px" color="#ffffff" />,
    component: <TradeLayout />,
    collapse: true,
    show: true,
    allow: ["Admin", "Manage Trades", "Manage Disputes"], 
    items: [
      {
        name: "Active Buy Trades",
        layout: "/admin",
        path: "activebuys",
        icon:  <Icon as={FiAirplay} width="20px" height="20px" color="white" />,
        component: <ActiveBuyTrades />,
        collapse: false,
        show: true,
        allow: ["Admin", "Manage Trades"],
      },
      {
        name: "Active Sell Trades",
        layout: "/admin",
        path: "activesells",
        icon: <Icon as={FiAirplay} width="20px" height="20px" color="white" />,
        component: <ActiveSellTrades />,
        collapse: false,
        show: true,
        allow: ["Admin", "Manage Trades"],
      },
      {
        name: "Buy Trade History",
        layout: "/admin",
        path: "buytrades",
        icon:  <Icon as={FaHistory} width="20px" height="20px" color="white" />,
        component: <BuyTrades />,
        collapse: false,
        show: true,
        allow: ["Admin", "Manage Trades"],
      },
      {
        name: "Sell Trade History",
        layout: "/admin",
        path: "selltrades",
        icon:  <Icon as={FaHistory} width="20px" height="20px" color="white" />,
        component: <SellTrades />,
        collapse: false,
        show: true,
        allow: ["Admin", "Manage Trades"],
      },
      {
        name: "Disputed Buy Trades",
        layout: "/admin",
        path: "disputedbuytrades",
        icon:  <Icon as={FaGavel} width="20px" height="20px" color="white" />,
        component: <DisputedBuyTrades />,
        collapse: false,
        show: true,
        allow: ["Admin"],
      },
      {
        name: "Disputed Sell Trades",
        layout: "/admin",
        path: "disputedselltrades",
        icon:  <Icon as={FaGavel} width="20px" height="20px" color="white" />,
        component: <DisputedSellTrades />,
        collapse: false,
        show: true,
        allow: ["Admin"],
      },
      {
        name: "Trade",
        layout: "/admin",
        path: "trade/:tradeId",
        icon:  <Icon as={FaGavel} width="20px" height="20px" color="white" />,
        component: <Trade />,
        collapse: false,
        show: false,
        allow: ["Admin", "Manage Trades", "Manage Disputes"],
      },
      {
        name: "Assigned Buy Disputes",
        layout: "/admin",
        path: "assigneddisputedbuys",
        icon:  <Icon as={FaGavel} width="20px" height="20px" color="white" />,
        component: <AssignedDisputedBuyTrades />,
        collapse: false,
        show: true,
        allow: ["Admin", "Manage Trades", "Manage Disputes"],
      },
      {
        name: "Assigned Sell Disputes",
        layout: "/admin",
        path: "assigneddisputedsells",
        icon:  <Icon as={FaGavel} width="20px" height="20px" color="white" />,
        component: <AssignedDisputedSellTrades />,
        collapse: false,
        show: true,
        allow: ["Admin", "Manage Trades", "Manage Disputes"],
      },
      {
        name: "Resolved Buy Disputes",
        layout: "/admin",
        path: "resolveddisputedbuys",
        icon:  <Icon as={FaGavel} width="20px" height="20px" color="white" />,
        component: <ResolvedDisputedBuyTrades />,
        collapse: false,
        show: true,
        allow: ["Admin"],
      },
      {
        name: "Resolved Sell Disputes",
        layout: "/admin",
        path: "resolveddisputedsells",
        icon:  <Icon as={FaGavel} width="20px" height="20px" color="white" />,
        component: <ResolvedDisputedSellTrades />,
        collapse: false,
        show: true,
        allow: ["Admin"],
      },
    ]
  },
  {
    name: "Settings",
    nameAbbrv: "Settings",
    layout: "/admin",
    path: "settings",
    icon: <Icon as={FaCog} width="20px" height="20px" color="white" />,
    component: <SettingsLayout />,
    collapse: true,
    show: true,
    allow: ["Admin", "Settings"],
    items: [
      {
        name: "User Roles",
        layout: "/admin",
        path: "roles",
        icon: (
          <Icon as={FiShield} width="20px" height="20px" color="white" />
        ),
        component: <Roles />,
        collapse: false,
        show: true,
        allow: ["Admin", "Settings"],
      },
      {
        name: "Roles",
        layout: "/admin",
        path: "roles",
        icon: (
          <Icon as={FaCog} width="20px" height="20px" color="white" />
        ),
        component: <Roles />,
        collapse: false,
        show: false,
        allow: ["Admin", "Settings"],
      },
      {
        name: "Add Role",
        layout: "/admin",
        path: "addrole",
        icon: (
          <Icon as={FaCog} width="20px" height="20px" color="white" />
        ),
        component: <AddRole />,
        collapse: false,
        show: false,
        allow: ["Admin", "Settings"],
      },
      {
        name: "Add Role",
        layout: "/admin",
        path: "addrole/:roleId",
        icon: (
          <Icon as={FaCog} width="20px" height="20px" color="white" />
        ),
        component: <AddRole />,
        collapse: false,
        show: false,
        allow: ["Admin", "Settings"],
      },
    ],
  },
  {
    name: "Manage Users",
    nameAbbrv: "Members",
    layout: "/admin",
    path: "users",
    icon: <Icon as={FiUsers} width="20px" height="20px" color="white" />,
    component: <UserLayout />,
    collapse: true,
    show: true,
    allow: ["Admin", "Manage Users"],
    items: [
      {
        name: "Users",
        layout: "/admin",
        path: "accounts",
        icon: (
          <Icon as={FiUser} width="20px" height="20px" color="white" />
        ),
        component: <Users />,
        collapse: false,
        show: true,
        allow: ["Admin", "Manage Users"],
      },
      {
        name: "Admin Users",
        layout: "/admin",
        path: "admins",
        icon: (
          <Icon as={FiUserPlus} width="20px" height="20px" color="white" />
        ),
        component: <Admins />,
        collapse: false,
        show: true,
        allow: ["Admin", "Manage Users"],
      },
      {
        name: "Update User",
        layout: "/admin",
        path: "adduser/:userId",
        icon: (
          <Icon as={FiUser} width="20px" height="20px" color="white" />
        ),
        component: <AddUser />,
        collapse: false,
        show: false,
        allow: ["Admin", "Manage Users"],
      },
    ],
  },
];

export default routes;


