import { http, createConfig } from 'wagmi'
import { baseSepolia, bscTestnet } from 'wagmi/chains'
import { coinbaseWallet, walletConnect } from 'wagmi/connectors'

export const config = createConfig({
  chains: [baseSepolia, bscTestnet],
  connectors: [
    coinbaseWallet({ appName: 'Scrowed', preference: 'smartWalletOnly', appLogoUrl: 'https://res.cloudinary.com/duci5ynen/image/upload/v1728262786/localfood/kbldhdat062aykp60ypc.png', projectId: "47213ff5-1789-47e8-9c55-630f942d3dcc" }),
    walletConnect({
      projectId: 'bdc0e49ac81f7365eb0bb0d6aea975a0',
      metadata: {
        name: 'Scrowed',
        description: 'Scrowed',
        url: 'https://reown.com/appkit', // origin must match your domain & subdomain
        icons: ['https://res.cloudinary.com/duci5ynen/image/upload/v1728262786/localfood/kbldhdat062aykp60ypc.png']
      }
    }),
    //metaMask()
  ],
  transports: {
    //[sepolia.id]: http(),
    [baseSepolia.id]: http(),
    [bscTestnet.id]: http(),
  },
});