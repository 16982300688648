import {
  Button,
  Input,
  FormControl,
  FormLabel,
  FormHelperText
} from "@chakra-ui/react";
import Card from "components/card/Card";

export default function DateFilter(props) {
  const { date, setDate, loading, initFilterSubmit } = props;
  return (
    <Card
      w={{ sm: "100%", md: "50%" }}
      h='200px'
      p='20px'
      mb='20px'
    >
      <FormControl>
        <FormLabel htmlFor='date' fontWeight="bold">Filter By Date</FormLabel>
        <Input id='date' type='date' defaultValue={date} onChange={(e) => setDate(e.target.value)} />
        <FormHelperText>Enter Date.</FormHelperText>
      </FormControl>
      <Button
        mt="5"
        size="md"
        variant='brand'
        fontWeight='500'
        isLoading={loading}
        loadingText="Wait.."
        onClick={(e) => { e.preventDefault(); initFilterSubmit(); }} >
        Find
      </Button>
    </Card>
  );
}