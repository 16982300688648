import React from "react";
import { Icon } from "@chakra-ui/react";
import { FaUser, FaHandshake, FaCoins } from "react-icons/fa";
import { AiFillNotification, AiFillWallet } from "react-icons/ai";

import BuyOffers from "views/app/ads/buyoffers";
import Trades from "views/app/trades";
import Profile from "views/app/profile";
import Wallet from "views/app/wallet";
import Faucet from "views/app/faucet";

const routes = [
  {
    name: "Ad",
    layout: "/app",
    path: "ads",
    icon: <Icon as={AiFillNotification} width={{sm:"13px", md:"20px"}} height={{sm:"13px", md:"20px"}} color="white" />,
    component: <BuyOffers />,
    collapse:false
  },
  {
    name: "Trade",
    layout: "/app",
    path: "trades",
    icon: <Icon as={FaHandshake} width={{sm:"13px", md:"20px"}} height={{sm:"13px", md:"20px"}} color="white" />,
    component: <Trades />,
    collapse:false
  },
  {
    name: "Wallet",
    layout: "/app",
    path: "wallet",
    icon: <Icon as={AiFillWallet} width={{sm:"13px", md:"20px"}} height={{sm:"13px", md:"20px"}} color="white" />,
    component: <Wallet />,
    collapse:false
  },
  {
    name: "Profile",
    layout: "/app",
    path: "profile",
    icon: <Icon as={FaUser} width={{sm:"13px", md:"20px"}} height={{sm:"13px", md:"20px"}} color="white" />,
    component: <Profile />,
    collapse:false
  },
  {
    name: "Faucet",
    layout: "/app",
    path: "faucet",
    icon: <Icon as={FaCoins} width={{sm:"13px", md:"20px"}} height={{sm:"13px", md:"20px"}} color="white" />,
    component: <Faucet />,
    collapse:false
  }
  ];

export default routes;

