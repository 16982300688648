import { simulateContract,  waitForTransactionReceipt, readContract, writeContract, getAccount, getChainId } from '@wagmi/core';
import { getAddress, parseEther } from 'viem'
import axiosInstance from './axiosInstance'; // Adjust the path accordingly
import FAUCETABI from "contracts/Faucet.json";
import NETWORKS from "contracts/Networks.json";
import USDTABI from "contracts/USDTToken.json";

import { config } from 'variables/connector';
import TOKENABI from "contracts/IBEP20.json";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

const ContractGetTokens = async (asset) => {
  try {
    const { connector } = getAccount(config);
    const chainId = getChainId(config);
    const FAUCET_CONTRACT = NETWORKS[chainId]["FAUCET_CONTRACT"];

    const hash = await writeContract(config, {
      abi: FAUCETABI.abi,
      address: FAUCET_CONTRACT,
      functionName: 'withdraw',
      args: [asset],
      chainId: chainId,
      connector
    });

    await waitForTransactionReceipt(config, {
      hash: hash,
    })

    return true;
  } catch (error) {
    let errorMessage = "An unexpected error occurred";
    if (error.hasOwnProperty("code")) {
      switch (error.code) {
        case 4001:
          errorMessage = "Transaction rejected by user";
          break;
        case "ACTION_REJECTED":
          errorMessage = "Transaction rejected by user";
          break;
        default:
          errorMessage = error.message || "An unexpected error occurred";
      }
    } else if (error.hasOwnProperty("error")) {
      if (error.error.hasOwnProperty("data") && error.error.data.hasOwnProperty("message")) {
        errorMessage = error.error.data.message;
      } else if (error.error.hasOwnProperty("message")) {
        errorMessage = error.error.message;
      }
    } else if (error.hasOwnProperty("message")) {
      errorMessage = error.message;
    }
    throw new Error(`Transaction failed: ${errorMessage}`);
  }
}

const ContractFaucetContracts = async () => {
  try {
    const { connector } = getAccount(config);
    const chainId = getChainId(config);
    const FAUCET_CONTRACT = NETWORKS[chainId]["FAUCET_CONTRACT"];
   
    const result = await readContract(config, {
      abi: FAUCETABI.abi,
      address: FAUCET_CONTRACT,
      functionName: 'getTokenInfo',
      args: [],
      chainId: chainId,
      connector
    });
    return result;
  } catch (error) {
    if (
      error.hasOwnProperty("data") &&
      error.hasOwnProperty("data") &&
      error.data.hasOwnProperty("message")
    ) {
      throw new Error(error.data.message);
    }
    else if (
      error.hasOwnProperty("error") &&
      error.error.hasOwnProperty("data") &&
      error.error.data.hasOwnProperty("message")
    ) {
      throw new Error(error.error.data.message);
    }
    else {
      throw new Error(error.message);
    }
  }
}

const ContractFaucetContractToken = async (tokenContract) => {
  try {

    const { connector } = getAccount(config);
    const chainId = getChainId(config);

    const result = await readContract(config, {
       abi: USDTABI.abi,
       address: tokenContract,
       functionName: 'getTokenInfo',
       args: [],
       chainId: chainId,
       connector
    });

    const [name, symbol, contractAddress, decimals] = result;
    return { name, symbol, contractAddress };
    
  } catch (error) {
    let errorMessage = "An unexpected error occurred";
    if (error.hasOwnProperty("code")) {
      switch (error.code) {
        case 4001:
          errorMessage = "Transaction rejected by user";
          break;
        case "ACTION_REJECTED":
          errorMessage = "Transaction rejected by user";
          break;
        default:
          errorMessage = error.message || "An unexpected error occurred";
      }
    } else if (error.hasOwnProperty("error")) {
      if (error.error.hasOwnProperty("data") && error.error.data.hasOwnProperty("message")) {
        errorMessage = error.error.data.message;
      } else if (error.error.hasOwnProperty("message")) {
        errorMessage = error.error.message;
      }
    } else if (error.hasOwnProperty("message")) {
      errorMessage = error.message;
    }
    throw new Error(`Transaction failed: ${errorMessage}`);
  }
}

const Withdraw = async (faucetData, token) => {
  try {
    const response = await axiosInstance.post(
      SERVER_URL + "/faucet",
      faucetData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        mode: "cors",
      }
    );
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
}

const CheckFaucetWithdrawal = async (faucetData, token) => {
  try {
    const response = await axiosInstance.post(
      SERVER_URL + "/faucet/checkWithdrawal", faucetData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        mode: "cors",
      }
    );
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
}

export {
  Withdraw,
  ContractGetTokens,
  ContractFaucetContractToken,
  CheckFaucetWithdrawal,
  ContractFaucetContracts
};
