import {
  Box,
  chakra,
  Container,
  Image,
  Heading,
  Flex,
  SimpleGrid,
  Stack,
  Text,
  VisuallyHidden,
  Input,
  IconButton,
  useColorModeValue,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import {
  FaFacebook,
  FaInstagram,
  FaTwitter,
  FaYoutube,
  FaTelegram,
} from "react-icons/fa";
import { BiMailSend } from "react-icons/bi";
import LogoWhite from "assets/images/logo-long.png";

const Logo = (props) => {
  return (
    <>
      <Flex>
        <Image
          height="auto"
          width={{sm:"120px", lg:"200px"}}
          src={LogoWhite}
          alt="Scrowed"
          mt="2"
        />
      </Flex>
    </>
  );
};

const SocialButton = ({ children, label, href }) => {
  return (
    <chakra.button
      bg={useColorModeValue("blackAlpha.100", "whiteAlpha.100")}
      rounded={"full"}
      w={8}
      h={8}
      cursor={"pointer"}
      as={"a"}
      href={href}
      display={"inline-flex"}
      alignItems={"center"}
      justifyContent={"center"}
      transition={"background 0.3s ease"}
      _hover={{
        bg: useColorModeValue("blackAlpha.200", "whiteAlpha.200"),
      }}
      color="#fff"
    >
      <VisuallyHidden>{label}</VisuallyHidden>
      {children}
    </chakra.button>
  );
};

const ListHeader = ({ children }) => {
  return (
    <Text
      fontWeight={"500"}
      fontSize={"lg"}
      mb={2}
      color="#fff"
      fontFamily="ManropeSemiBold"
    >
      {children}
    </Text>
  );
};

export default function Footer() {
  return (
    <Box
      background="#04040e"
      color={useColorModeValue("gray.700", "gray.200")}
      zIndex={1000}
    >
      <Container as={Stack} maxW={"100%"} py={10}>
        <SimpleGrid
          columns={{ sm: 1, md: 2, lg: 4 }}
          templateColumns={{ sm: "1fr", md: "2fr 1fr 1fr 1fr" }}
          spacing={8}
          mb={{ sm: 10, md: 0 }}
        >
           <Stack
            align={"flex-start"}
            fontSize={{ base: "sm", sm: "md", md: "lg", lg: "lg" }}
          >
             <Box align={"flex-start"}>
              <Logo color={useColorModeValue("gray.700", "white")} />
            </Box>
            <Box p={{sm: 0, md: "5"}}>
            <Text
              color="#fff"
              fontSize={{ sm: "md", md: "lg" }}
              fontFamily="ManropeLight"
              textAlign="justify"
            >
              Scrowed is a cutting-edge decentralized peer-to-peer (P2P) platform that empowers users to buy cryptocurrencies with fiat or sell their crypto holdings securely. 
            </Text>
           
            </Box>
          </Stack>
  
          <Stack
            align="flex-start"
            fontSize={{ base: "sm", sm: "md", md: "lg", lg: "xl" }}
            pl={{ sm: 0, md: 5 }}
          >
            <ListHeader
              fontSize={{ sm: "md", md: "lg" }}
              fontWeight="bold"
              fontFamily="ManropeSemiBold"
            >
              Company
            </ListHeader>
            <Link
              fontSize={{ sm: "md", md: "lg" }}
              fontFamily="ManropeLight"
              to={"/web/terms"}
              style={{ color: "#fff", fontFamily: "ManropeLight" }}
            >
              Terms of Service
            </Link>
            <Link
              fontSize={{ sm: "md", md: "lg" }}
              fontFamily="ManropeLight"
              to={"/web/privacy"}
              style={{ color: "#fff", fontFamily: "ManropeLight" }}
            >
              Privacy Policy
            </Link>
          </Stack>
          <Stack
            align={"flex-start"}
            fontSize={{ sm: "md", md: "lg" }}
          >
            <ListHeader
              fontSize={{ sm: "md", md: "lg" }}
              fontWeight="bold"
              fontFamily="ManropeSemiBold"
            >
              Support
            </ListHeader>
            <a
              fontSize={{ sm: "md", md: "lg" }}
              fontFamily="ManropeLight"
              href={"https://scrowed.tawk.help/"}
              style={{ color: "#fff", fontFamily: "ManropeLight" }}
            ><Text fontSize={{ sm: "md", md: "lg" }}>
              Help Center
              </Text>
            </a>
            <a
              fontSize={{ sm: "md", md: "lg" }}
              fontFamily="ManropeLight"
              href={"https://medium.com/@scrowedhq"}
              style={{ color: "#fff", fontFamily: "ManropeLight" }}
            >
              <Text fontSize={{ sm: "md", md: "lg" }}>
              Blog</Text>
            </a>
            <a
              fontSize={{ sm: "md", md: "lg" }}
              fontFamily="ManropeLight"
              href={"https://t.me/scrowedhq"}
              style={{ color: "#fff", fontFamily: "ManropeLight" }}
            ><Text fontSize={{ sm: "md", md: "lg" }}>
              Telegram Channel</Text>
            </a>
          </Stack>

          <Stack spacing={6}>
            <Text
              fontSize={{ sm: "md", md: "lg" }}
              fontFamily="ManropeLight"
              style={{ color: "#fff" }}
            >
              support@scrowed.com
            </Text>
            {/*<Text
              fontSize={{ base: "sm", sm: "md", md: "lg", lg: "lg" }}
              style={{ color: "#fff", fontFamily: "ManropeLight" }}
            >
              Unit 1411, 14/Floor, Cosco Tower, 183 Queen's Road Central, Sheung
              Wan, Hong Kong
              </Text>*/}

            <Stack direction={"row"} spacing={6} >
              <SocialButton
                label={"Twitter"}
                href={"https://twitter.com/scrowedhq"}
              >
                <FaTwitter />
              </SocialButton>
              <SocialButton
                label={"Facebook"}
                href={"https://facebook.com/scrowedhq"}
              >
                <FaFacebook />
              </SocialButton>
              <SocialButton label={"Telegram"} href={"https://t.me/scrowedhq"}>
                <FaTelegram />
              </SocialButton>
            </Stack>

            <Text
              mt="8"
              fontSize={{ sm: "md", md: "lg" }}
              color="#fff"
              fontFamily="ManropeLight"
            >
              © 2024 Scrowed. All rights reserved
            </Text>
          </Stack>

         
        </SimpleGrid>
      </Container>
    </Box>
  );
}
