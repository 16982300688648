import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import {
  Box,
  Text
} from "@chakra-ui/react";
const FileDropzone = ({ onFileAdded }) => {
  const onDrop = useCallback((acceptedFiles) => {
    if (acceptedFiles.length > 0) {
      onFileAdded(acceptedFiles[0]);
    }
  }, [onFileAdded]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: false,
    accept: 'image/*', // Only accept image files
  });

  return (
    <Box
      {...getRootProps()}
      style={{
        border: '2px dashed #cccccc',
        borderRadius: '5px',
        padding: '20px',
        textAlign: 'center',
        cursor: 'pointer',
        backgroundColor: isDragActive ? '#f0f0f0' : 'gray.300',
      }}
    >
      <input {...getInputProps()} />
      {isDragActive ? (
        <Text>Drop the image here ...</Text>
      ) : (
        <Text>Drag 'n' drop an image here, or click to select an image</Text>
      )}
    </Box>
  );
};

export default FileDropzone;
