import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Box,
  Icon,
  SimpleGrid,
  useToast,
  useColorModeValue,
} from "@chakra-ui/react";

import MiniStatistics from "components/card/MiniStatistics";
import IconBox from "components/icons/IconBox";
import {
  MdAttachMoney,
  MdBarChart
} from "react-icons/md";

import Revenue from "views/admin/dashboard/components/Revenue";
import Statistics from "views/admin/dashboard/components/Statistics";

export default function UserReports() {
  // Chakra Color Mode
  const brandColor = useColorModeValue("brand.500", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");

  const SERVER_URL = process.env.REACT_APP_SERVER_URL;
  const [loading, setLoading] = useState(false);
  const [categories, setCategories] = useState(null);
  const [weeklyStatistics, setWeeklyStatistics] = useState(null);
  const [dailyStatistics, setDailyStatistics] = useState(null);

  const token = localStorage.getItem("authToken");
  const toast = useToast();

  const dailyStat = async () => {
    try {
      setLoading(true);
      const { data: response } = await axios.get(SERVER_URL + '/stat/dailystatistics', {
        headers: {
          "Authorization": `Bearer ${token}`
        }
      });
      if (response.error) {
      }
      if (!response.success) {
      }
      if (response.success) {
        setLoading(false);
        setDailyStatistics(response.data);
        return
      }
    }
    catch (error) {
      setLoading(false);
      toast({
        title: error.message || "An error occured",
        status: 'error',
        duration: 9000,
        position: 'top-right',
        isClosable: true,
      })
    }
  }
  const weeklyStat = async () => {
    try {
      setLoading(true);
      const { data: response } = await axios.get(SERVER_URL + '/stat/weeklystatistics', {
        headers: {
          "Authorization": `Bearer ${token}`
        }
      });
      if (response.error) {
      }
      if (!response.success) {
      }
      if (response.success) {
        setLoading(false);
        setCategories(response.data.days)
        setWeeklyStatistics([
          {
            name: "Sell Trades",
            data: response.data.sellTrades,
          },
          {
            name: "Buy Trades",
            data: response.data.buyTrades,
          },
          {
            name: "Users",
            data: response.data.users,
          }
        ]);
        return
      }
    }
    catch (error) {
      setLoading(false);
      toast({
        title: error.message || "An error occured",
        status: 'error',
        duration: 9000,
        position: 'top-right',
        isClosable: true,
      })
    }
  }

  useEffect(() => {
    //dailyStat();
    //weeklyStat();
  }, [])

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>

      {/*
      {
        dailyStatistics!=null && 
        (()=>{
        return (
        <>
        <SimpleGrid
        columns={{ base: 1, md: 2, lg: 3, "2xl": 6 }}
        gap='20px'
        mb='20px'>
        <MiniStatistics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={
                <Icon w='32px' h='32px' as={MdBarChart} color={brandColor} />
              }
            />
          }
          name='Today Active Buy Trade'
          value={dailyStatistics.todayActiveBuyTrade}
        />

        <MiniStatistics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={
                <Icon w='32px' h='32px' as={MdBarChart} color={brandColor} />
              }
            />
          }
          name='Today Active Sell Trade'
          value={dailyStatistics.todayActiveSellTrade}
        />    
        <MiniStatistics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={
                <Icon w='32px' h='32px' as={MdAttachMoney} color={brandColor} />
              }
            />
          }
          name='Today Completed Buy Trade'
          value={dailyStatistics.todayCompletedBuyTrade}
        />
       <MiniStatistics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={
                <Icon w='32px' h='32px' as={MdBarChart} color={brandColor} />
              }
            />
          }
          name='Today Completed Sell Trade'
          value={dailyStatistics.todayCompletedSellTrade}
        />
      </SimpleGrid> 
      </>
      )
      })()
     }
     <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap='20px' mb='20px'>
         { revenue!=null &&  <Revenue categories={categories} revenue={revenue} />  }
         { weeklyStatistics!=null &&  <Statistics categories={categories} statistics={weeklyStatistics} /> }
      </SimpleGrid>

      */}

    </Box>
  );
}
