import React from "react";
import { Link } from "react-router-dom";
import {
    HStack,
    Box,
    Heading,
    Text,
    Avatar,
    SimpleGrid,
    Flex,
    Button
} from "@chakra-ui/react";
import { FiEye } from "react-icons/fi";
import NETWORKS from "contracts/Networks.json";
import { useSelector } from "react-redux";
const { DateTime } = require("luxon");

export default function Trade(props) {
    const userState = useSelector((state) => state?.user?.value);
    const userId = userState.hasOwnProperty("userId") ? userState.userId : null;
    const { trade } = props;
    const partnerUsername = trade.seller._id === userId ? trade.buyer.username : trade.seller.username;
    const tradeType = trade.seller._id === userId ? "Selling" : "Buying";
    const partnerType = trade.seller._id === userId ? "Buyer" : "Seller";

    const formatTime = (timestamp) => {
        const myDateTime = DateTime.fromISO(timestamp);
        return myDateTime.toLocaleString(DateTime.DATETIME_MED);
    };

    return (
        <>
            <HStack width="100%" p="3" background="brandScheme.500" borderRadius={'5'} borderLeftWidth="2px" borderLeftColor="secondary.500">
                <Box width={{ sm: "85%", md: "90%" }}>
                    <SimpleGrid columns={{ sm: 3, md: 6 }} width="100%" spacing="2">
                        <Box textAlign="left">
                            <Heading mt={{ sm: "2", md: "0" }} fontSize={{ sm: "sm", md: "md" }} color="white">{tradeType}</Heading>
                            <Text fontSize={{ sm: "sm", md: "md" }} color="gray.500">Trade Type</Text>
                            <Link to={"/app/user/" + partnerUsername}>
                                <Heading textDecoration="underline" fontSize={{ sm: "sm", md: "md" }} color="white" mt="2"> {partnerUsername}</Heading>
                            </Link>
                            <Text fontSize={{ sm: "sm", md: "md" }} color="gray.500">{partnerType}</Text>
                        </Box>

                        <Box textAlign="left">
                            <Heading mt="2" fontSize={{ sm: "sm", md: "md" }} color="white">{trade.advert.asset.symbol}</Heading>
                            <Text fontSize={{ sm: "sm", md: "md" }} color="gray.500">Asset</Text>
                            {trade.advert.network !== null && <Avatar src={NETWORKS[trade.advert.network]['ICONURL']} size="sm" />}
                        </Box>

                        <Box textAlign="left">
                            <Heading mt="2" fontSize={{ sm: "sm", md: "md" }} color="white">
                                {new Intl.NumberFormat('en-US', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(trade.price["$numberDecimal"])} &nbsp;  {trade.advert.currency.symbol}
                            </Heading>
                            <Text fontSize={{ sm: "sm", md: "md" }} color="gray.500">Price</Text>
                        </Box>

                        <Box textAlign="left">
                            <Heading mt="2" fontSize={{ sm: "sm", md: "md" }} color="white">
                                {trade.advert.asset.symbol !== "USDT" ? new Intl.NumberFormat('en-US', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 8 }).format(trade.unit["$numberDecimal"]) : new Intl.NumberFormat('en-US', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(trade.unit["$numberDecimal"])}
                                &nbsp;{trade.advert.asset.symbol}
                            </Heading>
                            <Text fontSize={{ sm: "sm", md: "md" }} color="gray.500">Quantity</Text>
                        </Box>

                        <Box textAlign="left">
                            <Heading mt="2" fontSize={{ sm: "sm", md: "md" }} color="white">
                                {trade.status}
                            </Heading>
                            <Text fontSize={{ sm: "sm", md: "md" }} color="gray.500">Status</Text>
                        </Box>

                        <Box textAlign="left">
                            <Heading mt="2" fontSize={{ sm: "sm", md: "md" }} color="white">
                                {formatTime(trade.createdAt)}
                            </Heading>
                            <Text fontSize={{ sm: "sm", md: "md" }} color="gray.500">Date</Text>
                        </Box>
                    </SimpleGrid>
                </Box>
                <Box width={{ sm: "15%", md: "10%" }} textAlign="center">
                    <Flex color="white" gap="3">
                        <Link
                            to={{ pathname: '/app/trades/' + trade.type + "/" + trade._id, state: { id: trade._id, type: trade.type } }}>
                            <Button colorScheme='secondary' size={{ sm: "sm", md: "md" }} p="2" variant='solid' borderRadius="3" fontSize={{ sm: "sm", md: "md" }}>View</Button>
                        </Link>
                    </Flex>
                </Box>
            </HStack>
        </>
    );
};














