/* eslint-disable */
import React, { useState } from "react";
import { Link as ReactRouterLink } from "react-router-dom";
// chakra imports
import { Box, Flex, HStack, Text, Circle, Icon, Link as ChakraLink, useColorModeValue } from "@chakra-ui/react";
import { useSelector } from "react-redux";


const activeRoute = (routeName) => {
  const currentPath = location.pathname;
  const pathParts = currentPath.split('/'); // Split the pathname into parts
  const lastPart = pathParts[pathParts.length - 1]; // Get the last part of the path
  return lastPart === routeName; // Check if the last part matches the routeName
};

const activeSubRoute = (subRoutes) => {
  let path = location.pathname.split("/");
  path = path[path.length - 1];
  return subRoutes.includes(path);
};

export function SidebarLinks(props) {
  //   Chakra color mode
  const [toggle, setToggle] = useState({});
  const user = useSelector((state) => state?.user?.value);
  const isLoggedIn = user.hasOwnProperty("token") ? true : false;
  const token = user.hasOwnProperty("token") ? user.token : null;
  const priviledges = user.hasOwnProperty("priviledges") ? user.priviledges : [];

  const { routes } = props;
  // verifies if routeName is the one active (in browser input)

  const handleToggle = (route) => {
    const updatedToggle = {};
    for (const toggleKey in toggle) {
      if (toggle.hasOwnProperty(toggleKey)) {
        // Set the value to 'none' if the key doesn't match the passed key
        updatedToggle[toggleKey] = toggleKey !== route ? false : toggle[toggleKey];
      }
    }
    // Update the state with the updatedToggle object
    setToggle(updatedToggle);
    setToggle((prevState) => ({
      ...prevState,
      [route]: !prevState[route], // Toggle the state of the submenu
    }));

  };

  return (
    <Box>
      {routes.map((route) => (
        <>
          {!route.collapse && (
            <NavItem key={route.name} route={route} priviledges={priviledges}>
              {route.name}
            </NavItem>
          )}

          {route.collapse && (
            <>
              <Box onClick={() => handleToggle(route.name)}>
                <ParentMenu
                  key={route.name}
                  route={route}
                  priviledges={priviledges}
                >
                  {route.name}
                </ParentMenu>
              </Box>
              <Box
                pl="3"
                pt="1"
                pb="2"
                width="100%"
                background="brand.500"
                borderRadius="15px"
                display={toggle[route.name] ? "block" : "none"}
              >
                <SubMenu
                  routes={route.items}
                  path={route.path}
                  priviledges={priviledges}
                />
              </Box>
            </>
          )}
        </>
      ))}
    </Box>
  )
}

const SubMenu = ({ routes, path, priviledges }) => {
  return (
    <>
      {routes.map((route) => (
        <>
          {route.show && (
            <SubNavItem
              key={route.name}
              route={route}
              path={path}
              priviledges={priviledges}
            >
              {route.name}
            </SubNavItem>
          )}
        </>
      ))}
    </>
  );
};

const ParentMenu = ({ route, priviledges, children }) => {
  const allowed = route.allow;
  const isRoleInArray = allowed.some(element => priviledges.includes(element));
  return (
    <>
      {isRoleInArray && (
        <Box
          bg={(activeRoute(route.path.toLowerCase()) || (route.items !== undefined && route.items.length > 0 && activeSubRoute(route.items))) ? "brand.500" : "transparent"}
          borderRadius="15px"
          mt="4"
          mb="4"
          width="100%"
          color={(activeRoute(route.path.toLowerCase()) || (route.items !== undefined && route.items.length > 0 && activeSubRoute(route.items))) ? "#ffffff" : "brand.500"}
          textAlign="center"
        >
          {route.icon ? (
            <HStack
              spacing={
                activeRoute(route.path.toLowerCase()) ? "22px" : "26px"
              }
              py='2px'
              ps='5px'
              w='100%'
              height="36px">
              <Flex w='100%' alignItems='center' justifyContent='center'>
                <Circle
                  background={activeRoute(route.path.toLowerCase()) ? "secondary.500" : "brand.500"}
                  color={activeRoute(route.path.toLowerCase()) ? "white" : "white"}
                  me='18px'
                  width="30px"
                  h="30px">
                  {route.icon}
                </Circle>
                <Text
                  me='auto'
                  fontSize={{ sm: "md", md: "lg" }}
                  color={activeRoute(route.path.toLowerCase()) ? "white" : "brand.500"}
                  fontWeight={
                    activeRoute(route.path.toLowerCase())
                      ? "bold"
                      : "normal"
                  }
                  fontFamily="ManropeLight">
                  {route.name}
                </Text>
              </Flex>
              <Box
                h='36px'
                w='4px'
                bg={
                  activeRoute(route.path.toLowerCase())
                    ? "transparent"
                    : "transparent"
                }
                borderRadius='5px'
              />
            </HStack>
          ) : (
            <HStack
              spacing={
                activeRoute(route.path.toLowerCase()) ? "22px" : "26px"
              }
              py='5px'
              ps='10px'
              w='100%'>
              <Text
                me='auto'
                fontSize={{ sm: "md", md: "lg" }}
                color={activeRoute(route.path.toLowerCase()) ? "white" : "brand.500"}
                fontWeight={
                  activeRoute(route.path.toLowerCase())
                    ? "bold"
                    : "normal"
                }>
                {route.name}
              </Text>
              <Box h='36px' w='4px' bg='transparent' borderRadius='5px' />
            </HStack>
          )}
        </Box>
      )}
    </>
  );
};

const SubNavItem = ({ route, path, priviledges, children }) => {
  const allowed = route.allow;
  const isRoleInArray = allowed.some(element => priviledges.includes(element));
  return (
    <>
      {isRoleInArray && (
        <ChakraLink
          as={ReactRouterLink}
          to={route.layout + "/" + path + "/" + route.path}
        >
          <Box
            pl="2"
            mt="2"
            mb="2"
            width="100%"
            fontSize="17"
            color="#ffffff"
            textAlign="center"
          >
            {route.icon ? (
              <HStack
                spacing={
                  activeRoute(route.path.toLowerCase()) ? "22px" : "26px"
                }
                py='5px'
                ps='10px'
                w='100%'
                h='36px'>
                <Flex w='100%' alignItems='center' justifyContent='center'>
                  <Circle
                    color={activeRoute(route.path.toLowerCase()) ? "white" : "white"}
                    me='18px'
                    background={activeRoute(route.path.toLowerCase()) ? "secondary.500" : "brand.500"}
                    width="30px"
                    h="30px">
                    {route.icon}
                  </Circle>
                  <Text
                    me='auto'
                    fontFamily="ManropeRegular"
                    fontSize={{ sm: "md", md: "lg" }}
                    color={activeRoute(route.path.toLowerCase()) ? "secondary.500" : "white"}
                    fontWeight={activeRoute(route.path.toLowerCase()) ? "bold" : "normal"}>
                    {route.name}
                  </Text>
                </Flex>
                <Box
                  h='36px'
                  w='4px'
                  bg={activeRoute(route.path.toLowerCase()) ? "transparent" : "transparent"}
                  borderRadius='5px'
                />
              </HStack>
            ) : (
              <HStack
                spacing={activeRoute(route.path.toLowerCase()) ? "22px" : "26px"}
                py='5px'
                ps='10px'
                w='100%'
                h='36px'>
                <Text
                  fontFamily="ManropeRegular"
                  fontSize={{ sm: "md", md: "lg" }}
                  me='auto'
                  color={activeRoute(route.path.toLowerCase()) ? "secondary.500" : "white"}
                  fontWeight={activeRoute(route.path.toLowerCase()) ? "bold" : "normal"}>
                  {route.name}
                </Text>
                <Box h='36px' w='4px' bg='transparent' borderRadius='5px' />
              </HStack>
            )}
          </Box>
        </ChakraLink>
      )}
    </>
  );
};

const NavItem = ({ route, priviledges, children }) => {
  const allowed = route.allow;
  const isRoleInArray = allowed.some(element => priviledges.includes(element));
  return (
    <>
      {isRoleInArray && (
        <ChakraLink
          as={ReactRouterLink}
          to={route.layout + "/" + route.path}
        >
          <Box
            bg={
              (activeRoute(route.path.toLowerCase()) || (route.items !== undefined && route.items.length > 0 && activeSubRoute(route.items))) ? useColorModeValue("brand.500", "brand.500") : useColorModeValue("transparent", "transparent")
            }
            borderRadius="15px"
            mt="4"
            mb="4"
            width="100%"
            color={(activeRoute(route.path.toLowerCase()) || (route.items !== undefined && route.items.length > 0 && activeSubRoute(route.items))) ? "#ffffff" : "brand.500"}
            textAlign="center"
          >

            {route.icon ? (
              <HStack
                spacing={
                  activeRoute(route.path.toLowerCase()) ? "22px" : "26px"
                }
                py='2px'
                ps='5px'
                w='100%'
                height="36px">
                <Flex w='100%' alignItems='center' justifyContent='center'>
                  <Circle
                    me='18px'
                    background={activeRoute(route.path.toLowerCase()) ? "secondary.500" : "brand.500"}
                    color={activeRoute(route.path.toLowerCase()) ? "white" : "white"}
                    width="30px"
                    h="30px">
                    {route.icon}
                  </Circle>
                  <Text
                    me='auto'
                    fontSize={{ sm: "md", md: "lg" }}
                    color={activeRoute(route.path.toLowerCase()) ? "white" : "brand.500"}
                    fontWeight={
                      activeRoute(route.path.toLowerCase())
                        ? "bold"
                        : "normal"
                    }
                    fontFamily="ManropeLight">
                    {route.name}
                  </Text>
                </Flex>
              </HStack>
            ) : (
              <HStack
                spacing={
                  activeRoute(route.path.toLowerCase()) ? "22px" : "26px"
                }
                py='5px'
                ps='10px'
                w='100%'>
                <Text
                  me='auto'
                  fontSize={{ sm: "md", md: "lg" }}
                  color={activeRoute(route.path.toLowerCase()) ? "white" : "brand.500"}
                  fontWeight={
                    activeRoute(route.path.toLowerCase())
                      ? "bold"
                      : "normal"
                  }>
                  {route.name}
                </Text>
                <Box h='36px' w='4px' bg='transparent' borderRadius='5px' />
              </HStack>
            )}
          </Box>
        </ChakraLink>
      )}
    </>
  );
};

const NavItemSmall = ({ route, priviledges, children }) => {
  //const allowed = route.allow;
  //const isRoleInArray = allowed.includes(userType) || allowed.includes("all");
  const allowed = route.allow;
  const isRoleInArray = allowed.some(element => priviledges.includes(element));
  return (
    <>
      {isRoleInArray && (
        <ChakraLink
          as={ReactRouterLink}
          to={route.layout + "/" + route.path}
          mt="25"
        >
          <Box
            mt="4"
            width="100%"
            fontSize="17"
            color="#000000"
            align="center"
            mr="5"
          >
            {route.icon}
            <Text color="#000000">{route.linkText}</Text>
          </Box>
        </ChakraLink>
      )}
    </>
  );
};


export default SidebarLinks;
