import { useState, useEffect } from "react";

const useCountDown = (endTime) => {
    const [timeLeft, setTimeLeft] = useState(endTime - new Date().getTime());

    useEffect(() => {
        const interval = setInterval(() => {
            const timeDifference = endTime - new Date().getTime();
            setTimeLeft(timeDifference > 0 ? timeDifference : 0);
        }, 1000);

        return () => clearInterval(interval);
    }, [endTime]);

    return [timeLeft, setTimeLeft];
};

export default useCountDown;
