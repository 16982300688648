import axios from "axios";
import {
  query,
  getDocs,
  where,
  orderBy,
  addDoc,
  doc,
  getDoc,
  updateDoc,
  onSnapshot,
  collection,
} from "firebase/firestore";
import { auth, db } from "firebase.js";
const SERVER_URL = process.env.REACT_APP_SERVER_URL;

const SendMessage = async (messageData) => {
  try {
    //upload product photo to cloudinary
    /*if (messageData.hasOwnProperty("photo") && messageData.photo !== "") {
      const photo = await uploadImage(messageData.photo);
      messageData["photo"] = photo;
    } else {
      delete messageData.photo;
    }
    */
    const docRef = await addDoc(collection(db, "messages"), messageData);
    return { success: true, message: "Message added successfully" };
  } catch (error) {
    alert(JSON.stringify(error));
     
    throw new Error(error);
  }
};

const GetMessages = async (tradeId) => {
  try {
    const querySnapshot = await getDocs(
      query(
        collection(db, "messages"),
        where("tradeId", "==", tradeId),
        orderBy("createdAt", "asc") // Order by createdAt in ascending order
      )
    );
    const messages = [];
    querySnapshot.forEach((doc) => {
      messages.push({ id: doc.id, ...doc.data() });
    });
    return { success: true, message: "", messages };

  } catch (error) {
    throw new Error(error);
  }
};

const GetUnreadMessagesCount = async (tradeId, recipientId) => {
  try {
    const querySnapshot = await getDocs(
      query(
        collection(db, "messages"),
        where("tradeId", "==", tradeId),
        where("recipientId", "==", recipientId), // Filter by recipientId
        where("recipientRead", "==", false), // Filter by unread messages (recipientRead is false)
        orderBy("createdAt", "asc") // Order by createdAt in ascending order
      )
    );
    // Get the count of unread messages
    const unreadCount = querySnapshot.size;
    return { success: true, message: "", unreadCount };
  } catch (error) {
    throw new Error(error);
  }
};

const UpdateReadStatus = async (tradeId, recipientId) => {
  try {
    const messagesRef = collection(db, "messages");
    const q = query(
      messagesRef,
      where("tradeId", "==", tradeId),
      where("recipientId", "==", recipientId),
      where("recipientRead", "==", false) // Only fetch unread messages
    );

    const querySnapshot = await getDocs(q);

    // Update each document found by the query
    for (const docSnapshot of querySnapshot.docs) {
      const docRef = doc(db, "messages", docSnapshot.id);
      await updateDoc(docRef, { recipientRead: true });
    }

    const updatedCount = querySnapshot.size;
    return { success: true, message: "Messages updated", updatedCount };

  } catch (error) {
    throw new Error(error.message);
  }
};

const ListenForNewMessages = (tradeId, callback) => {
  const q = query(
    collection(db, "messages"),
    where("tradeId", "==", tradeId),
    orderBy("createdAt", "asc") // Order by createdAt in ascending order
  );

  const unsubscribe = onSnapshot(q, (querySnapshot) => {
    const messages = [];
    querySnapshot.forEach((doc) => {
      messages.push({ id: doc.id, ...doc.data() });
    });
    callback(messages);
  });

  return unsubscribe;
};


const ListenForNewCounts = (tradeId, recipientId, callback) => {
  const q = query(
    collection(db, "messages"),
    where("tradeId", "==", tradeId),
    where("recipientId", "==", recipientId),
    where("recipientRead", "==", false)
  );

  const unsubscribe = onSnapshot(q, (querySnapshot) => {
    const newCount = querySnapshot.size; // Get the number of unread messages
    callback(newCount); // Pass the new count to the callback function
  });

  return unsubscribe; // Return the unsubscribe function
};

const UploadImage = async (base64String) => {
  try {
    const response = await axios.post(
      "https://api.cloudinary.com/v1_1/duci5ynen/image/upload",
      {
        file: `data:image/jpeg;base64,${base64String}`,
        upload_preset: "localfood",
      }
    );

    return response.data.secure_url;
  } catch (error) {
    throw new Error(error);
  }
};

const ScanFile = async (file, token) => {
  const formData = new FormData();
  formData.append('file', file);
  try {
    const response = await axios.post(SERVER_URL + "/scan", formData, {
      headers: {
        Authorization: `Bearer ${token}`
      },
      mode: "cors",
    });
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

export {
  SendMessage,
  GetMessages,
  GetUnreadMessagesCount,
  UpdateReadStatus,
  ListenForNewMessages,
  ListenForNewCounts,
  UploadImage,
  ScanFile
};