import { useEffect } from 'react';
import { UpdateUser } from 'services/userService';

const updateLastActive = async (token) => {
  try {
    const userData = {lastSeen: new Date()};
    await UpdateUser(userData, token);
  } catch (error) {
    console.error('Failed to update last active time:', error);
  }
};

const useLastActive = (token) => {
  useEffect(() => {
    const interval = setInterval(() => {
      if(token===undefined) return;
      updateLastActive(token);
    }, 60000); // Update every minute
    return () => clearInterval(interval);
  }, [token]);
};

export default useLastActive;
