import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Text,Button,
  TableContainer,
} from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card";
import React from "react";
import { Link } from "react-router-dom";
const { DateTime } = require("luxon");

export default function TradeTable(props) {

  const { trades, pageCount, tradeType, action, onOpen, setTradeId } = props;
  const formatTime = (timestamp) => {
    const myDateTime = DateTime.fromISO(timestamp);
    return myDateTime.toLocaleString(DateTime.DATETIME_MED);
  };

  return (
    <Card
      w='100%'
      px='0px'
      overflowY={{ sm: "scroll", lg: "hidden" }}>
      <TableContainer>
        <Table variant='striped' fontSize={{ sm: "md", lg: "lg" }}>
          <Thead>
            <Tr>
              <Th textAlign="left" fontWeight="bold">SN</Th>
              <Th textAlign="left" fontWeight="bold">Asset</Th>
              <Th textAlign="left" fontWeight="bold">Currency</Th>
              <Th textAlign="left" isNumeric fontWeight="bold">Quantity</Th>
              <Th textAlign="left" isNumeric fontWeight="bold">Rate</Th>
              <Th textAlign="left" fontWeight="bold">Payment Status</Th>
              <Th textAlign="left" fontWeight="bold">Date</Th>
              {action && <Th textAlign="left" fontWeight="bold">Action</Th>}
            </Tr>
          </Thead>
          <Tbody>
            {
              trades !== null && trades.map((trade, index) => {
                return (
                  <Tr key={index}>
                    <Td textAlign="left">
                      <Link to={{ pathname: '/admin/trades/trade/' + trade._id, state: { id: trade._id } }}>
                        <Text textDecoration="underline">
                          #{trade._id}
                        </Text>
                      </Link>
                    </Td>
                    <Td textAlign="left">{trade.advert.asset.symbol}</Td>
                    <Td textAlign="left">{trade.advert.currency.symbol}</Td>
                    <Td textAlign="left" isNumeric>{trade.advert.asset.symbol !== "USDT" ? new Intl.NumberFormat('en-US', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 8 }).format(trade.unit["$numberDecimal"]) : new Intl.NumberFormat('en-US', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(trade.unit["$numberDecimal"])}</Td>
                    <Td textAlign="left" isNumeric>{new Intl.NumberFormat('en-US', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(trade.price["$numberDecimal"])}/{trade.advert.currency.symbol}</Td>
                    <Td textAlign="left">{trade.paid === false ? "Not Paid" : "Paid"}</Td>
                    <Td textAlign="left">{formatTime(trade.createdAt)}</Td>
                    {action && <Td textAlign="left"><Button colorScheme="brand" size="sm" onClick={()=>{setTradeId(trade._id); onOpen();}}>{action}</Button></Td>}
                  </Tr>
                )
              })}
          </Tbody>
        </Table>
      </TableContainer>

    </Card>
  );
}
