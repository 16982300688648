import axiosInstance from './axiosInstance'; // Adjust the path accordingly

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

const GetCurrencies = async () => {
  try {
    const response = await axiosInstance.get(
      SERVER_URL + "/currency",
      {
        headers: {
          Authorization: `Bearer `,
        },
        mode: "cors",
      }
    );
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

const GetAssets = async () => {
  try {
    const response = await axiosInstance.get(
      SERVER_URL + "/asset",
      {
        headers: {
          Authorization: `Bearer `,
        },
        mode: "cors",
      }
    );
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

const GetPaymentMethods = async () => {
  try {
    const response = await axiosInstance.get(
      SERVER_URL + "/payment",
      {
        headers: {
          Authorization: `Bearer `,
        },
        mode: "cors",
      }
    );
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};


export {
  GetCurrencies,
  GetAssets,
  GetPaymentMethods
};




