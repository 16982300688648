import React, { useEffect, useState } from "react";
import {
  Box,
  Center,
  Heading,
  Checkbox,
  InputGroup,
  InputRightAddon,
  Button,
  SimpleGrid,
  Input,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useAccount, useChainId } from 'wagmi';
import { useSelector } from "react-redux";
import useStake from "services/stakeService";
//const token = localStorage.getItem("authToken");

export default function Index() {
  const toast = useToast();
  const userState = useSelector((state) => state?.user?.value);
  const {
    TotalStakers,
    TotalStaked,
    StakeOf,
    CreateStake,
    RewardOf,
    RemoveStake
  } = useStake();

  const token = userState.hasOwnProperty("token") ? userState.token : null;
  const { isConnected, address } = useAccount();

  const [user, setUser] = useState(null);
  const [assetStaked, setAssetStaked] = useState(0);
  const [stakers, setStakers] = useState(0);
  const [step, setStep] = useState(1);
  const [terms, setTerms] = useState(false);
  const [amount, setAmount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [userStake, setUserStake] = useState(0);
  const [userReward, setUserReward] = useState(0);

  const getStakeOf = async () => {
    try {
      const stake = await StakeOf(address);
      setUserStake(stake);
    } catch (error) { }
  };

  const getTotalStakers = async () => {
    try {
      const stakers = await TotalStakers();
      setStakers(stakers);
    } catch (error) { }
  };

  const getTotalStaked = async () => {
    try {
      const staked = await TotalStaked();
      setAssetStaked(staked);
    } catch (error) { }
  };

  const getRewardOf = async () => {
    try {
      const reward = await RewardOf(address);
      setUserReward(reward);
    } catch (error) { }
  };

  const stake = async () => {
    try {
      if (terms !== true) {
        toast({
          title: "You must agree to terms before staking",
          status: "info",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      setLoading(true);
      await CreateStake(amount);
    } catch (error) {
      toast({
        title: error.message,
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
      setLoading(false);
    }
  };

  const unstake = async () => {
    try {
      setLoading(true);
      await RemoveStake();
    } catch (error) {
      toast({
        title: error.message,
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
      setLoading(false);
    }
  };

  useEffect(() => {
    getTotalStaked();
    getStakeOf();
    getRewardOf();
  }, []);


  return (
    <>
      <Box pt={{ base: "30px", md: "40px", xl: "40px" }} width="100%">

        <Box width="100%" mt="5" mb="5">
          <Heading color="white" size="md">
            Staking
          </Heading>
        </Box>

        <SimpleGrid columns={{ base: 2, sm: 2, md: 4, lg: 4 }} spacing={2}>
          <Box minHeight="70" className="trade-record-card">
            <Box minHeight="70" width="100%" pt="3">
              <Text color="white" textAlign="center">
                Staked
              </Text>
              <Text color="white" fontWeight="bold" textAlign="center">
                {userStake} DXP
              </Text>
              {userStake > 0 && (
                <Text
                  color="white"
                  textAlign="center"
                  textDecoration={"underline"}
                  onClick={unstake}
                >
                  unstake
                </Text>
              )}
            </Box>
          </Box>

          <Box minHeight="70" className="trade-record-card">
            <Box minHeight="70" width="100%" pt="3">
              <Text color="white" textAlign="center">
                Rewards
              </Text>
              <Text color="white" fontWeight="bold" textAlign="center">
                {userReward} DXP
              </Text>
            </Box>
          </Box>

          <Box minHeight="70" className="trade-record-card">
            <Box minHeight="70" width="100%" pt="3">
              <Text color="white" textAlign="center">
                Total Locked Value
              </Text>
              <Text color="white" fontWeight="bold" textAlign="center">
                {assetStaked} DXP
              </Text>
            </Box>
          </Box>

          <Box className="trade-record-card" minHeight="70">
            <Box width="100%" minHeight="70" pt="3">
              <Text color="white" textAlign="center">
                APR
              </Text>
              <Text color="white" fontWeight="bold" textAlign="center">
                15%
              </Text>
            </Box>
          </Box>
        </SimpleGrid>

        <Box width="100%" mt="5">
          <Box width="100%" mt="7" mb="7" textAlign="center">
            <Heading size="sm" color="white">
              {" "}
              Stake Your DXP{" "}
            </Heading>
          </Box>
          <ol className="FormStepList">
            <li className="FormStepList-item">
              <span className={`FormStepIcon ${step === 1 ? "is-passed" : ""}`}>
                <span className="FormStepIcon-circle">1</span>
                <Text color="white" fontSize="14">
                  Instruction
                </Text>
              </span>
            </li>

            <li className="FormStepList-item">
              <span
                className={`FormStepIcon ${step === 1 ? "is-active" : ""} ${step === 2 ? "is-passed" : ""
                  }`}
              >
                <span className="FormStepIcon-circle">2</span>
                <Text color="white">Amount</Text>
              </span>
            </li>

            <li className="FormStepList-item">
              <span
                className={`FormStepIcon FormStepIcon--last ${step === 2 ? "is-active" : ""
                  }`}
              >
                <span className="FormStepIcon-circle">3</span>
                <Text color="white">Confirm &amp; Submit</Text>
              </span>
            </li>
          </ol>
        </Box>

        {step === 1 && (
          <Box width="100%">
            <Box width="100%">
              <Heading size="md" color="white">
                Instruction
              </Heading>
            </Box>
            <SimpleGrid
              columns={{ base: 1, sm: 1, md: 3, lg: 3 }}
              spacing={4}
              mt="5"
              mb="10"
            >
              <Box>
                <Heading size="sm" color="white">
                  1. Connect Wallet
                </Heading>
                <Text color="white">
                  If wallet not connected, click the "Connect" button at the top
                  right corner
                </Text>
              </Box>
              <Box>
                <Heading size="sm" color="white">
                  2. DXP available to deposit
                </Heading>
                <Text color="white">Enter amount of DXP you want to stake</Text>
              </Box>
              <Box>
                <Heading size="sm" color="white">
                  3. Approve Transaction
                </Heading>
                <Text color="white">
                  Approve the Dapp to deduct DXP balance from your wallet
                </Text>
              </Box>
            </SimpleGrid>

            <Box width="100%" mt="5" mb="5">
              <Box with="100%" textAlign="center">
                <Checkbox
                  onChange={(e) => {
                    // The event object (e) contains information about the checkbox state
                    const newCheckedState = e.target.checked;
                    if (newCheckedState) {
                      setTerms(true);
                    } else {
                      setTerms(false);
                    }
                  }}
                >
                  <Text color="white" textAlign="center">
                    Do you agree to stake your DXP tokens
                  </Text>
                </Checkbox>
              </Box>
            </Box>
            <Box with="100%" textAlign="center">
              <Button
                variant={"outline"}
                size="sm"
                color="white"
                onClick={(e) => {
                  if (!terms) {
                    toast({
                      title: "You need to accept terms to proceed",
                      status: "error",
                      duration: 9000,
                      position: "top-right",
                      isClosable: true,
                    });
                  } else {
                    setStep(2);
                  }
                }}
              >
                Continue
              </Button>
            </Box>
          </Box>
        )}

        {step === 2 && (
          <Box width="100%">
            <Center width="100%" mt="10" mb="5">
              <Box width={{ sm: "100%", md: "30%" }}>
                <InputGroup size="sm">
                  <Input
                    placeholder="DXP"
                    color="#ffffff"
                    placeHolderTe
                    height="35"
                    onChange={(e) => {
                      setAmount(e.target.value);
                    }}
                  />
                  <InputRightAddon children="BNB" height="35" />
                </InputGroup>
                <Button
                  mt="2"
                  isLoading={loading}
                  loadingText="Please Wait"
                  onClick={(e) => {
                    e.preventDefault();
                    stake();
                  }}
                >
                  Stake
                </Button>
              </Box>
            </Center>
          </Box>
        )}
      </Box>
    </>
  );
}
