import { Outlet, useLocation } from "react-router-dom";
import {
  Box,
  HStack,
  Flex,
  Center,
  Text,
  Link as ChakraLink,
  useMediaQuery,
} from "@chakra-ui/react";
import { Link as ReactRouterLink } from "react-router-dom";
import routes from "routesuser.js";
import Footer from "components/navigation/Footer.js";
import NavBar from "components/navigation/NavBarWallet.js";
import React from "react";

const activeRoute = (routeName, pathname) => {
  return pathname.includes(routeName);
};

const UserLayout = (props) => {
  const { handleLogin, handleLogout, handleConnect, isConnected, address, loginLoading } = props;
  const location = useLocation(); // Use the useLocation hook to get the current location

  // Use media query to check the screen size
  const [isLargerThanMedium] = useMediaQuery("(min-width: 48em)");
  const [isSmallerThanMedium] = useMediaQuery("(max-width: 47.99em)");

  return (
    <>
      <Box
        width="100%">
        {/* Sidebar or Footerbar based on screen size */}
        {isLargerThanMedium ? (
          <Box
            as="aside"
            w="100px"
            h="100vh"
            bg="brandScheme.500"
            color="white"
            zIndex="10"
            position="fixed"
            top="0"
            left="0"
            paddingTop="20"
          >
            {routes.map((route) => (
              <ChakraLink
                key={route.layout}
                as={ReactRouterLink}
                to={route.layout + "/" + route.path}
                mt="25"
              >
                <NavItem key={route.name} icon={route.icon} path={route.path} active={activeRoute(route.path, location.pathname)}>
                  {route.name}
                </NavItem>
              </ChakraLink>
            ))}
          </Box>
        ) : (
          isSmallerThanMedium && (
            <Box
              position="fixed"
              width="100%"
              h="80px"
              bottom="2"
              left="0"
              zIndex={1000}
              p="2"
            >
              <Flex w="100%" borderRadius="15" bg="brandScheme.500" justifyContent="space-between">
                {routes.map((route) => (
                  <ChakraLink
                    key={route.layout}
                    as={ReactRouterLink}
                    to={route.layout + "/" + route.path}
                  >
                    <NavItem key={route.name} icon={route.icon} path={route.path} active={activeRoute(route.path, location.pathname)}>
                      {route.name}
                    </NavItem>
                  </ChakraLink>
                ))}
              </Flex>
            </Box>
          )
        )}

        {/* Main content */}
        <Box ml={isLargerThanMedium ? "100px" : "0"} minH="100vh" bg="#000000">
          <HStack background="none">
            <NavBar zIndex="10" isConnected={isConnected} address={address} handleLogin={handleLogin} handleLogout={handleLogout} handleConnect={handleConnect} loginLoading={loginLoading} />
          </HStack>
          <Box p="4" width="100%" minH="600">
            <Outlet />
          </Box>
          <Footer />
        </Box>
      </Box>
    </>
  );
};

const NavItem = ({ icon, path, children, active, ...rest }) => {
  return (
    <Box
      p="3"
      pt={{ sm: "1", md: "3" }}
      pb="6"
      mx={{ sm: "1", md: "3" }}
      mt="15"
      mb="15"
      width={{ sm: "50px", md: "65px" }}
      height={{ sm: "50px", md: "65px" }}
      background={active ? "secondary.500" : "none"}
      color="white"
      align="center"
      className="sidebar-icon"
      _hover={{
        bg: "secondary.400",
        color: "secondary.500",
      }}
      {...rest}
    >
      {icon}
      <Text fontSize={{ sm: "8px", md: "12px" }} color="white">{children}</Text>
    </Box>
  );
};

export default UserLayout;
