import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  Heading,
  Text,
  Tag,
  Center,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  VStack,
  SkeletonText,
  useToast,
  useDisclosure
} from "@chakra-ui/react";
import Trade from "./components/TradeInfo.js";
import Chat from "./components/Chat.js";
import FileDropzone from './components/FileDropzone.js';
import { GetBuyTrade, GetSellTrade, CancelBuyTradeContract, CancelSellTradeContract, TransferAssetSellTradeContract, TransferAssetBuyTradeContract, TransferFiatSellTrade, TransferFiatBuyTrade } from "services/tradeService";
import { SendMessage, GetMessages, ListenForNewMessages, ListenForNewCounts, UploadImage, ScanFile, GetUnreadMessagesCount, UpdateReadStatus } from "services/chatService";
import { useSelector } from "react-redux";
import Card from "components/card/Card";
import { useAccount, useChainId, useSwitchChain } from 'wagmi'
const { DateTime } = require("luxon");

export default function Index() {
  const socket = new WebSocket(process.env.REACT_APP_SOCKET_URL);
  const { type, tradeId } = useParams();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isCancelOpen, onOpen: onCancelOpen, onClose: onCancelClose } = useDisclosure();
  const { isOpen: isTransferAssetOpen, onOpen: onTransferAssetOpen, onClose: onTransferAssetClose } = useDisclosure();
  const { isOpen: isTransferFiatOpen, onOpen: onTransferFiatOpen, onClose: onTransferFiatClose } = useDisclosure();

  const userState = useSelector((state) => state?.user?.value);
  const token = userState.hasOwnProperty("token") ? userState.token : null;
  const userId = userState.hasOwnProperty("userId") ? userState.userId : null;

  const { isConnected, address } = useAccount();
  const chainId = useChainId();
  const { switchChain } = useSwitchChain();

  const toast = useToast();

  const [tradeLoading, setTradeLoading] = useState(false);
  const [cancelLoading, setCancelLoading] = useState(false);
  const [transferLoading, setTransferLoading] = useState(false);
  const [transferFiatLoading, setTransferFiatLoading] = useState(false);
  const [disputed, setDisputed] = useState(false);
  const [trade, setTrade] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);
  const [sellerUser, setSellerUser] = useState(null);
  const [buyerUser, setBuyerUser] = useState(null);
  const [recipientUser, setRecipientUser] = useState(null);

  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState(null);
  const [view, setView] = useState("trade");
  const [file, setFile] = useState(null);
  const [unreadCount, setUnreadCount] = useState(0);

  const getTrade = async () => {
    try {
      setTradeLoading(true);
      const response = type === "buy" ? await GetBuyTrade(tradeId, token) : await GetSellTrade(tradeId, token);
      setTradeLoading(false);
      if (!response) {
        return;
      }
      if (response.error) {
        toast({
          title: response.error || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (!response.success) {
        toast({
          title: response.message || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (response.success === true) {
        setTrade(response.data);

        const currentUser = {
          username: userState?.username,
          _id: userState.userId
        }
        setCurrentUser(currentUser);

        const recipient1 = {
          username: response.data.buyer.username,
          _id: response.data.buyer._id
        }
        setBuyerUser(recipient1);

        const recipient2 = {
          username: response.data.seller.username,
          _id: response.data.seller._id
        }
        setSellerUser(recipient2);

        return;
      }
    } catch (error) {
      toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
      setTradeLoading(false);
    }
  };

  const handleCancelTrade = async () => {
    try {
      setCancelLoading(true);
      if (trade.type === "sell") {
        await CancelSellTradeContract(tradeId);
      }
      else if (trade.type === "buy") {
        await CancelBuyTradeContract(tradeId);
      }
      else {
        toast({
          title: "Trade not found, refresh and try again",
          status: "success",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
      }
      if (chainId.toString() !== trade.advert.network) {
        handleSwitchNetwork(trade.advert.network);
        return;
      }
      toast({
        title: "Trade cancelling initiated",
        status: "success",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
      getTrade();
      onCancelClose();
    } catch (error) {
      toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
      setCancelLoading(false);
    }
  };

  const handleTransferAsset = async () => {
    try {
      setTransferLoading(true);
      if (trade.type === "sell") {
        await TransferAssetSellTradeContract(tradeId);
      }
      else if (trade.type === "buy") {
        await TransferAssetBuyTradeContract(tradeId);
      }
      else {
        toast({
          title: "Trade not found, refresh and try again",
          status: "success",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
      }
      setTransferLoading(true);
      toast({
        title: "Asset release has been initated",
        status: "success",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
      onTransferAssetClose();
    } catch (error) {
      toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
      setTransferLoading(false);
    }
  };

  const handleTransferFiat = async () => {
    try {
      setTransferFiatLoading(true);
      let response;
      const tradeData = { paid: true }
      if (trade.type === "sell") {
        response = await TransferFiatSellTrade(tradeId, tradeData, token);
      }
      else if (trade.type === "buy") {
        response = await TransferFiatBuyTrade(tradeId, tradeData, token);
      }
      else {
        toast({
          title: "Trade not found, refresh and try again",
          status: "success",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
      }
      setTransferFiatLoading(false);
      if (response.error) {
        toast({
          title: response.error || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (!response.success) {
        toast({
          title: response.message || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (response.success) {
        toast({
          title: "Trade mark as paid successfully",
          status: "success",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        getTrade();
        onTransferFiatClose();
      }
    } catch (error) {
      toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
      setTransferFiatLoading(false);
    }
  };

  const handleSendMessage = async () => {
    try {
      if (message === "") return;

      const messageData = {
        senderId: currentUser._id,
        recipientId: recipientUser._id,
        senderName: "Admin",
        recipientName: recipientUser.username,
        message: message,
        tradeId: tradeId,
        createdAt: new Date().toISOString(),
        senderRead: true,
        recipientRead: false,
        bothPartyRead: true,
        upload: false
      };

      const response = await SendMessage(messageData);
      if (response.hasOwnProperty("success") && response.success) {
        setMessage('');
      } else {
        toast({
          title: "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
      setTradeLoading(false);
    }
  };

  const handleTimeOut = async () => {
    setDisputed(true);
  };


  const [uploading, setUploading] = useState(false);
  const [uploadedUrl, setUploadedUrl] = useState('');

  const handleFileAdded = (newFile) => {
    setFile(newFile);
    setUploadedUrl('');
  };

  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result.split(',')[1]);
      reader.onerror = (error) => reject(error);
    });
  };

  const uploadFileToCloudinary = async () => {
    if (!file) return;
    setUploading(true);
    try {
      // Scan the file
      const scanData = await ScanFile(file, token);
      if (!scanData.success) {
        toast({
          title: scanData.message || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        setUploading(false);
        return;
      }

      const base64Image = await getBase64(file);
      const imageUrl = await UploadImage(base64Image);
      const messageData = {
        senderId: currentUser._id,
        recipientId: recipientUser._id,
        senderName: currentUser.username,
        recipientName: recipientUser.username,
        message: imageUrl,
        tradeId: tradeId,
        createdAt: new Date().toISOString(),
        senderRead: true,
        recipientRead: false,
        bothPartyRead: true,
        upload: true
      };
      const response = await SendMessage(messageData);
      if (response.hasOwnProperty("success") && response.success) {
        setMessage('');
        setFile('');
        setUploadedUrl(imageUrl);
        onClose();
      } else {
        toast({
          title: "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: error.message || "An upload error occured, try again",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
    } finally {
      setUploading(false);
    }
  };

  const getMessages = async () => {
    const { messages } = await GetMessages(tradeId);
    setMessages(messages);
  };

  const getUnreadMessagesCount = async () => {
    const { unreadCount } = await GetUnreadMessagesCount(tradeId, userId);
    setUnreadCount(unreadCount);
  };

  const updateReadStatus = async () => {
    await UpdateReadStatus(tradeId, userId);
  };

  const handleSwitchNetwork = async (chainId) => {
    try {
      switchChain({ chainId: chainId });
    } catch (error) {
      console.error("Failed to switch network", error);
    }
  };

  useEffect(() => {
    if (token !== null) {
      getTrade();
    }
  }, [token, tradeId]);


  useEffect(() => {
    if (isConnected) {
      const handleOpen = () => {
        console.log('WebSocket connection established');
        socket.send(JSON.stringify({
          type: 'REGISTER',
          payload: {
            clientId: address.toLowerCase(),
          },
        }));
      };

      const handleMessage = (event) => {
        const message = JSON.parse(event.data);
        if (message.type === 'TRADE_CLOSED_SUCCESS') {
          setCancelLoading(false);
          if (token !== null) { getTrade(); }
          toast({
            title: "Trade cancelled successfully",
            status: "success",
            duration: 9000,
            position: "top-right",
            isClosable: true,
          });
        }

        if (message.type === 'ASSET_TRANSFERRED_SUCCESS') {
          setTransferLoading(false);
          if (token !== null) { getTrade(); }
          toast({
            title: "Asset has been released successfully",
            status: "success",
            duration: 9000,
            position: "top-right",
            isClosable: true,
          });
        }

        if (message.type === 'TRADE_OPENED_SUCCESS') {
          if (token !== null) getTrade();
          toast({
            title: "Trade funding completed successfully",
            status: "success",
            duration: 9000,
            position: "top-right",
            isClosable: true,
          });
        }
        if (message.type === 'TRADE_PAID_SUCCESS') {
          if (token !== null) getTrade();
          toast({
            title: "Trade has been mark as paid",
            status: "success",
            duration: 9000,
            position: "top-right",
            isClosable: true,
          });
        }
        if (message.type === 'TRADE_DISPUTED_SUCCESS') {
          if (token !== null) getTrade();
        }
      };

      socket.onopen = handleOpen;
      socket.onmessage = handleMessage;

      // Cleanup function
      return () => {
        socket.onopen = null;
        socket.onmessage = null;
      };
    }
  }, [isConnected, token, address, getTrade, toast]);


  useEffect(() => {
    const unsubscribeX = ListenForNewMessages(tradeId, (newMessages) => {
      setMessages(newMessages);
    });

    const unsubscribeY = ListenForNewCounts(tradeId, userId, (newCount) => {
      setUnreadCount(newCount); // Update the state with the new count
    });
    // Cleanup function
    return () => {
      unsubscribeX(); // Stop listening for new messages when component unmounts
      unsubscribeY();
    };
  }, []);

  // Optionally, you can also fetch initial messages when component mounts
  useEffect(() => {
    //get initial messages
    getMessages();
    getUnreadMessagesCount();
  }, []);

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} size={'sm'} background="brand.500">
        <ModalOverlay />
        <ModalContent background="brand.500">
          <ModalHeader color="white">Upload Payment Proof</ModalHeader>
          <ModalCloseButton color="white" />
          <ModalBody>
            <FileDropzone onFileAdded={handleFileAdded} />
            <Box style={{ marginTop: '20px' }}>
              <Heading size="sm" color="white">Selected File:</Heading>
              {file ? <Text color="white">{file.name}</Text> : <Text color="white">No file selected yet.</Text>}
            </Box>
            <Box style={{ marginTop: '10px', marginBottom: '20px' }} textAlign="center">
              {file && (
                <Button onClick={uploadFileToCloudinary} isLoading={uploading} colorScheme="brand" color="white">
                  {uploading ? 'Uploading...' : 'Upload File'}
                </Button>
              )}
              { /*uploadedUrl && (
              <Box style={{ marginTop: '20px' }}>
                <h2>Uploaded Image: {uploadedUrl}</h2>
              </Box>
            )*/}
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>


      <Modal isOpen={isCancelOpen} onClose={onCancelClose} size={'sm'} background="brand.500">
        <ModalOverlay />
        <ModalContent background="brand.500">
          <ModalHeader color="white">Cancel Trade</ModalHeader>
          <ModalCloseButton color="white" />
          <ModalBody>
            <Box style={{ marginTop: '20px' }}>
              <Heading size="sm" color="white">Are you sure you want to cancel this trade</Heading>
            </Box>
            <ModalFooter>
              <Button colorScheme='blue' mr={3} onClick={onCancelClose}>
                Cancel
              </Button>
              <Button variant='ghost' _hover={{ backgroundColor: "white", textColor: "brand.500" }} color="white" onClick={handleCancelTrade} isLoading={cancelLoading} isLoadingText="Wait..." >Proceed</Button>
            </ModalFooter>
          </ModalBody>
        </ModalContent>
      </Modal>

      <Modal isOpen={isTransferAssetOpen} onClose={onTransferAssetClose} size={'sm'} background="brand.500">
        <ModalOverlay />
        <ModalContent background="brand.500">
          <ModalHeader color="white">Release Asset</ModalHeader>
          <ModalCloseButton color="white" />
          <ModalBody>
            <Box style={{ marginTop: '20px' }}>
              <Heading size="sm" color="white">Are you sure you want to release asset for this trade?</Heading>
            </Box>
            <ModalFooter>
              <Button colorScheme='blue' mr={3} onClick={onTransferAssetClose}>
                Cancel
              </Button>
              <Button variant='ghost' _hover={{ backgroundColor: "white", textColor: "brand.500" }} color="white" onClick={handleTransferAsset} isLoading={transferLoading} isLoadingText="Wait..." >Proceed</Button>
            </ModalFooter>
          </ModalBody>
        </ModalContent>
      </Modal>

      <Modal isOpen={isTransferFiatOpen} onClose={onTransferFiatClose} size={'sm'} background="brand.500">
        <ModalOverlay />
        <ModalContent background="brand.500">
          <ModalHeader color="white">Mark as Paid</ModalHeader>
          <ModalCloseButton color="white" />
          <ModalBody>
            <Box style={{ marginTop: '20px' }}>
              <Heading size="sm" color="white">Are you sure you want to mark this trade as paid?</Heading>
            </Box>
            <ModalFooter>
              <Button colorScheme='blue' mr={3} onClick={onTransferFiatClose}>
                Cancel
              </Button>
              <Button variant='ghost' _hover={{ backgroundColor: "white", textColor: "brand.500" }} color="white" onClick={handleTransferFiat} isLoading={transferFiatLoading} isLoadingText="Wait..." >Proceed</Button>
            </ModalFooter>
          </ModalBody>
        </ModalContent>
      </Modal>

      <Box pt={{ base: "30px", md: "40px", xl: "40px" }} p="3" width="100%" mb="30">
        {tradeLoading ? <LoadingTrade /> : (trade && view === "trade" && <Trade trade={trade} disputed={disputed} handleTimeOut={handleTimeOut} setView={setView} onCancelOpen={onCancelOpen} onTransferAssetOpen={onTransferAssetOpen} onTransferFiatOpen={onTransferFiatOpen} cancelLoading={cancelLoading} transferLoading={transferLoading} unreadCount={unreadCount} />)}
        {trade && sellerUser && buyerUser && view === "chat" && <Chat currentUser={currentUser} sellerUser={sellerUser} buyerUser={buyerUser} setRecipientUser={setRecipientUser} tradeId={tradeId} message={message} messages={messages} handleSendMessage={handleSendMessage} setMessage={setMessage} setView={setView} onOpen={onOpen} updateReadStatus={updateReadStatus} />}
      </Box>

    </>
  );
}

const LoadingTrade = () => {
  return (
    <>
      <VStack width="100%" spacing="3">
        <Box width="100%" textAlign="center">
          <Tag><SkeletonText mt="2" noOfLines={1} /></Tag>
        </Box>
        <Box textAlign="center">
          <Heading fontSize={{ sm: "lg", md: "2xl" }} color="white"> <SkeletonText noOfLines={1} /> </Heading>
        </Box>
      </VStack>
      <Center mt="5">
        <Card background="transparent" borderColor="brand.500" borderWidth="1px" width={{ sm: "100%", md: "70%", lg: "50%" }} minH="300">
          <VStack width="100%" spacing="4">
            <Box width="100%">
              <SkeletonText mt="2" noOfLines={2} />
            </Box>
            <Box width="100%">
              <SkeletonText mt="2" noOfLines={2} />
            </Box>
            <Box width="100%">
              <SkeletonText mt="2" noOfLines={2} />
            </Box>
            <Box width="100%">
              <SkeletonText mt="2" noOfLines={2} />
            </Box>
            <Box width="100%">
              <SkeletonText mt="2" noOfLines={2} />
            </Box>
          </VStack>
        </Card>
      </Center>
    </>
  );
};
