import axiosInstance from "./axiosInstance"; // Adjust the path accordingly
import { ethers, BrowserProvider } from "ethers";
import { useWalletClient } from 'wagmi';
import staking from "blockchain/Staking.json";
import token from "blockchain/IBEP20.json";

const CONTRACT_ADDRESS = "0x4F33f9e9B6625F7017F5f51E21bA84f7b86cAa01";
const TOKEN_ADDRESS = "0x89c2847d96f3727d13d85a701277c424de4e5f5b";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

const useStake = () => {
const { data: walletClient, isError, error: walletError } = useWalletClient();

const TotalStaked = async () => {
  try {
    if (isError || !walletClient) throw new Error(`Wallet not connected: ${walletError?.message}`);
    const ethersProvider = new BrowserProvider(walletClient.provider);
    const signer = ethersProvider.getSigner();
    const chainId = await signer.getChainId(); // Get the chainId from signer

    // The Contract object
    const StakeContract = new ethers.Contract(
      CONTRACT_ADDRESS,
      staking.abi,
      signer
    );
    const totalStaked = await StakeContract.getTotalLockedValue();
    const wei = ethers.formatEther(totalStaked);
    return wei;
  } catch (error) {
    return 0;
  }
};

const TotalStakers = async () => {
  try {
    if (isError || !walletClient) throw new Error(`Wallet not connected: ${walletError?.message}`);
    const ethersProvider = new BrowserProvider(walletClient.provider);
    const signer = ethersProvider.getSigner();
    const chainId = await signer.getChainId(); // Get the chainId from signer
    // The Contract object
    const StakeContract = new ethers.Contract(
      CONTRACT_ADDRESS,
      staking.abi,
      signer
    );

    const stakers = await StakeContract.getNumberOfStakers();
    return parseInt(stakers);
  } catch (error) {
    return 0;
  }
};

const StakeOf = async (address) => {
  try {
    if (isError || !walletClient) throw new Error(`Wallet not connected: ${walletError?.message}`);

    const ethersProvider = new BrowserProvider(walletClient.provider);
    const signer = ethersProvider.getSigner();
    const chainId = await signer.getChainId(); // Get the chainId from signer

    // The Contract object
    const StakeContract = new ethers.Contract(
      CONTRACT_ADDRESS,
      staking.abi,
      signer
    );
    const totalStake = await StakeContract.getStakerTotalStakedValue(address);
    const wei = ethers.formatEther(totalStake);
    return wei;
  } catch (error) {
    return 0;
  }
};

const CreateStake = async (amount) => {
  try {
    if (isError || !walletClient) throw new Error(`Wallet not connected: ${walletError?.message}`);

    const ethersProvider = new BrowserProvider(walletClient.provider);
    const signer = ethersProvider.getSigner();
    const chainId = await signer.getChainId(); // Get the chainId from signer
    // The Contract object
    const StakeContract = new ethers.Contract(
      CONTRACT_ADDRESS,
      staking.abi,
      signer
    );

    const Token = new ethers.Contract(TOKEN_ADDRESS, token.abi, signer);
    const wei = ethers.parseEther(amount);
    let transaction = await Token.approve(CONTRACT_ADDRESS, wei);
    await transaction.wait();
    transaction = await StakeContract.stake(wei);
    await transaction.wait();
  } catch (error) {
    if (
      error.hasOwnProperty("data") &&
      error.hasOwnProperty("data") &&
      error.data.hasOwnProperty("message")
    ) {
      throw new Error(error.data.message);
    }
    else if (
      error.hasOwnProperty("error") &&
      error.error.hasOwnProperty("data") &&
      error.error.data.hasOwnProperty("message")
    ) {
      throw new Error(error.error.data.message);
    }
    else {
      throw new Error(error.message);
    }
  }
};

const RemoveStake = async () => {
  try {
    if (isError || !walletClient) throw new Error(`Wallet not connected: ${walletError?.message}`);

    const ethersProvider = new BrowserProvider(walletClient.provider);
    const signer = ethersProvider.getSigner();
    const chainId = await signer.getChainId(); // Get the chainId from signer
    // The Contract object
    const StakeContract = new ethers.Contract(
      CONTRACT_ADDRESS,
      staking.abi,
      signer
    );
    const transaction = await StakeContract.unstake();
    await transaction.wait();
    return true;
  } catch (error) {
    if (
      error.hasOwnProperty("data") &&
      error.hasOwnProperty("data") &&
      error.data.hasOwnProperty("message")
    ) {
      throw new Error(error.data.message);
    }
    else if (
      error.hasOwnProperty("error") &&
      error.error.hasOwnProperty("data") &&
      error.error.data.hasOwnProperty("message")
    ) {
      throw new Error(error.error.data.message);
    }
    else {
      throw new Error(error.message);
    }
  }
};

const RewardOf = async (address) => {
  try {
    if (isError || !walletClient) throw new Error(`Wallet not connected: ${walletError?.message}`);
    const ethersProvider = new BrowserProvider(walletClient.provider);
    const signer = ethersProvider.getSigner();
    const chainId = await signer.getChainId(); // Get the chainId from signer
    // The Contract object
    const StakeContract = new ethers.Contract(
      CONTRACT_ADDRESS,
      staking.abi,
      signer
    );

    const reward = await StakeContract.calculateReward(address);
    const ether = ethers.formatEther(reward, { commify: true, pad: true });
    return ether;
  } catch (error) {
    return 0;
  }
};

return {
  TotalStakers,
  TotalStaked,
  StakeOf,
  CreateStake,
  RewardOf,
  RemoveStake,
  }

};

export default useStake;
