import React, { useState } from "react";
import {
  Box,
  Container,
  SimpleGrid,
  Heading,
  Center,
  Text,
} from "@chakra-ui/react";
import Background from "assets/images/bg-line.svg";

function HowItWorks() {
  return (
    <Container
      maxW={"100%"}
      p={10}
      background="#04040e"
      backgroundImage={`url(${Background})`}
      backgroundPosition="center center"
      backgroundSize="100% auto"
      backgroundRepeat="no-repeat"
      minHeight={'500'}
    >
      <Box width="100%" textAlign={"center"} mt="10">
        <Heading
          fontSize={{ sm: "lg", md: "2xl" }}
          fontFamily="Spantran"
          fontWeight="400"
          lineHeight={"100%"}
          color="white"
        >
          <Text as="span" color="secondary.500">
           How Scrowed Works
          </Text>&nbsp;in Few Easy Steps
        </Heading>
        <HowItWorksText>
          Gain full access without ever giving your private key. We will never ask you for the seed phrase.
        </HowItWorksText>
      </Box>

      <Center>
        <SimpleGrid width="80%" columns={{  sm: 1, md: 2, lg: 4 }} mt="50" spacing="5">
          <Box>
            <Box boxShadow="dark-lg" height="260" p="6" rounded="md" bg="brandScheme.500" borderWidth="1px" borderColor="brand.500">
              <Text
                color="white"
                textAlign="left"
                fontSize={{ sm: "md", md: "lg" }}
                fontFamily="Spantran"
                fontWeight="300"
              >
                STEP ONE
              </Text>
              <Text
                mt="3"
                color="white"
                textAlign="left"
                fontSize={{ sm: "md", md: "lg" }}
                fontFamily="ManropeSemiBold"
                fontWeight="300"
              >
                Connect Wallet
              </Text>
              <Text
                mt="3"
                color="white"
                textAlign="left"
                fontSize={{ sm: "md", md: "lg" }}
                fontFamily="ManropeLight"
              >Sign up on the Scrowed platform by connecting your Web3 wallet
              </Text>
            </Box>
          </Box>

          <Box>
            <Box boxShadow="dark-lg" height="260" p="6" rounded="md" bg="brandScheme.500" borderWidth="1px" borderColor="brand.500">
              <Text
                color="white"
                textAlign="left"
                fontSize={{ sm: "md", md: "lg" }}
                fontFamily="Spantran"
                fontWeight="300"
              >
                STEP TWO
              </Text>
              <Text
                mt="3"
                color="white"
                textAlign="left"
                fontSize={{ sm: "md", md: "lg" }}
                fontFamily="ManropeSemiBold"
                fontWeight="300"
              >
                Find or Create Offer
              </Text>
              <Text
                mt="3"
                color="white"
                textAlign="left"
                fontSize={{ sm: "md", md: "lg" }}
                fontFamily="ManropeLight"
              >
                Interact with an offer in the marketplace or create a new offer
              </Text>
            </Box>
          </Box>

          <Box>
            <Box boxShadow="dark-lg" height="260" p="6" rounded="md" bg="brandScheme.500" borderWidth="1px" borderColor="brand.500">
              <Text
                color="white"
                textAlign="left"
                fontSize={{ sm: "md", md: "lg" }}
                fontFamily="Spantran"
                fontWeight="300"
              >
                STEP THREE
              </Text>
              <Text
                mt="3"
                color="white"
                textAlign="left"
                fontSize={{ sm: "md", md: "lg" }}
                fontFamily="ManropeSemiBold"
                fontWeight="300"
              >
                Open a Trade
              </Text>
              <Text
                mt="3"
                color="white"
                textAlign="left"
                fontSize={{ sm: "md", md: "lg" }}
                fontFamily="ManropeLight"
              >
                Connect with a suitable buyer or seller based on your preferences
              </Text>
            </Box>
          </Box>

          <Box>
            <Box boxShadow="dark-lg" p="6" height="260" rounded="md" bg="brandScheme.500" borderWidth="1px" borderColor="brand.500">
              <Text
                color="white"
                textAlign="left"
                fontSize={{ sm: "md", md: "lg" }}
                fontFamily="Spantran"
                fontWeight="300"
              >
                STEP FOUR
              </Text>
              <Text
                mt="3"
                color="white"
                textAlign="left"
                fontSize={{ sm: "md", md: "lg" }}
                fontFamily="ManropeSemiBold"
                fontWeight="300"
              >
                Complete Trade
              </Text>
              <Text
                mt="3"
                color="white"
                textAlign="left"
                fontSize={{ sm: "md", md: "lg" }}
                fontFamily="ManropeLight"
              >
               Transfer the agreed amount in fiat to the seller. If you’re selling, the buyer will transfer fiat to you.
              </Text>
            </Box>
          </Box>
        </SimpleGrid>
      </Center>

    </Container>
  );
}

const HowItWorksText = ({ children }) => {
  return (
    <Text
      textAlign={"center"}
      color="white"
      fontSize={{ sm: "md", md: "lg" }}
      fontFamily="ManropeLight"
      mt="4"
    >
      {children}
    </Text>
  );
};

export default HowItWorks;
