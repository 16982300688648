import React, { useEffect, useState } from "react";
import {
  Box,
  Heading,
  Text,
  SimpleGrid,
  VStack,
  Skeleton,
  SkeletonText,
  useToast,
  Flex,
} from "@chakra-ui/react";
import Trade from "./components/Trade.js";
import PastTrade from "./components/PastTrade.js";
import { GetActiveTrades, GetUserSellTrades, GetUserBuyTrades } from "services/tradeService";
import Paginate from "components/navigation/Paginate.js";
import { useSelector } from "react-redux";
import { FaHandshake } from "react-icons/fa";

export default function Index() {
  const userState = useSelector((state) => state?.user?.value);
  const token = userState.hasOwnProperty("token") ? userState.token : null;
  const toast = useToast();

  const [tradesLoading, setTradesLoading] = useState(false);
  const [sellTradesLoading, setSellTradesLoading] = useState(false);
  const [buyTradesLoading, setBuyTradesLoading] = useState(false);
  const [activeTradesLoading, setActiveTradesLoading] = useState(false);
  const [sellTrades, setSellTrades] = useState(null);
  const [buyTrades, setBuyTrades] = useState(null);
  const [activeTrades, setActiveTrades] = useState(null);

  const [sellPageCount, setSellPageCount] = useState(0);
  const [sellSortOrder, setSellSortOrder] = useState("desc");
  const [sellFrom, setSellFrom] = useState(0);
  const [sellLimit, setSellLimit] = useState(12);
  const [sellOrderBy, setSellOrderBy] = useState("createdAt");
  const [sellFinished, setSellFinished] = useState(false);

  const [buyPageCount, setBuyPageCount] = useState(0);
  const [buySortOrder, setBuySortOrder] = useState("desc");
  const [buyFrom, setBuyFrom] = useState(0);
  const [buyLimit, setBuyLimit] = useState(12);
  const [buyOrderBy, setBuyOrderBy] = useState("createdAt");
  const [buyFinished, setBuyFinished] = useState(false);

  const getActiveTrades = async () => {
    try {
      setActiveTradesLoading(true);
      const response = await GetActiveTrades(token);
      setActiveTradesLoading(false);
      if (!response) {
        return;
      }
      if (response.error) {
        toast({
          title: response.error || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (!response.success) {
        toast({
          title: response.message || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (response.success === true) {
        setActiveTrades(response.data);
        return;
      }
    } catch (error) {
      toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
      setActiveTradesLoading(false);
    }
  };

  const getUserSellTrades = async () => {
    try {
      const pagination = {
        from: sellFrom,
        limit: sellLimit,
        orderBy: sellOrderBy,
        sortOrder: sellSortOrder,
      };
      setTradesLoading(true);
      const response = await GetUserSellTrades(pagination, token);
      setTradesLoading(false);
      if (!response) {
        return;
      }
      if (response.error) {
        toast({
          title: response.error || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (!response.success) {
        toast({
          title: response.message || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (response.success === true) {
        setSellTrades(response.data.trades);
        setSellPageCount(response.data.count / sellLimit);
        if (response.data?.trades.length === 0) {
          setSellFinished(true);
        }
        return;
      }
    } catch (error) {
      toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
      setTradesLoading(false);
    }
  };

  const getUserBuyTrades = async () => {
    try {
      const pagination = {
        from: buyFrom,
        limit: buyLimit,
        orderBy: buyOrderBy,
        sortOrder: buySortOrder,
      };
      setBuyTradesLoading(true);
      const response = await GetUserBuyTrades(pagination, token);
      setBuyTradesLoading(false);
      if (!response) {
        return;
      }
      if (response.error) {
        toast({
          title: response.error || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (!response.success) {
        toast({
          title: response.message || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (response.success === true) {
        setBuyTrades(response.data.trades);
        setBuyPageCount(response.data.count / buyLimit);
        if (response.data?.trades.length === 0) {
          setBuyFinished(true);
        }
        return;
      }
    } catch (error) {
      toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
      setBuyTradesLoading(false);
    }
  };

  useEffect(() => {
    if (token !== null) {
    getActiveTrades();
    getUserSellTrades();
    getUserBuyTrades();
  }else{
    setBuyTrades(null);
    setSellTrades(null);
    setActiveTrades(null);
  }
  }, [token]);


  return (
    <>
      <Box pt={{ base: "30px", md: "40px", xl: "40px" }} p="3" width="100%">
        <Box width="100%" mt="5" mb="5">
          <Heading color="white" fontSize={{ sm: "md", lg: "xl" }}>
            Active Trades
          </Heading>
        </Box>

        {activeTradesLoading ? (
          <LoadingTrades />
        ) : (
          <VStack
            width="100%"
            mt="10"
            mb="10"
            spacing="5"
          >
            {
              activeTrades !== null && activeTrades.length>0? activeTrades.map((trade) => <Trade trade={trade} />) :  <Flex><FaHandshake color="white" size="25" />&nbsp;<Text align="center" color="white"> No active trade</Text></Flex>
            }
          </VStack>
        )}

        <Box width="100%" mt="10" mb="5">
          <Heading color="white" fontSize={{ sm: "md", lg: "xl" }}>
            Buy Trade History
          </Heading>
        </Box>
        {buyTradesLoading ? (
          <LoadingTrades />
        ) : (

          <VStack
            width="100%"
            mt="10"
            mb="10"
            spacing="5"
          >
            {
              buyTrades !== null && buyTrades.length>0? buyTrades.map((trade) => <PastTrade trade={trade} />) : <Flex> <FaHandshake color="white" size="25"/> &nbsp;<Text align="center" color="white"> No buy trades yet</Text></Flex>
            }
          </VStack>
        )}

        {buyPageCount > 0 && (
          <Box width="100%" mt="2">
            <Paginate
              pageCount={buyPageCount}
              setFrom={buyFrom}
              getRecords={getUserBuyTrades}
            />
          </Box>
        )}


        <Box width="100%" mt="10" mb="5">
          <Heading color="white" fontSize={{ sm: "md", lg: "xl" }}>
            Sell Trade History
          </Heading>
        </Box>
        {sellTradesLoading ? (
          <LoadingTrades />
        ) : (

          <VStack
            width="100%"
            mt="10"
            mb="10"
            spacing="5"
          >
            {
              sellTrades !== null ? sellTrades.map((trade) => <PastTrade trade={trade} />) :  <Flex> <FaHandshake color="white" size="25" /> &nbsp; <Text align="center" color="white">  No sell trades yet</Text> </Flex>
            }
          </VStack>
        )}

        {sellPageCount > 0 && (
          <Box width="100%" mt="2">
            <Paginate
              pageCount={sellPageCount}
              setFrom={sellFrom}
              getRecords={getUserSellTrades}
            />
          </Box>
        )}

      </Box>
    </>
  );
}

const LoadingTrades = () => {
  return (
    <>
      <SimpleGrid
        width="100%"
        mt="10"
        mb="20"
        columns={{ sm: "1", md: "2", lg: "3" }}
        spacing="2"
      >
        <Box>
          <Skeleton height="5px" />
          <SkeletonText mt="2" noOfLines={3} spacing="3" />
          <Skeleton height="5px" />
        </Box>
        <Box>
          <Skeleton height="5px" />
          <SkeletonText mt="2" noOfLines={3} spacing="3" />
          <Skeleton height="5px" />
        </Box>
        <Box>
          <Skeleton height="5px" />
          <SkeletonText mt="2" noOfLines={3} spacing="3" />
          <Skeleton height="5px" />
        </Box>
      </SimpleGrid>
    </>
  );
};
