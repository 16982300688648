import React from "react";
import { Box, Icon, Button, Heading, Text, Container } from "@chakra-ui/react";
import { FaTwitter, FaTelegram } from "react-icons/fa";

export default function Community() {
  return (
    <Container
      maxW={"100%"}
      py={12}
      w="100%"
      background="#04040e"
      backgroundSize="100% auto"
      backgroundRepeat="no-repeat"
    >
      <Box
        mx='auto'
        p={{ base: "20px", md: "30px" }}
        pe='20px'
        pt='50px'
        pb="50px"
        width="100%">
        <Box width="100%">
          <Heading
            as="h3"
            fontSize={{ sm: "lg", md: "2xl" }}
            fontFamily="Spantran"
            fontWeight="400"
            textAlign={"center"}
            color="white">
            Join the <Text as="span" color="secondary.500">Scrowed</Text> Community Today
          </Heading>
          <Text textAlign="center" mt={3} mb={5} fontSize={{ sm: "md", md: "lg" }} color="white">We are here for you</Text>
        </Box>
        <Box width="100%" textAlign={"center"} mt="3">
          <a href="https://x.com/ScrowedHQ">
            <Button size="lg" borderRadius="0" width="130px" height="50px" background="secondary.500" _hover={{background:"brand.500", color: "#ffffff"}}><Icon as={FaTwitter} width="20px" height="20px"  _hover={{color: "#ffffff"}}/>&nbsp;&nbsp;X</Button> &nbsp;&nbsp;
          </a>
          <a href="https://t.me/scrowedhq">
           <Button borderRadius="0" size="lg" width="130px" height="50px" background="secondary.500" _hover={{background:"brand.500", color: "#ffffff"}}><Icon as={FaTelegram} width="20px" height="20px"  _hover={{color: "#ffffff"}}/>&nbsp; &nbsp;Telegram</Button>
          </a>
        </Box>
      </Box>
    </Container>
  );
}