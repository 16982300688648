import React, { useEffect } from 'react';
import {
  Box,
  Image,
  Center,
  Flex,
  HStack,
  Select,
  Input,
  Button,
  Stack,
  Text,
  Icon
} from "@chakra-ui/react";
import moment from 'moment';
import Card from "components/card/Card";
import { FaArrowLeft, FaGavel } from "react-icons/fa";
const { DateTime } = require("luxon");

const Chat = ({ currentUser, sellerUser, buyerUser, setRecipientUser, messages, message, setMessage, setView, handleSendMessage, updateReadStatus }) => {
  const formatTime = (timestamp) => moment(timestamp).format('HH:mm');
  //const formatDate = (timestamp) => moment(timestamp).format('YYYY-MM-DD');

  const formatDate = (timestamp) => {
    const myDateTime = DateTime.fromISO(timestamp);
    return myDateTime.toLocaleString(DateTime.DATE_MED);
  };
  
  let lastDate = null;

  useEffect(() => {
    //update messages read status
    updateReadStatus();
  }, []);

  return (
    <>
      <Center mt="2">
        <Box width={{ sm: "100%", md: "70%", lg: "70%" }}>
          <Flex onClick={() => { setView("trade") }}><Icon as={FaArrowLeft} width="20px" height="20px" color="brand.500" mt="1" />&nbsp;&nbsp;<Text color="brand.500">Back to Trade</Text></Flex>
        </Box>
      </Center>
      <Center mt="5">
        <Card background="transparent" borderColor="brand.500" borderWidth="1px" width={{ sm: "100%", md: "70%", lg: "70%" }} minH="300">
          <Flex h="100vh" py={12}>
            <Flex
              flexDirection="column"
              w="100%"
              h="full"
              roundedTop="lg"
            >
              <Stack
                px={4}
                py={8}
                overflow="auto"
                flex={1}
                css={{
                  '&::-webkit-scrollbar': {
                    width: '4px',
                  },
                  '&::-webkit-scrollbar-track': {
                    width: '6px',
                  },
                  '&::-webkit-scrollbar-thumb': {
                    background: '#d5e3f7',
                    borderRadius: '24px',
                  },
                }}
              >

                {messages !== null && messages.map((message, index) => {
                  const messageDate = message.createdAt !== undefined ? formatDate(message?.createdAt) : formatDate(new Date().toISOString());
                  const showDate = messageDate !== lastDate;
                  lastDate = messageDate;

                  return (
                    <>
                      {showDate && (
                        <Box width="100%" textAlign={"center"}>
                          <Text color="brand.500">{messageDate}</Text>
                        </Box>
                      )}
                      
                      <Flex
                        flexDirection={"row"}
                        key={index}
                        p={4}
                        bg={message.senderName==="Admin"? 'secondary.500': (currentUser._id === message.senderId ? 'brand.500' : 'gray.100')}
                        color={currentUser._id === message.senderId ? 'white' : 'gray.600'}
                        borderRadius="lg"
                        w="fit-content"
                        alignSelf={currentUser._id === message.senderId ? 'flex-end' : 'flex-start'}>
                         { message.upload !== undefined && message.upload? <Image src={message.message} width="100%" height="auto" />: <Text> { message.senderName==="Admin" && <Icon as={FaGavel} size="md" mr="3" /> }  {message.message}</Text> }
                      </Flex>

                      <Box width="100%">
                        <Text textAlign={currentUser._id === message.senderId ? 'right' : 'left'} color="brand.500" fontSize={{ sm: "xs", md: "sm" }}>{formatTime(message.createdAt)}</Text>
                      </Box>
                    </>
                  );
                })}

              </Stack>

              <Box width="100%"> 
                  <Input bg="white" borderWidth="1px" borderColor="brand.500" placeholder="Type your message..." value={message} onChange={e => setMessage(e.target.value)} />
              </Box>
              <HStack p={4} pl="0" pr="0">
                <Select onChange={(e)=>{
                   setMessage(e.target.value);
                }}>
                  <option value="Please release the asset to the buyer as the payment has been confirmed.">Release Asset</option>
                  <option value="Kindly upload proof of payment for this transaction to proceed.">Upload Payment Proof</option>
                  <option value="Please upload the full statement of account for the receiver for verification purposes.">Upload Statement of Account Receiver</option>
                  <option value="Please upload the full statement of account for the sender for verification purposes.">Upload Statement of Account Sender</option>
                  <option value="Please upload the signed statement of account for the receiver to validate the transaction.">Upload Signed Statement of Account Receiver</option>
                  <option value="Please upload the signed statement of account for the sender to validate the transaction.">Upload Signed Statement of Account Sender</option>
                  <option value="Please confirm that you have received the payment and notify us to release the asset.">Confirm Payment Received</option>
                </Select>

                {/*<Icon as={FaFileUpload} width="30px" height="30px" color="brand.500" mt="1" onClick={onOpen} />*/}
                <Select onChange={(e)=>{
                  if(e.target.value===buyerUser._id){
                    setRecipientUser(buyerUser);
                  }
                  if(e.target.value===sellerUser._id){
                    setRecipientUser(sellerUser);
                  }
                }}>
                  <option value="">Select Trader</option>
                  <option value={buyerUser._id}>{buyerUser.username} (Buyer)</option>
                  <option value={sellerUser._id}>{sellerUser.username} (Seller)</option>
                </Select>
                <Button colorScheme="brand" onClick={handleSendMessage}>Send</Button>
              </HStack>
              

            </Flex>
          </Flex>
        </Card>
      </Center>
    </>
  );
};


export default Chat;
