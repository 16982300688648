import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
    Box,
    Heading,
    Text,
    Button,
    SimpleGrid,
    HStack,
    Center,
    Flex,
    Avatar,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    Input,
    InputGroup,
    InputRightElement,
    useClipboard,
    useDisclosure,
    useMediaQuery
} from "@chakra-ui/react";
import { FiThumbsUp, FiThumbsDown, FiCopy, FiLink2, } from "react-icons/fi";
import { FacebookShare, TwitterShare, LinkedinShare, WhatsappShare, TelegramShare, EmailShare } from 'react-share-kit';
import { useSelector } from "react-redux";
import NETWORKS from "contracts/Networks.json";
//import Card from "components/card/Card.js";
export default function SellAdOffer(props) {
    const userState = useSelector((state) => state?.user?.value);
    const userId = userState.hasOwnProperty("userId") ? userState.userId : null;
    const { isOpen: isShareOpen, onOpen: onShareOnOpen, onClose: onShareClose } = useDisclosure();
    const [shareUrl, setShareUrl] = useState('');
    const [copied, setCopied] = useState(false);
    const [title, setTitle] = useState('');
    const [hashtags, setHashtags] = useState([]);
    const { onCopy, hasCopied } = useClipboard(shareUrl);
    const { ad, handleUpdateSellAd, onOpen, setAdId, setAdType, setFundAdData, onFundAdOnOpen } = props;
    const [isMdOrSm] = useMediaQuery("(max-width: 960px)");

    useEffect(() => {
        if (shareUrl === "") return;
        onCopy();
    }, [shareUrl]);

    return (
        <>
            <Modal isOpen={isShareOpen} onClose={onShareClose}>
                <ModalOverlay />
                <ModalContent background="brand.500">
                    <ModalHeader>Share Offer</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Box width="100%" mb="10">
                            <Flex gap="2" flexDirection="row" flexWrap="wrap">
                                <FacebookShare url={shareUrl}
                                    quote={title}
                                    hashtag={hashtags} />
                                <TwitterShare
                                    url={shareUrl}
                                    title={title}
                                    hashtags={hashtags} />
                                <LinkedinShare
                                    url={shareUrl} />
                                <WhatsappShare
                                    url={shareUrl}
                                    title={title}
                                    separator=":: "
                                />
                                <TelegramShare url={shareUrl} />
                                <EmailShare
                                    url={shareUrl}
                                    subject={title}
                                    body="You can trade with me by visiting my Trade page"
                                />
                                <Link to={shareUrl}><FiLink2 mt="2" size="50" color="brand.500" /></Link>
                            </Flex>

                            <InputGroup mt="4">
                                <Input color="white" value={shareUrl} isReadOnly />
                                <InputRightElement>
                                    <Button onClick={(e) => { setCopied(true) }} size="sm">
                                        <FiCopy />
                                    </Button>
                                </InputRightElement>
                            </InputGroup>
                            <Text textAlign={'center'}>{copied && hasCopied ? 'Copied' : ''}</Text>
                        </Box>
                    </ModalBody>
                </ModalContent>
            </Modal>


            <HStack width="100%" background="brandScheme.500" borderRadius={'5'} borderLeftWidth="2px" borderLeftColor="secondary.500">
                <Box width={{ sm: "85%", md: "90%" }} p="5">
                    <SimpleGrid columns={{ base: 1, sm: 1, md: 4 }} width="100%" spacing={{ sm: "5", md: "2" }}>
                        <Box textAlign="left">
                            {isMdOrSm && (<Text fontWeight="bold" color="gray.400" fontSize={{ sm: "sm", md: "md" }}>Merchant</Text>)}
                            <Link to={"/app/user/" + ad.owner.username}>
                                <Heading textDecoration="underline" fontSize={{ sm: "md", md: "lg" }} color="white">{ad.owner.username}</Heading>
                            </Link>
                            <Flex color="gray.400" gap='1'>
                                <Text textAlign="left" fontSize={{ sm: "sm", md: "md" }}>
                                    {ad.owner.completedTrades} Orders
                                </Text>
                                <Text> &nbsp; </Text>
                                {
                                    (() => {
                                        const totalTrades = (ad.owner.completedTrades === 0 ? 1 : ad.owner.completedTrades) + ad.owner.incompleteTrades;
                                        const percentageCompletion = ((ad.owner.completedTrades === 0 ? 1 : ad.owner.completedTrades) * 100) / totalTrades;
                                        return (
                                            <>
                                                <Text fontSize={{ sm: "sm", md: "md" }}> {percentageCompletion}% Completion</Text>
                                            </>
                                        )
                                    })()
                                }
                            </Flex>
                            <Flex color="gray.400" gap='1'>
                                <Text textAlign="left">
                                    <Flex>
                                        <Text fontSize={{ sm: "sm", md: "md" }}>{ad.owner.positiveTrades}</Text>&nbsp;<Text mt="1"><FiThumbsUp /></Text>
                                    </Flex>
                                </Text>
                                <Text textAlign="left">
                                    <Flex>
                                        <Text fontSize={{ sm: "sm", md: "md" }}>{ad.owner.negativeTrades}</Text>&nbsp;<Text mt="1"><FiThumbsDown /></Text>
                                    </Flex>
                                </Text>
                            </Flex>
                        </Box>
                        <Box textAlign="left">
                            {isMdOrSm && <Text fontWeight="bold" color="gray.400" fontSize={{ sm: "sm", md: "md" }}>Price</Text>}
                            <Heading fontSize={{ sm: "sm", md: "md" }} color="white">
                                {new Intl.NumberFormat('en-US', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(ad.price["$numberDecimal"])} {ad.currency.symbol}
                            </Heading>
                        </Box>

                        <Box textAlign="left">
                            {isMdOrSm && <Text fontWeight="bold" color="gray.400" fontSize={{ sm: "sm", md: "md" }}>Available Order | Limit</Text>}
                            <Flex gap="2">
                                <Heading fontSize={{ sm: "sm", md: "md" }} color="white">
                                    {ad.asset.symbol !== "USDT" ? new Intl.NumberFormat('en-US', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 8 }).format(ad.unit["$numberDecimal"]) : new Intl.NumberFormat('en-US', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(ad.unit["$numberDecimal"])}
                                    &nbsp;{ad.asset?.symbol}
                                </Heading>
                                &nbsp;
                                {ad.network !== null && <Avatar src={NETWORKS[ad.network]['ICONURL']} size="xs" />}
                            </Flex>
                            <Heading fontSize={{ sm: "sm", md: "md" }} color="white">
                                {new Intl.NumberFormat('en-US', { style: 'decimal' }).format(ad.lowerLimit)} - {new Intl.NumberFormat('en-US', { style: 'decimal' }).format(ad.upperLimit)} {ad.currency.symbol}
                            </Heading>
                        </Box>
                        <Box textAlign="left">
                            {isMdOrSm && <Text fontWeight="bold" color="gray.400" fontSize={{ sm: "sm", md: "md" }}>Payment Method</Text>}
                            <Heading fontSize={{ sm: "sm", md: "md" }} color="white">{ad.paymentMethod?.paymentMethod?.name} </Heading>
                        </Box>
                    </SimpleGrid>
                </Box>
                <Box width={{ sm: "15%", md: "10%" }} textAlign="center" color="gray.400" p="5">
                    {
                        userId !== ad.owner._id &&
                        <Center>
                            <Link to={"/app/ads/selloffer/" + ad._id}><Button colorScheme='secondary' size={{ sm: "sm", md: "md" }} p="2" variant='solid' borderRadius="3" fontSize={{ sm: "sm", md: "md" }}>Buy</Button></Link>
                        </Center>
                    }
                </Box>
            </HStack>
        </>
    );
};
