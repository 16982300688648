import { simulateContract, waitForTransactionReceipt, readContract, writeContract, getAccount, getChainId } from '@wagmi/core';
import { parseEther } from 'viem';
import axiosInstance from './axiosInstance'; // Adjust the path accordingly
import SELLADABI from "../contracts/Advert.json";
import BUYADABI from "../contracts/BuyAdvert.json";
import TOKENABI from "../contracts/IBEP20.json";
import NETWORKS from "../contracts/Networks.json";
import { config } from 'variables/connector';

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

const CreateBuyAdvert = async (buyAdData, token) => {
  try {
    const response = await axiosInstance.post(SERVER_URL + "/ad/buy/create", buyAdData, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      mode: "cors",
    });
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

const CreateSellAdvert = async (sellAdData, token) => {
  try {
    const response = await axiosInstance.post(SERVER_URL + "/ad/sell/create", sellAdData, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      mode: "cors",
    });
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

const ContractCreateSellAdvert = async (sellAdData) => {
  try {
    const { connector } = getAccount(config);
    const chainId = getChainId(config);

    const ASSET_CONTRACT = NETWORKS[chainId][sellAdData.symbol];
    const SELL_AD_CONTRACT = NETWORKS[chainId]["SELL_AD_CONTRACT"];

    const wei = parseEther(String(sellAdData.amount));

    const approvalTx = await writeContract(config, {
      abi: TOKENABI.abi,
      address: ASSET_CONTRACT,
      functionName: 'approve',
      args: [SELL_AD_CONTRACT, wei],
      chainId: chainId,
      connector
    });

     // Wait for the approval transaction to be mined
     const approvalReceipt = await waitForTransactionReceipt(config, {
      hash: approvalTx,
    });

    //check transaction for errors
    const { request } = await simulateContract(config, {
      abi: SELLADABI.abi,
      address: SELL_AD_CONTRACT,
      functionName: 'createAdvert',
      args: [sellAdData.advertID, wei, ASSET_CONTRACT],
      chainId: chainId,
      connector
    });

    //commit transaction
    let hash = await writeContract(config, request);
    
    //wait for transaction receipt
    const transaction = await waitForTransactionReceipt(config, {
      hash: hash,
    });

    return {
      success: true,
      transactionHash: hash
    };

  } catch (error) {
    let errorMessage = "An unexpected error occurred";
    if (error.hasOwnProperty("code")) {
      switch (error.code) {
        case 4001:
          errorMessage = "Transaction rejected by user";
          break;
        case "ACTION_REJECTED":
          errorMessage = "Transaction rejected by user";
          break;
        default:
          errorMessage = error.message || "An unexpected error occurred";
      }
    } else if (error.hasOwnProperty("error")) {
      if (error.error.hasOwnProperty("data") && error.error.data.hasOwnProperty("message")) {
        errorMessage = error.error.data.message;
      } else if (error.error.hasOwnProperty("message")) {
        errorMessage = error.error.message;
      }
    } else if (error.hasOwnProperty("message")) {
      errorMessage = error.message;
    }
    throw new Error(`Transaction failed with an unknown error, contact support`);
  }
};

const ContractCreateBuyAdvert = async (buyAdData) => {
  try {
    const { connector } = getAccount(config);
    const chainId = getChainId(config);

    const ASSET_CONTRACT = NETWORKS[chainId][buyAdData.symbol];
    const BUY_AD_CONTRACT = NETWORKS[chainId]["SELL_TRADE_CONTRACT"];

    //check transaction for errors
    const { request } = await simulateContract(config, {
      abi: BUYADABI.abi,
      address: BUY_AD_CONTRACT,
      functionName: 'createBuyAdvert',
      args: [buyAdData.advertID, ASSET_CONTRACT],
      chainId: chainId,
      connector
    });

    const hash = await writeContract(config, request);

    //wait for transaction receipt
    const txReceipt = await waitForTransactionReceipt(config, {
      hash: hash,
    })

    return {
      success: true,
      transactionHash: hash
    };

  } catch (error) {
    let errorMessage = "An unexpected error occurred";
    if (error.hasOwnProperty("code")) {
      switch (error.code) {
        case 4001:
          errorMessage = "Transaction rejected by user";
          break;
        case "ACTION_REJECTED":
          errorMessage = "Transaction rejected by user";
          break;
        default:
          errorMessage = error.message || "An unexpected error occurred";
      }
    } else if (error.hasOwnProperty("error")) {
      if (error.error.hasOwnProperty("data") && error.error.data.hasOwnProperty("message")) {
        errorMessage = error.error.data.message;
      } else if (error.error.hasOwnProperty("message")) {
        errorMessage = error.error.message;
      }
    } else if (error.hasOwnProperty("message")) {
      errorMessage = error.message;
    }
    throw new Error(`Transaction failed with an unknown error, contact support`);
    //throw new Error(`Transaction failed: ${errorMessage}`);
  }
};

const ContractFundSellAdvert = async (fundAdData) => {
  try {
    const { connector } = getAccount(config);
    const chainId = getChainId(config);

    const ASSET_CONTRACT = NETWORKS[chainId][fundAdData.symbol];
    const SELL_AD_CONTRACT = NETWORKS[chainId]["SELL_AD_CONTRACT"];

    const wei = parseEther(String(fundAdData.amount));

    const approvalTx = await writeContract(config, {
      abi: TOKENABI.abi,
      address: ASSET_CONTRACT,
      functionName: 'approve',
      args: [SELL_AD_CONTRACT, wei],
      chainId: chainId,
      connector
    });

    // Wait for the approval transaction to be mined
    const approvalReceipt = await waitForTransactionReceipt(config, {
       hash: approvalTx,
     });

    //check transaction for errors
    const { request } = await simulateContract(config, {
      abi: SELLADABI.abi,
      address: SELL_AD_CONTRACT,
      functionName: 'deposit',
      args: [fundAdData.advertID, wei],
      chainId: chainId,
      connector
    });

    //commit transaction
    const hash = await writeContract(config, request);

    //wait for transaction receipt
    const txReceipt = await waitForTransactionReceipt(config, {
      hash: hash,
    })

    return {
      success: true,
      transactionHash: hash
    };

  } catch (error) {
    let errorMessage = "An unexpected error occurred";
    if (error.hasOwnProperty("code")) {
      switch (error.code) {
        case 4001:
          errorMessage = "Transaction rejected by user";
          break;
        case "ACTION_REJECTED":
          errorMessage = "Transaction rejected by user";
          break;
        default:
          errorMessage = error.message || "An unexpected error occurred";
      }
    } else if (error.hasOwnProperty("error")) {
      if (error.error.hasOwnProperty("data") && error.error.data.hasOwnProperty("message")) {
        errorMessage = error.error.data.message;
      } else if (error.error.hasOwnProperty("message")) {
        errorMessage = error.error.message;
      }
    } else if (error.hasOwnProperty("message")) {
      errorMessage = error.message;
    }

    throw new Error(`Transaction failed with an unknown error, contact support`);
  }
};

const ContractCloseSellAdvert = async (advertId) => {
  try {
    const { connector } = getAccount(config);
    const chainId = getChainId(config);

    const SELL_AD_CONTRACT = NETWORKS[chainId]["SELL_AD_CONTRACT"];

    //check transaction for errors
    const { request } = await simulateContract(config, {
      abi: SELLADABI.abi,
      address: SELL_AD_CONTRACT,
      functionName: 'closeAdvert',
      args: [advertId],
      chainId: chainId,
      connector
    });

    const hash = await writeContract(config, request);
    //wait for transaction receipt
    const txReceipt = await waitForTransactionReceipt(config, {
      hash: hash,
    })

    return {
      success: true,
      transactionHash: hash
    };

  } catch (error) {
    let errorMessage = "An unexpected error occurred";
    if (error.hasOwnProperty("code")) {
      switch (error.code) {
        case 4001:
          errorMessage = "Transaction rejected by user";
          break;
        case "ACTION_REJECTED":
          errorMessage = "Transaction rejected by user";
          break;
        default:
          errorMessage = error.message || "An unexpected error occurred";
      }
    } else if (error.hasOwnProperty("error")) {
      if (error.error.hasOwnProperty("data") && error.error.data.hasOwnProperty("message")) {
        errorMessage = error.error.data.message;
      } else if (error.error.hasOwnProperty("message")) {
        errorMessage = error.error.message;
      }
    } else if (error.hasOwnProperty("message")) {
      errorMessage = error.message;
    }
    throw new Error(`Transaction failed with an unknown error, contact support`);
  }
};

const ContractCloseBuyAdvert = async (advertId) => {
  try {
    const { connector } = getAccount(config);
    const chainId = getChainId(config);

    const BUY_AD_CONTRACT = NETWORKS[chainId]["SELL_TRADE_CONTRACT"];
    //check transaction for errors

    const { request } = await simulateContract(config, {
      abi: BUYADABI.abi,
      address: BUY_AD_CONTRACT,
      functionName: 'closeAdvert',
      args: [advertId],
      chainId: chainId,
      connector
    });

    const hash = await writeContract(config, request);
    //wait for transaction receipt
    const txReceipt = await waitForTransactionReceipt(config, {
      hash: hash,
    })

    return {
      success: true,
      transactionHash: hash
    };

  } catch (error) {
    let errorMessage = "An unexpected error occurred";
    if (error.hasOwnProperty("code")) {
      switch (error.code) {
        case 4001:
          errorMessage = "Transaction rejected by user";
          break;
        case "ACTION_REJECTED":
          errorMessage = "Transaction rejected by user";
          break;
        default:
          errorMessage = error.message || "An unexpected error occurred";
      }
    } else if (error.hasOwnProperty("error")) {
      if (error.error.hasOwnProperty("data") && error.error.data.hasOwnProperty("message")) {
        errorMessage = error.error.data.message;
      } else if (error.error.hasOwnProperty("message")) {
        errorMessage = error.error.message;
      }
    } else if (error.hasOwnProperty("message")) {
      errorMessage = error.message;
    }
    throw new Error(`Transaction failed with an unknown error, contact support`);
  }
};

const UpdateSellAdvert = async (sellAdData, adId, token) => {
  try {
    const response = await axiosInstance.patch(SERVER_URL + "/ad/sell/" + adId, sellAdData, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      mode: "cors",
    });
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

const UpdateBuyAdvert = async (buyAdData, adId, token) => {
  try {
    const response = await axiosInstance.patch(SERVER_URL + "/ad/buy/" + adId, buyAdData, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      mode: "cors",
    });
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

const GetUserSellAd = async (adId, token) => {
  try {
    const response = await axiosInstance.get(
      SERVER_URL + "/ad/sell/user/" + adId,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        mode: "cors",
      }
    );
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

const GetSellAd = async (adId, token) => {
  try {
    const response = await axiosInstance.get(
      SERVER_URL + "/ad/sell/" + adId,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        mode: "cors",
      }
    );
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

const GetBuyAd = async (adId, token) => {
  try {
    const response = await axiosInstance.get(
      SERVER_URL + "/ad/buy/" + adId,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        mode: "cors",
      }
    );
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

const GetUserBuyAd = async (adId, token) => {
  try {
    const response = await axiosInstance.get(
      SERVER_URL + "/ad/buy/user/" + adId,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        mode: "cors",
      }
    );
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

const GetUserSellAds = async (userId, filter, token) => {
  try {
    const response = await axiosInstance.post(
      SERVER_URL + "/ad/sell/userads/" + (userId !== null ? userId : ""),
      filter,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        mode: "cors",
      }
    );
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

const GetUserBuyAds = async (userId, filter, token) => {
  try {
    const response = await axiosInstance.post(
      SERVER_URL + "/ad/buy/userads/" + (userId !== null ? userId : ""),
      filter,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        mode: "cors",
      }
    );
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

const GetSellAds = async (filter, pagination, token) => {
  try {
    const response = await axiosInstance.post(
      SERVER_URL + "/ad/sell/all?from=" +
      pagination.from +
      "&limit=" +
      pagination.limit +
      "&orderBy=" +
      pagination.orderBy +
      "&sortOrder=" +
      pagination.sortOrder,
      filter,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        mode: "cors",
      }
    );
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

const GetBuyAds = async (filter, pagination, token) => {
  try {
    const response = await axiosInstance.post(
      SERVER_URL + "/ad/buy/all?from=" +
      pagination.from +
      "&limit=" +
      pagination.limit +
      "&orderBy=" +
      pagination.orderBy +
      "&sortOrder=" +
      pagination.sortOrder,
      filter,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        mode: "cors",
      }
    );
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

export {
  CreateBuyAdvert,
  CreateSellAdvert,
  ContractCreateSellAdvert,
  ContractCreateBuyAdvert,
  ContractFundSellAdvert,
  ContractCloseSellAdvert,
  ContractCloseBuyAdvert,
  UpdateSellAdvert,
  UpdateBuyAdvert,
  GetUserSellAds,
  GetUserBuyAds,
  GetUserBuyAd,
  GetUserSellAd,
  GetSellAd,
  GetBuyAd,
  GetBuyAds,
  GetSellAds
};
