/*eslint-disable*/
import React from "react";
import {
  Flex,
  List,
  ListItem,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";

export default function Footer() {
  let textColor = useColorModeValue("brand.700", "white");
  let linkColor = useColorModeValue("brand.700","white");
  return (
    <>
      <Flex  flexDirection={{
        base: "column",
        lg: "row",
      }}
      alignItems={{
        base: "center",
        xl: "start",
      }}
      px={{ base: "10px", md: "0px" }}
      pb='30px'>
      <List display='flex'>
        <ListItem
          me={{
            base: "20px",
            md: "44px",
          }}>
          <a
            fontWeight='500'
            color={linkColor}
            href='https://swaptrex.tawk.help/'>
           <b>Support</b>
          </a>
        </ListItem>
        <ListItem
          me={{
            base: "20px",
            md: "44px",
          }}>
          <Link
            fontWeight='500'
            color={linkColor}
            to='/web/terms'>
            <b>Terms of Service</b>
          </Link>
        </ListItem>
        <ListItem
          me={{
            base: "20px",
            md: "44px",
          }}>
          <Link
            fontWeight='bold'
            color={linkColor}
            to='/web/privacy'>
            <b>Privacy Policy</b>
          </Link>
        </ListItem>
      </List>
    </Flex>
    <Flex
      zIndex='3'
      flexDirection={{
        base: "column",
        lg: "row",
      }}
      alignItems={{
        base: "center",
        xl: "start",
      }}
      px={{ base: "10px", md: "0px" }}
      pb='30px'>
        <Text
      color={textColor}
      textAlign={{
        base: "center",
        xl: "start",
      }}>
      <Link
          mx='3px'
          color={textColor}
          to='/web/index'
          target='_blank'
          fontWeight='700'>
         &copy; Swaptrex,
        </Link>
      </Text>
      <Text
       ml="3px"
        color={textColor}
        textAlign={{
          base: "center",
          xl: "start",
        }}
        mb={{ base: "20px", lg: "0px" }}>
        {" "}
         {1900 + new Date().getYear()}
        <Text as='span' fontWeight='500' ms='4px'>
        All Rights Reserved.
          
        </Text>
      </Text>
      </Flex>

    
   </>
  );
}
