import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyC120KsuiJ-L4CvCP_TIM0UZQoL_2XrC8U",
    authDomain: "scrowed-67af4.firebaseapp.com",
    projectId: "scrowed-67af4",
    storageBucket: "scrowed-67af4.appspot.com",
    messagingSenderId: "264689368800",
    appId: "1:264689368800:web:277e308459bc6631fb1a85",
    measurementId: "G-DXCR2T6BB0"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

export { auth, db };

