import React, { useCallback, useState, useEffect } from "react";
import { Box } from "@chakra-ui/react";
import { Helmet } from "react-helmet";
import Features from "./components/Features.js";
import FAQs from "./components/FAQs.js";
import About from "./components/About.js";
import HowItWorks from "./components/HowItWorks.js";
import Community from "./components/Community.js";

const defaultLabelStyle = {
  fontSize: '3px',
  fontFamily: 'sans-serif',
  fill: '#FFF'
};

const options = {
  "fullScreen": {
    "enable": true,
  },
  "particles": {
    "number": {
      "value": 80,
      "density": {
        "enable": true,
        "value_area": 800
      }
    },
    "color": {
      "value": "#ffffff"
    },
    "shape": {
      "type": "circle"
    },
    "opacity": {
      "value": 1,
      "random": true,
      "anim": {
        "enable": true,
        "speed": 1,
        "opacity_min": 0,
        "sync": false
      }
    },
    "size": {
      "value": 3,
      "random": true,
      "anim": {
        "enable": false,
        "speed": 4,
        "size_min": 0.3,
        "sync": false
      }
    },
    "line_linked": {
      "enable": false,
      "distance": 150,
      "color": "#ffffff",
      "opacity": 0.4,
      "width": 1
    },
    "move": {
      "enable": true,
      "speed": 1,
      "direction": "none",
      "random": true,
      "straight": false,
      "out_mode": "out",
      "bounce": false,
      "attract": {
        "enable": false,
        "rotateX": 600,
        "rotateY": 600
      }
    }
  },
  "interactivity": {
    "events": {
      "onhover": {
        "enable": false,
        "mode": "bubble"
      },
      "onclick": {
        "enable": false,
        "mode": "repulse"
      },
      "resize": true
    },
    "modes": {
      "grab": {
        "distance": 400,
        "line_linked": {
          "opacity": 1
        }
      },
      "bubble": {
        "distance": 250,
        "size": 0,
        "duration": 2,
        "opacity": 0,
        "speed": 3
      },
      "repulse": {
        "distance": 400,
        "duration": 0.4
      },
      "push": {
        "particles_nb": 4
      },
      "remove": {
        "particles_nb": 2
      }
    }
  },
  "retina_detect": true,
  "background": {
    "position": "50% 50%",
    "repeat": "no-repeat",
    "size": "20%"
  }
};

export default function Home() {

  return (
    <>
      <Box minH='100vh'>
        <Helmet>
          <title>Scrowed: Decentralized P2P Crypto Trading</title>
          <meta name="description" content="Buy and Sell Crypto Securely with Decentralized Escrow on Multiple Blockchain Networks" />
        </Helmet>
   
        <About />
        {/*<Features />*/}
        <HowItWorks />
        <Community />
        <FAQs />
      </Box>
    </>
  );
}