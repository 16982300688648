import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import {
  Flex,
  Box,
  Image,
  Stack,
  Button,
  Heading,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Icon,
  VStack,
  ModalCloseButton,
  useDisclosure,
} from "@chakra-ui/react";
import { MdArrowForward } from "react-icons/md"
// Custom components
import DefaultAuth from "layouts/auth/Default";
// Assets
import illustration from "assets/images/illustration.png";
import Logo from "assets/images/logo.png";
import { useAccount, useChainId, useDisconnect, useConnect } from 'wagmi'
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setUser } from "utils/userSlice";
import { UserLogin } from "services/userService";

function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toast = useToast();
  const { isOpen: isConnectOpen, onOpen: onConnectOpen, onClose: onConnectClose } = useDisclosure();
  const [loading, setLoading] = useState(false);
  const { connectors, connect } = useConnect();
  const { disconnect } = useDisconnect();
  const { isConnected, address } = useAccount();

  const user = useSelector((state) => state?.user?.value);
  const isLoggedIn = user.hasOwnProperty("token") ? true : false;

  const login = async () => {
    try {
      setLoading(true);
      const response = await UserLogin(address);
      setLoading(false);
      if (response.error) {
        toast({
          title: response.error || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (!response.success) {
        toast({
          title: response.message || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (response.success) {

        if (!response.data.isAdmin) {
          toast({
            title: "You are not allowed here",
            status: "error",
            duration: 9000,
            position: "top-right",
            isClosable: true,
          });
          return;
        }
        if (address.toLowerCase() !== response.data.address.toLowerCase()) {
          toast({
            title: "Invalid login",
            status: "error",
            duration: 9000,
            position: "top-right",
            isClosable: true,
          });
          return;
        }
        dispatch(setUser(response.data));
        navigate("/admin/dashboard");
        return;
      }
    } catch (error) {
      setLoading(false);
      toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
    }
  };


  const handleConnect = async (connector) => {
    try {
      connect({ connector });
    } catch (error) {
      console.error("Failed to connect to the chain:", error);
    }
  };


  const handleLogout = async () => {
    disconnect();
    dispatch(setUser({}));
  };

  const ethereumAddressDisplay = (ethereumAddress) => {
    // Assuming ethereumAddress is a string with a valid Ethereum address
    const displayedAddress =
      ethereumAddress.length >= 6
        ? ethereumAddress.slice(0, 3) + ".." + ethereumAddress.slice(-3)
        : ethereumAddress;

    return displayedAddress;
  };

  useEffect(() => {
    const checkConnection = () => {
      if (isConnected && !isLoggedIn) {
        login();
      }
    }
    // Adding a timeout to ensure the state is fully updated
    const timeoutId = setTimeout(checkConnection, 1000);
    return () => clearTimeout(timeoutId);

  }, [isConnected]);

  return (
    <>
      <Modal isOpen={isConnectOpen} onClose={onConnectClose} size={'sm'} background="brand.500">
        <ModalOverlay />
        <ModalContent background="brand.500">
          <ModalHeader color="white">Choose Wallet</ModalHeader>
          <ModalCloseButton color="white" />
          <ModalBody>
           <VStack spacing="3" width="100%" mb="10">
            {connectors.map((connector) => {
              return (
                <>
                  {
                    connector.name === "Coinbase Wallet" &&
                    <Button
                      key={connector.uid}
                      width="100%"
                      leftIcon={<Image src={Logo} boxSize="24px" />}
                      onClick={() => { handleConnect(connector); onConnectClose(); }}
                      bg="#0052ff"
                      color="white"
                      _hover={{ bg: '#003fcc' }}
                      _active={{ bg: '#002fb8' }}
                      borderRadius="8px"
                      padding="12px 20px" >
                      Connect {connector.name}
                    </Button>
                  }
                  {
                    connector.name === "MetaMask" &&
                    <Button
                      key={connector.uid}
                      width="100%"
                      leftIcon={<Image src={Logo} boxSize="24px" />}
                      onClick={() => { handleConnect(connector); onConnectClose(); }}
                      bg="#f6851b"
                      color="white"
                      _hover={{ bg: '#e07617' }}
                      _active={{ bg: '#d06613' }}
                      borderRadius="8px"
                      padding="12px 20px"
                    >
                     Connect {connector.name}
                    </Button>
                  }

                  {
                    connector.name === "WalletConnect" &&
                    <Button
                      key={connector.uid}
                      leftIcon={<Image src={Logo} boxSize="24px" />}
                      onClick={() => { handleConnect(connector); onConnectClose(); }}
                      width="100%"
                      bg="#3b99fc"
                      color="white"
                      _hover={{ bg: '#337acc' }}
                      _active={{ bg: '#2b66b0' }}
                      borderRadius="8px"
                      padding="12px 20px"
                    >
                      Connect {connector.name}
                    </Button>
                  }
                </>)
            })}
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>

      <Helmet>
        <title>Login | Access your account</title>
        <meta
          name="description"
          content="Log in to your account"
        />
        <meta
          name="keywords"
          content="Swaptrex"
        />
      </Helmet>
      <DefaultAuth illustrationBackground={illustration} image={illustration}>
        <Flex
          maxW={{ base: "100%", md: "100%" }}
          w="100%"
          h="100%"
          mb="10"
          alignItems="start"
          justifyContent="center"
          flexDirection="column"
        >
          <Flex minH={"100vh"} width={"100%"} mt="10" justify={"center"}>
            <Stack
              spacing={8}
              mx={"auto"}
              w={{ base: "95%", sm: "95%", md: "70%", lg: "65%" }}
            >
              <Stack align={"center"}>
                <Image src={Logo} alt="Scrowed" height="auto" width="60px" mb="5" />

                <Heading
                  fontFamily="ManropeSemiBold"
                  fontSize={{ base: "sm", sm: "md", md: "lg", lg: "xl" }}
                  textAlign={"center"}
                  mt="10"
                >
                  Sign In
                </Heading>
                {/*<Text
                  fontFamily="ManropeLight"
                  fontSize={{ base: "sm", sm: "md", md: "lg", lg: "xl" }}
                >
                  Click on the Connect
                </Text> */}
              </Stack>
              <Box p={2} width="100%" textAlign="center">
                {
                  !isConnected &&
                  <Button
                    onClick={onConnectOpen}
                    className="banner-btn"
                    size="md"
                    rounded={"full"}
                    color="brand.500"
                    fontFamily="ManropeLight"
                    _hover={{
                      bg: ["primary.100", "primary.100", "primary.600", "primary.600"],
                    }}
                  >
                    Connect
                    <Icon
                      as={MdArrowForward}
                      width="20px"
                      height="20px"
                      color="white"
                    />
                  </Button>
                }

                {
                  isConnected &&
                  <Button
                    onClick={handleLogout}
                    className="banner-btn"
                    size="md"
                    rounded={"full"}
                    color="brand.500"
                    fontFamily="ManropeLight"
                    _hover={{
                      bg: ["primary.100", "primary.100", "primary.600", "primary.600"],
                    }}
                  >
                    Disconnect
                    <Icon
                      as={MdArrowForward}
                      width="20px"
                      height="20px"
                      color="white"
                    />
                  </Button>
                }

              </Box>
            </Stack>
          </Flex>
        </Flex>
      </DefaultAuth>
    </>
  );
}

export default Login;
