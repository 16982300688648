import React from "react";
import {
  Box,
  VStack,
  Heading,
  Text,
  List,
  ListIcon,
  ListItem,
  OrderedList,
} from "@chakra-ui/react";
import { MdCheckCircle } from "react-icons/md";
import { Helmet } from "react-helmet";
import Header from "components/navigation/Header.js";

export default function Terms() {
  return (
    <Box minH="100vh" background="#070b24">
      <Helmet>
        <title>Terms | Terms and Conditions</title>
        <meta
          name="description"
          content="Read our terms and conditions carefully before using Scrowed.com, a decentralized peer-to-peer cryptocurrency trading platform. These terms outline the rules, regulations, and responsibilities that govern the use of our service. By using our platform, you agree to abide by our terms and conditions."
        />
        <meta
          name="keywords"
          content="decentralized, cryptocurrency, peer-to-peer, trading, blockchain, smart contracts, crypto-fiat trading, Scrowed, Scrowed.com"
        />
      </Helmet>
      <Header />

      <VStack spacing="2" background="brand.500">
        <Box>
          <Heading as="h3" mt="10" color="white">
            Terms of Service
          </Heading>
        </Box>
        <Box p={{ base: "4", sm: "10", md: "20" }} fontSize={{ base: "md", sm: "lg", md: "xl" }} color="white">
          <Text fontSize={{ base: "md", sm: "lg", md: "xl" }}>
            Effective Date: 19th of August, 2024
          </Text>

          <Text fontSize={{ base: "md", sm: "lg", md: "xl" }} mt="4">
            Welcome to <b>Scrowed.com</b>, operated by <b>Tradersfield Ltd</b>. These Terms of Service ("Terms") govern your use of our decentralized peer-to-peer cryptocurrency trading platform. Please read these Terms carefully before using our website or services. By accessing or using Scrowed.com, you agree to be bound by these Terms.
          </Text>

          <OrderedList spacing={4} pl={4} mt="4">
            <ListItem>
              <Text fontWeight="bold" fontSize={{ base: "md", sm: "lg", md: "xl" }}>
                Introduction
              </Text>
              <Text fontSize={{ base: "md", sm: "lg", md: "xl" }}>
                Scrowed.com (the "Platform") is owned and operated by Tradersfield Ltd ("Tradersfield," "we," "us," or "our"). By accessing or using our Platform, you agree to comply with and be bound by these Terms of Service ("Terms"). If you do not agree to these Terms, you may not access or use the Platform.
              </Text>
            </ListItem>

            <ListItem>
              <Text fontWeight="bold" fontSize={{ base: "md", sm: "lg", md: "xl" }}>
                Definitions
              </Text>
              <Text fontSize={{ base: "md", sm: "lg", md: "xl" }}>
                <b>Platform:</b> Refers to Scrowed.com and all associated services, features, and functionalities.
              </Text>
              <Text fontSize={{ base: "md", sm: "lg", md: "xl" }}>
                <b>User:</b> Refers to any individual or entity accessing or using the Platform.
              </Text>
              <Text fontSize={{ base: "md", sm: "lg", md: "xl" }}>
                <b>Smart Contract:</b> Refers to self-executing contracts with the terms of the agreement directly written into lines of code on the blockchain.
              </Text>
              <Text fontSize={{ base: "md", sm: "lg", md: "xl" }}>
                <b>Cryptocurrency:</b> Refers to digital or virtual currency that uses cryptography for security and operates independently of a central authority.
              </Text>
              <Text fontSize={{ base: "md", sm: "lg", md: "xl" }}>
                <b>Wallet:</b> Refers to the digital wallet used by users to store and transact cryptocurrencies on the Platform.
              </Text>
            </ListItem>

            <ListItem>
              <Text fontWeight="bold" fontSize={{ base: "md", sm: "lg", md: "xl" }}>
                Eligibility
              </Text>
              <Text fontSize={{ base: "md", sm: "lg", md: "xl" }}>
                By accessing or using the Platform, you represent and warrant that you are at least 18 years old and have the legal capacity to enter into these Terms. If you are accessing or using the Platform on behalf of an entity, you further represent and warrant that you have the authority to bind such entity to these Terms.
              </Text>
            </ListItem>

            <ListItem>
              <Text fontWeight="bold" fontSize={{ base: "md", sm: "lg", md: "xl" }}>
                Registration and Accounts
              </Text>
              <Text fontSize={{ base: "md", sm: "lg", md: "xl" }}>
                <b>Account Creation:</b> To access certain features of the Platform, you may be required to register for an account. You agree to provide accurate, current, and complete information during the registration process and to update such information to keep it accurate, current, and complete.
              </Text>
              <Text fontSize={{ base: "md", sm: "lg", md: "xl" }}>
                <b>Account Security:</b> You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account. You agree to notify us immediately of any unauthorized use of your account or any other breach of security.
              </Text>
              <Text fontSize={{ base: "md", sm: "lg", md: "xl" }}>
                <b>Account Termination:</b> We reserve the right to suspend or terminate your account at any time and for any reason without notice.
              </Text>
            </ListItem>

            <ListItem>
              <Text fontWeight="bold" fontSize={{ base: "md", sm: "lg", md: "xl" }}>
                Platform Use
              </Text>
              <Text fontSize={{ base: "md", sm: "lg", md: "xl" }}>
                <b>Decentralized Nature:</b> You acknowledge and agree that the Platform operates on decentralized technology, and transactions made on the Platform are irreversible. We are not responsible for any loss of funds due to user error or technical issues.
              </Text>
              <Text fontSize={{ base: "md", sm: "lg", md: "xl" }}>
                <b>Prohibited Activities:</b> You agree not to engage in any of the following prohibited activities:
                <ul>
                  <li>Violating any applicable laws or regulations.</li>
                  <li>Interfering with the proper functioning of the Platform.</li>
                  <li>Impersonating any person or entity or misrepresenting your affiliation with any person or entity.</li>
                  <li>Uploading, posting, transmitting, or otherwise making available any content that is unlawful, harmful, threatening, abusive, harassing, defamatory, vulgar, obscene, or otherwise objectionable.</li>
                  <li>Engaging in any activity that could damage, disable, overburden, or impair our servers or networks.</li>
                </ul>
              </Text>
              <Text fontSize={{ base: "md", sm: "lg", md: "xl" }}>
                <b>Intellectual Property:</b> All content on the Platform, including but not limited to text, graphics, logos, icons, images, audio clips, digital downloads, data compilations, and software, is the property of Tradersfield or its licensors and is protected by copyright, trademark, and other intellectual property laws.
              </Text>
            </ListItem>

            <ListItem>
              <Text fontWeight="bold" fontSize={{ base: "md", sm: "lg", md: "xl" }}>
                Disclaimer of Warranties
              </Text>
              <Text fontSize={{ base: "md", sm: "lg", md: "xl" }}>
                THE PLATFORM IS PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED. TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, WE DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT.
              </Text>
            </ListItem>

            <ListItem>
              <Text fontWeight="bold" fontSize={{ base: "md", sm: "lg", md: "xl" }}>
                Limitation of Liability
              </Text>
              <Text fontSize={{ base: "md", sm: "lg", md: "xl" }}>
                IN NO EVENT SHALL TRADERSFIELD, ITS OFFICERS, DIRECTORS, EMPLOYEES, AGENTS, OR LICENSORS BE LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES, INCLUDING, BUT NOT LIMITED TO, LOSS OF PROFITS, LOSS OF DATA, LOSS OF USE, OR INTERRUPTION OF BUSINESS, ARISING OUT OF OR IN ANY WAY RELATED TO YOUR USE OR INABILITY TO USE THE PLATFORM, WHETHER BASED ON WARRANTY, CONTRACT, TORT (INCLUDING NEGLIGENCE), OR ANY OTHER LEGAL THEORY, EVEN IF TRADERSFIELD HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
              </Text>
            </ListItem>

            <ListItem>
              <Text fontWeight="bold" fontSize={{ base: "md", sm: "lg", md: "xl" }}>
                Indemnification
              </Text>
              <Text fontSize={{ base: "md", sm: "lg", md: "xl" }}>
                You agree to indemnify, defend, and hold harmless Tradersfield, its officers, directors, employees, agents, and licensors from and against any and all claims, damages, losses, liabilities, costs, and expenses (including reasonable attorneys' fees) arising out of or related to your use of the Platform, your violation of these Terms, or your violation of any rights of another.
              </Text>
            </ListItem>

            <ListItem>
              <Text fontWeight="bold" fontSize={{ base: "md", sm: "lg", md: "xl" }}>
                Governing Law and Dispute Resolution
              </Text>
              <Text fontSize={{ base: "md", sm: "lg", md: "xl" }}>
                These Terms shall be governed by and construed in accordance with the laws of the jurisdiction in which Tradersfield is established, without regard to its conflict of law principles. Any disputes arising out of or related to these Terms or the Platform shall be resolved through binding arbitration in accordance with the rules of the applicable arbitration organization in that jurisdiction.
              </Text>
            </ListItem>

            <ListItem>
              <Text fontWeight="bold" fontSize={{ base: "md", sm: "lg", md: "xl" }}>
                Changes to the Terms
              </Text>
              <Text fontSize={{ base: "md", sm: "lg", md: "xl" }}>
                We reserve the right to modify these Terms at any time. We will notify you of any material changes to these Terms by posting the revised Terms on the Platform or by other means. Your continued use of the Platform following such notice constitutes your acceptance of the revised Terms.
              </Text>
            </ListItem>

            <ListItem>
              <Text fontWeight="bold" fontSize={{ base: "md", sm: "lg", md: "xl" }}>
                Contact Information
              </Text>
              <Text fontSize={{ base: "md", sm: "lg", md: "xl" }}>
                If you have any questions about these Terms, please contact us at support@scrowed.com.
              </Text>
            </ListItem>
          </OrderedList>
        </Box>
      </VStack>
    </Box>
  );
}
