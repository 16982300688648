import { simulateContract, waitForTransactionReceipt, writeContract, getAccount, getChainId } from '@wagmi/core';
import axiosInstance from './axiosInstance'; // Adjust the path accordingly
import BUYTRADEABI from "../contracts/Trade.json";
import SELLTRADEABI from "../contracts/BuyAdvert.json";
import TOKENABI from "../contracts/IBEP20.json";
import NETWORKS from "../contracts/Networks.json";
import { parseEther } from 'viem';
import { config } from 'variables/connector';

const SERVER_URL = process.env.REACT_APP_SERVER_URL;
const TIMEOUT_MS = 90000; // 1 minute timeout

const CreateBuyTrade = async (buyTradeData, token) => {
  try {
    const response = await axiosInstance.post(SERVER_URL + "/trade/buy/create", buyTradeData, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      mode: "cors",
    });
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

const MarkFundedBuyTrade = async (buyTradeData, tradeId, token) => {
  try {
    const response = await axiosInstance.patch(SERVER_URL + "/trade/buy/funded/" + tradeId, buyTradeData, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      mode: "cors",
    });
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

const CreateSellTrade = async (sellTradeData, token) => {
  try {
    const response = await axiosInstance.post(SERVER_URL + "/trade/sell/create", sellTradeData, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      mode: "cors",
    });
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

const CreateBuyTradeContract = async (buyTradeContractData) => {
  try {
    const { connector } = getAccount(config);
    const chainId = getChainId(config);

    const BUY_TRADE_CONTRACT = NETWORKS[chainId]["BUY_TRADE_CONTRACT"];
    const wei = parseEther(String(buyTradeContractData.unit));

    //check transaction for errors
    const { request } = await simulateContract(config, {
      abi: BUYTRADEABI.abi,
      address: BUY_TRADE_CONTRACT,
      functionName: 'createTrade',
      args: [buyTradeContractData.tradeId, buyTradeContractData.advertId, wei],
      chainId: chainId,
      connector
    });

    const hash = await writeContract(config, request);

    //wait for transaction receipt
    const txReceipt = await waitForTransactionReceipt(config, {
      hash: hash,
    })

    return {
      success: true,
      transactionHash: hash
    };

  } catch (error) {
    let errorMessage = "An unexpected error occurred";
    if (error.code) {
      switch (error.code) {
        case 4001:
        case "ACTION_REJECTED":
          errorMessage = "Transaction rejected by user";
          break;
        default:
          errorMessage = error.message || errorMessage;
      }
    } else if (error.error?.data?.message) {
      errorMessage = error.error.data.message;
    } else if (error.message) {
      errorMessage = error.message;
    }
    throw new Error(`Transaction failed with an unknown error: ${errorMessage}`);
  }
};

const CreateSellTradeContract = async (sellTradeContractData) => {
  try {
    const { connector } = getAccount(config);
    const chainId = getChainId(config);

    const ASSET_CONTRACT = NETWORKS[chainId][sellTradeContractData.asset];
    const SELL_TRADE_CONTRACT = NETWORKS[chainId]["SELL_TRADE_CONTRACT"];
    const wei = parseEther(String(sellTradeContractData.unit));

    const approvalTx = await writeContract(config, {
      abi: TOKENABI.abi,
      address: ASSET_CONTRACT,
      functionName: 'approve',
      args: [SELL_TRADE_CONTRACT, wei],
      chainId: chainId,
      connector
    });

    // Wait for the approval transaction to be mined
    const approvalReceipt = await waitForTransactionReceipt(config, {
      hash: approvalTx,
    });

    //check transaction for errors
    const { request } = await simulateContract(config, {
      abi: SELLTRADEABI.abi,
      address: SELL_TRADE_CONTRACT,
      functionName: 'createSellTrade',
      args: [sellTradeContractData.tradeId, sellTradeContractData.advertId, wei],
      chainId: chainId,
      connector
    });

    //commit transaction
    const hash = await writeContract(config, request);

    //wait for transaction receipt
    const txReceipt = await waitForTransactionReceipt(config, {
      hash: hash,
    })

    return {
      success: true,
      transactionHash: hash
    };

  } catch (error) {
    let errorMessage = "An unexpected error occurred";

    if (error.code) {
      switch (error.code) {
        case 4001:
        case "ACTION_REJECTED":
          errorMessage = "Transaction rejected by user";
          break;
        default:
          errorMessage = error.message || errorMessage;
      }
    } else if (error.error?.data?.message) {
      errorMessage = error.error.data.message;
    } else if (error.message) {
      errorMessage = error.message;
    }

    throw new Error(`Transaction failed: ${errorMessage}`);
  }
};

const CancelSellTradeContract = async (tradeId) => {
  try {
    const { connector } = getAccount(config);
    const chainId = getChainId(config);

    const SELL_TRADE_CONTRACT = NETWORKS[chainId]["SELL_TRADE_CONTRACT"];

    //check transaction for errors
    const { request } = await simulateContract(config, {
      abi: SELLTRADEABI.abi,
      address: SELL_TRADE_CONTRACT,
      functionName: 'closeTrade',
      args: [tradeId],
      chainId: chainId,
      connector
    });

    const hash = await writeContract(config, request);

    //wait for transaction receipt
    const txReceipt = await waitForTransactionReceipt(config, {
      hash: hash,
    })

    return {
      success: true,
      transactionHash: hash
    };

  } catch (error) {
    let errorMessage = "An unexpected error occurred";

    if (error.code) {
      switch (error.code) {
        case 4001:
        case "ACTION_REJECTED":
          errorMessage = "Transaction rejected by user";
          break;
        default:
          errorMessage = error.message || errorMessage;
      }
    } else if (error.error?.data?.message) {
      errorMessage = error.error.data.message;
    } else if (error.message) {
      errorMessage = error.message;
    }
    throw new Error(`Transaction failed: ${errorMessage}`);
  }
};

const CancelBuyTradeContract = async (tradeId) => {
  try {
    const { connector } = getAccount(config);
    const chainId = getChainId(config);

    const BUY_TRADE_CONTRACT = NETWORKS[chainId]["BUY_TRADE_CONTRACT"];

    //check transaction for errors
    const { request } = await simulateContract(config, {
      abi: BUYTRADEABI.abi,
      address: BUY_TRADE_CONTRACT,
      functionName: 'closeTrade',
      args: [tradeId],
      chainId: chainId,
      connector
    });

    const hash = await writeContract(config, request);

    //wait for transaction receipt
    const txReceipt = await waitForTransactionReceipt(config, {
      hash: hash,
    })

    return {
      success: true,
      transactionHash: hash
    };

  } catch (error) {
    let errorMessage = "An unexpected error occurred";

    if (error.code) {
      switch (error.code) {
        case 4001:
        case "ACTION_REJECTED":
          errorMessage = "Transaction rejected by user";
          break;
        default:
          errorMessage = error.message || errorMessage;
      }
    } else if (error.error?.data?.message) {
      errorMessage = error.error.data.message;
    } else if (error.message) {
      errorMessage = error.message;
    }

    throw new Error(`Transaction failed: ${errorMessage}`);
  }
};

const TransferAssetSellTradeContract = async (tradeId) => {
  try {
    const { connector } = getAccount(config);
    const chainId = getChainId(config);

    const SELL_TRADE_CONTRACT = NETWORKS[chainId]["SELL_TRADE_CONTRACT"];

    //check transaction for errors
    const { request } = await simulateContract(config, {
      abi: SELLTRADEABI.abi,
      address: SELL_TRADE_CONTRACT,
      functionName: 'transferAsset',
      args: [tradeId],
      chainId: chainId,
      connector
    });

    const hash = await writeContract(config, request);

    //wait for transaction receipt
    const txReceipt = await waitForTransactionReceipt(config, {
      hash: hash,
    })

    return {
      success: true,
      transactionHash: hash
    };

  } catch (error) {
    let errorMessage = "An unexpected error occurred";
    if (error.code) {
      switch (error.code) {
        case 4001:
        case "ACTION_REJECTED":
          errorMessage = "Transaction rejected by user";
          break;
        default:
          errorMessage = error.message || errorMessage;
      }
    } else if (error.error?.data?.message) {
      errorMessage = error.error.data.message;
    } else if (error.message) {
      errorMessage = error.message;
    }

    throw new Error(`Transaction failed: ${errorMessage}`);
  }
};

const TransferAssetBuyTradeContract = async (tradeId) => {
  try {
    const { connector } = getAccount(config);
    const chainId = getChainId(config);

    const BUY_TRADE_CONTRACT = NETWORKS[chainId]["BUY_TRADE_CONTRACT"];

    //check transaction for errors
    const { request } = await simulateContract(config, {
      abi: BUYTRADEABI.abi,
      address: BUY_TRADE_CONTRACT,
      functionName: 'transferAsset',
      args: [tradeId],
      chainId: chainId,
      connector
    });

    const hash = await writeContract(config, request);

    //wait for transaction receipt
    const txReceipt = await waitForTransactionReceipt(config, {
      hash: hash,
    })

    return {
      success: true,
      transactionHash: hash
    };

  } catch (error) {
    let errorMessage = "An unexpected error occurred";

    if (error.code) {
      switch (error.code) {
        case 4001:
        case "ACTION_REJECTED":
          errorMessage = "Transaction rejected by user";
          break;
        default:
          errorMessage = error.message || errorMessage;
      }
    } else if (error.error?.data?.message) {
      errorMessage = error.error.data.message;
    } else if (error.message) {
      errorMessage = error.message;
    }

    throw new Error(`Transaction failed: ${errorMessage}`);
  }
};

const TransferFiatBuyTrade = async (tradeId, buyTradeData, token) => {
  try {
    const response = await axiosInstance.patch(SERVER_URL + "/trade/buy/paid/" + tradeId, buyTradeData, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      mode: "cors",
    });
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

const DisputeTrade = async (disputeData, token) => {
  try {
    const response = await axiosInstance.post(SERVER_URL + "/dispute/create", disputeData, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      mode: "cors",
    });
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

const TransferFiatSellTrade = async (tradeId, sellTradeData, token) => {
  try {
    const response = await axiosInstance.patch(SERVER_URL + "/trade/sell/paid/" + tradeId, sellTradeData, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      mode: "cors",
    });
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

const GetActiveTrades = async (token) => {
  try {
    const buyTrades = await axiosInstance.get(
      SERVER_URL + "/trade/user/activebuy",
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        mode: "cors",
      }
    );
    const sellTrades = await axiosInstance.get(
      SERVER_URL + "/trade/user/activesell",
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        mode: "cors",
      }
    );
    const trades = buyTrades.data.data.concat(sellTrades.data.data);
    return {
      success: true,
      data: trades
    }
  } catch (error) {
    throw new Error(error.message);
  }
};

const GetUserSellTrades = async (pagination, token) => {
  try {
    const response = await axiosInstance.get(
      SERVER_URL + "/trade/user/sells?from=" +
      pagination.from +
      "&limit=" +
      pagination.limit +
      "&orderBy=" +
      pagination.orderBy +
      "&sortOrder=" +
      pagination.sortOrder,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        mode: "cors",
      }
    );
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

const GetUserBuyTrades = async (pagination, token) => {
  try {
    const response = await axiosInstance.get(
      SERVER_URL + "/trade/user/buys?from=" +
      pagination.from +
      "&limit=" +
      pagination.limit +
      "&orderBy=" +
      pagination.orderBy +
      "&sortOrder=" +
      pagination.sortOrder,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        mode: "cors",
      }
    );
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

const GetSellTrades = async (filter, pagination, token) => {
  try {
    const response = await axiosInstance.post(
      SERVER_URL + "/trade/sells/all?from=" +
      pagination.from +
      "&limit=" +
      pagination.limit +
      "&orderBy=" +
      pagination.orderBy +
      "&sortOrder=" +
      pagination.sortOrder, filter,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        mode: "cors",
      }
    );
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};


const GetBuyTrades = async (filter, pagination, token) => {
  try {
    const response = await axiosInstance.post(
      SERVER_URL + "/trade/buys/all?from=" +
      pagination.from +
      "&limit=" +
      pagination.limit +
      "&orderBy=" +
      pagination.orderBy +
      "&sortOrder=" +
      pagination.sortOrder, filter,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        mode: "cors",
      }
    );
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

const GetBuyTrade = async (tradeId, token) => {
  try {
    const response = await axiosInstance.get(
      SERVER_URL + "/trade/buy/" + tradeId,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        mode: "cors",
      }
    );
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};


const GetSellTrade = async (tradeId, token) => {
  try {
    const response = await axiosInstance.get(
      SERVER_URL + "/trade/sell/" + tradeId,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        mode: "cors",
      }
    );
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

const ReviewTrade = async (reviewData, tradeId, token) => {
  try {
    const response = await axiosInstance.post(SERVER_URL + "/rating/" + tradeId, reviewData, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      mode: "cors",
    });
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

const GetReview = async (tradeId, token) => {
  try {
    const response = await axiosInstance.get(
      SERVER_URL + "/rating/trade/" + tradeId,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        mode: "cors",
      }
    );
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

export {
  CreateBuyTrade,
  CreateBuyTradeContract,
  CreateSellTradeContract,
  CancelSellTradeContract,
  CancelBuyTradeContract,
  TransferAssetSellTradeContract,
  TransferAssetBuyTradeContract,
  TransferFiatBuyTrade,
  TransferFiatSellTrade,
  CreateSellTrade,
  DisputeTrade,
  GetUserBuyTrades,
  GetUserSellTrades,
  GetActiveTrades,
  GetBuyTrade,
  GetSellTrade,
  GetBuyTrades,
  GetSellTrades,
  ReviewTrade,
  GetReview,
  MarkFundedBuyTrade
};

