import React from 'react';
import {
  Box,
  Center,
  HStack,
  Heading,
  Button,
  Text,
  Icon,
  Textarea
} from "@chakra-ui/react";
import moment from 'moment';
import Card from "components/card/Card";
import { useSelector } from "react-redux";
import { FaStar } from "react-icons/fa";

const Rating = ({ trade, review, setReview, hover, setHover, reviewLoading, handleSubmitReview }) => {
  const userState = useSelector((state) => state?.user?.value);
  const userId = userState.hasOwnProperty("userId") ? userState.userId : null;
  return (
    <>
     <Center mt="2">
      <Box width={{ sm: "100%", md: "70%", lg: "50%" }}>
        <Heading size="md" color="white">Your review for {trade.seller._id === userId ? trade.buyer.username : trade.seller.username} </Heading>
      </Box>
    </Center>
    <Center mt="5">
      <Card background="transparent" borderColor="brand.500" borderWidth="1px" width={{ sm: "100%", md: "70%", lg: "50%" }} minH="200">
       <Text mt="5" color="white" fontSize={{ sm: "md", md: "lg" }} textAlign={"center"}>How satisfied are you with this trade?</Text>
       <Center>
        <HStack spacing={2} mt="4">
          {[1, 2, 3, 4, 5].map((star) => (
            <Icon
              as={FaStar}
              key={star}
              boxSize={8}
              cursor="pointer"
              color={star <= (hover || review.rating) ? "yellow.400" : "gray.300"}
              onClick={() => {
                setReview(prevReview => ({
                  ...prevReview,
                  rating: star
                }));
              }}
              onMouseEnter={() => setHover(star)}
              onMouseLeave={() => setHover(0)}
            />
          ))}
        </HStack>
        </Center>
      </Card>
    </Center>

    <Center mt="5">
      <Card background="transparent" borderColor="brand.500" borderWidth="1px" width={{ sm: "100%", md: "70%", lg: "50%" }} minH="100">
       <Textarea
        color="white"
        borderWidth={"1px"}
        borderColor="white"
        value={review.comment}
        onChange={(e)=>{
          setReview(prevReview => ({
            ...prevReview,
            comment: e.target.value
          }));
        }}
        placeholder='Write a comment about this trade'
        fontSize={{ sm: "md", md: "lg" }}
       />
       <Button colorScheme='brand' variant='outline' color="white" mt="2" onClick={handleSubmitReview} isLoading={reviewLoading} isLoadingText="Wait..">Comment</Button>
       </Card>
     </Center>
    </>
  );
};


export default Rating;
