import axiosInstance from "./axiosInstance"; // Adjust the path accordingly

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

const GetRole = async (roleId, token) => {
  try {
    const response = await axiosInstance.get(SERVER_URL + "/role/" + roleId, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      mode: "cors",
    });
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

const GetRoles = async (token) => {
  try {
    const response = await axiosInstance.get(
      SERVER_URL + "/role",
      {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        mode: "cors",
      }
    );
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};
const DeleteRole = async (roleId, token) => {
  try {
    const response = await axiosInstance.delete(
      SERVER_URL + "/role/" + roleId,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        mode: "cors",
      }
    );
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

const CreateRole = async (role, token) => {
  try {
    const response = await axiosInstance.post(SERVER_URL + "/role", role, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      mode: "cors",
    });
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

const UpdateRole = async (roleData, roleId, token) => {
  try {
    const response = await axiosInstance.patch(
      SERVER_URL + "/role/" + roleId,
      roleData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        mode: "cors",
      }
    );
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

export { GetRole, GetRoles, DeleteRole, CreateRole, UpdateRole };
