import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Icon,
  Heading,
  Button,
  SimpleGrid,
  Skeleton,
  SkeletonText,
  Stack,
  Text,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Select,
  Link,
  useToast,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { FiPlus, FiArrowLeft } from "react-icons/fi";
import { MdDelete, MdOutlineImage, MdEditNote } from "react-icons/md";
import Card from "components/card/Card.js";
import Paginate from "components/navigation/Paginate.js";
import TextFilter from "components/filter/TextFilter";
import {
  SearchUser,
  GetUsers,
  DeleteUser,
  UpdateUser,
} from "services/userService";

export default function Admins() {
  const toast = useToast();
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const userState = useSelector((state) => state?.user?.value);
  const token = userState.hasOwnProperty("token") ? userState.token : null;
  const priviledges = userState.hasOwnProperty("priviledges") ? userState.priviledges : [];
  const [pageCount, setPageCount] = useState(0);
  const [sortOrder, setSortOrder] = useState("desc");
  const [from, setFrom] = useState(0);
  const [limit, setLimit] = useState(200);
  const [orderBy, setOrderBy] = useState("createdAt");

  const [user, setUser] = useState(null);

  const [searchText, setSearchText] = useState(null);
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [userId, setUserId] = useState("");

  const getUsers = async () => {
    try {
      setLoading(true);
      const pagination = {
        from: from,
        limit: limit,
        orderBy: orderBy,
        sortOrder: sortOrder,
      };
      let filter = {isAdmin: false};
      const response = await GetUsers(pagination, filter, token);
      setLoading(false);
      if (response.error) {
        toast({
          title: response.error || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (!response.success) {
        toast({
          title: response.message || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (response.success) {
        setPageCount(response.data.count / limit);
        setUsers(response?.data?.users);
        setLoading(false);
        return;
      }
    } catch (error) {
      setLoading(false);
      toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
    }
  };

  const initFilterSubmit = async () => {
    try {
      setLoading(true);
      const searchData = {
        searchText,
        isAdmin: false
      };
      /*if (!priviledges.includes("Manage User")) {
        toast({
          title: "You are not allowed",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }*/
      const response = await SearchUser(searchData, token);
      setLoading(false);
      if (response.error) {
        toast({
          title: response.error || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (!response.success) {
        toast({
          title: response.message || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (response.success) {
        setLoading(false);
        setUsers(response.data);
        setPageCount(1);
        return;
      }
    } catch (error) {
      setLoading(false);
      toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
    }
  };

  const deleteUser = async () => {
    try {
      setLoading(true);
      if (!priviledges.includes("Manage User")) {
        toast({
          title: "You are not allowed",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      const response = await DeleteUser(userId, token);
      setLoading(false);
      if (response.error) {
        toast({
          title: response.error || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        setLoading(false);
        return;
      }
      if (!response.success) {
        toast({
          title: response.message || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        setLoading(false);
        return;
      }
      if (response.success) {
        toast({
          title: response.message || "User deleted successfully",
          status: "success",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        getUsers();
        onClose();
      }
    } catch (error) {
      toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
      setLoading(false);
    }
  };

  useEffect(() => {
    getUsers();
  }, []);

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} size={"md"}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Icon
              as={FiArrowLeft}
              onClick={onClose}
              width="20px"
              height="20px"
            />
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <SimpleGrid>
              <Box textAlign="center">
                <Heading
                  fontSize={{ base: "md", sm: "lg", md: "lg", lg: "xl" }}
                >
                  Are you sure you want to delete this?
                </Heading>
              </Box>
            </SimpleGrid>
          </ModalBody>

          <ModalFooter>
            <Button
              variant="primary"
              background="brand.500"
              isLoading={loading}
              loadingText="Please Wait"
              onClick={(e) => {
                e.preventDefault();
                deleteUser();
              }}
            >
              Proceed
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Box pt={{ base: "30px", md: "40px", xl: "40px" }} width="100%" mb="70" mt="20">
        <SimpleGrid columns={{ sm: 1, md: 2 }} w="100%" spacing="2">
          <Box>
            {" "}
            <Text
              color={"brand.500"}
              fontSize="18px"
              mb="4px"
              fontWeight="600"
              lineHeight="100%"
            >
              Members
            </Text>
          </Box>
          <TextFilter
            searchText={searchText}
            setSearchText={setSearchText}
            searchTextLabel="Email/Address/Last Name"
            initFilterSubmit={initFilterSubmit}
          />
        </SimpleGrid>

        {loading ? (
          <LoadingUsers />
        ) : (
          <TableContainer>
            <Table variant="simple" fontSize={{ sm: "md", md: "lg" }}>
              <Thead>
                <Tr>
                  <Th>Last Name</Th>
                  <Th>First Name</Th>
                  <Th>Address</Th>
                  <Th>Email</Th>
                  <Th>Action</Th>
                </Tr>
              </Thead>
              <Tbody>
                {users !== null &&
                  users.map((user) => (
                    <Tr>
                      <Td>{user.lastName}</Td>
                      <Td>{user.firstName}</Td>
                      <Td>{user.address}</Td>
                      <Td>{user.email}</Td>
                      <Td>
                        {" "}
                        <Menu>
                          <MenuButton
                            as={Button}
                            variant="primary"
                            background="brand.500"
                            rightIcon={<ChevronDownIcon />}
                          >
                            Action
                          </MenuButton>
                          <MenuList>
                            <MenuItem
                              onClick={() => {
                                navigate("/admin/users/adduser/" + user._id, {
                                  state: { userId: user._id },
                                });
                              }}
                            >
                              Edit &nbsp;{" "}
                              <Icon
                                as={MdEditNote}
                                width="20px"
                                height="20px"
                                color="brand.500"
                              />{" "}
                            </MenuItem>
                            <MenuItem
                              onClick={() => {
                                setUserId(user._id);
                                setUser(user);
                                onOpen();
                              }}
                            >
                              Delete &nbsp;{" "}
                              <Icon
                                as={MdDelete}
                                width="20px"
                                height="20px"
                                color="brand.500"
                              />{" "}
                            </MenuItem>
                          </MenuList>
                        </Menu>
                      </Td>
                    </Tr>
                  ))}
              </Tbody>
            </Table>
          </TableContainer>
        )}
        {pageCount > 0 && (
          <Box width="100%" mt="5" background="brand.500" p="5" borderRadius="5">
            <Paginate
              pageCount={pageCount}
              setFrom={setFrom}
              getRecords={getUsers}
            />
          </Box>
        )}
      </Box>
    </>
  );
}

const LoadingUsers = () => {
  return (
    <>
      <Card align="center" p="5px">
        <Stack>
          <Skeleton height="5px" />
          <SkeletonText mt="2" noOfLines={3} spacing="3" />
          <Skeleton height="5px" />
        </Stack>
      </Card>

      <Card align="center" p="5px" mt="5">
        <Stack>
          <Skeleton height="5px" />
          <SkeletonText mt="2" noOfLines={3} spacing="3" />
          <Skeleton height="5px" />
        </Stack>
      </Card>
    </>
  );
};
