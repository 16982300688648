import React, { useState, useEffect } from 'react';
import {
  Box,
  Image,
  VStack,
  Center,
  Flex,
  HStack,
  Heading,
  Input,
  Button,
  Stack,
  Text,
  Icon
} from "@chakra-ui/react";
import moment from 'moment';
import Card from "components/card/Card";
import { FaArrowLeft, FaFileUpload } from "react-icons/fa";

const Chat = ({ currentUser, recipientUser, messages, message, setMessage, setView, handleSendMessage, onOpen, updateReadStatus }) => {
  const formatTime = (timestamp) => moment(timestamp).format('HH:mm');
  const formatDate = (timestamp) => moment(timestamp).format('YYYY-MM-DD');
  let lastDate = null;

  useEffect(() => {
    //update messages read status
    updateReadStatus();
  }, []);

  return (
    <>
     <Center mt="2">
      <Box width={{ sm: "100%", md: "70%", lg: "50%" }}>
        <Flex onClick={()=>{setView("trade")}}><Icon as={FaArrowLeft} width="20px" height="20px" color="white" mt="1" />&nbsp;&nbsp;<Text color="white">Back to Trade</Text></Flex>
      </Box>
    </Center>
    <Center mt="5">
      <Card background="transparent" borderColor="brand.500" borderWidth="1px" width={{ sm: "100%", md: "70%", lg: "50%" }} minH="300">
        <Flex h="100vh" py={12}>
          <Flex
            flexDirection="column"
            w="100%"
            h="full"
            roundedTop="lg"
          >
            <Stack
              px={4}
              py={8}
              overflow="auto"
              flex={1}
              css={{
                '&::-webkit-scrollbar': {
                  width: '4px',
                },
                '&::-webkit-scrollbar-track': {
                  width: '6px',
                },
                '&::-webkit-scrollbar-thumb': {
                  background: '#d5e3f7',
                  borderRadius: '24px',
                },
              }}
            >

               { messages !== null && messages.map((message, index) => {
                  const messageDate = message.createdAt!==undefined? formatDate(message?.createdAt): formatDate(new Date().toISOString());
                  const showDate = messageDate !== lastDate;
                  lastDate = messageDate;

                  return (
                    <>
                    {showDate && (
                      <Box width="100%" textAlign={"center"}>
                      <Text color="white">{messageDate}</Text>
                      </Box>
                    )}
                      <Flex
                      flexDirection={"column"}
                      key={index}  
                      p={4}
                      bg={currentUser._id === message.senderId ? 'brand.500' : 'gray.100'}
                      color={currentUser._id === message.senderId ? 'white' : 'gray.600'}
                      borderRadius="lg"
                      w="fit-content"
                      alignSelf={currentUser._id === message.senderId ? 'flex-end' : 'flex-start'}
                    >
                       {message.upload!==undefined && message.upload? <Image src={message.message} width="100%" height="auto" />: <Text>{message.message}</Text> }
                       
                    </Flex>

                    <Box width="100%">
                      <Text textAlign={currentUser._id === message.senderId ? 'right' : 'left'} color="white" fontSize={{ sm: "xs", md: "sm" }}>{formatTime(message.createdAt)}</Text>
                    </Box>
                    </>
                  );
                })}

            </Stack>

            <HStack p={4}>
              <Input bg="white" placeholder="Type your message..." value={message} onChange={e => setMessage(e.target.value)} />
              <Icon as={FaFileUpload} width="30px" height="30px" color="white" mt="1" onClick={onOpen}/>
              <Button colorScheme="brand" onClick={handleSendMessage}>Send</Button>
            </HStack>

          </Flex>
        </Flex>
      </Card>
    </Center>
    </>
  );
};


export default Chat;
