
export const connections = [
   {
    chainId: 84532,
    chainName: 'Base Sepholia Testnet'
  },
  {
    chainId: 97,
    chainName: 'Binance Smart Chain Testnet'
  }
]