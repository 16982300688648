import React, { useEffect, useState } from "react";
import {
  Box,
  Heading,
  Button,
  SimpleGrid,
  Input,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Tag,
  Flex,
  Image,
  useToast,
  useDisclosure
} from "@chakra-ui/react";
import { useParams, Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useAccount, useChainId } from 'wagmi';
import { base } from 'viem/chains';
import { OnchainKitProvider } from '@coinbase/onchainkit';
import { getName } from '@coinbase/onchainkit/identity';
//import { useEnsName } from 'wagmi';
import { UpdateUser, ResendEmailVerification, GetUser, VerifyEmail, InitiateKYC } from "services/userService";
import { FaAngleRight } from "react-icons/fa";
import BaseLogo from "assets/images/baseimg.webp";
import EnsLogo from "assets/images/ens-logo.svg";

export default function Index() {
  const socket = new WebSocket(process.env.REACT_APP_SOCKET_URL);
  const { isConnected, address } = useAccount();
  const chainId = useChainId();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isEnsOpen, onOpen: onEnsOpen, onClose: onEnsClose } = useDisclosure();
  /*const { data: ensName } = useEnsName({
    address: address,
  });*/

  const toast = useToast();
  const userState = useSelector((state) => state?.user?.value);
  const token = userState.hasOwnProperty("token") ? userState.token : null;

  const [user, setUser] = useState(null);
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [username, setUsername] = useState("");
  const [loading, setLoading] = useState(false);
  const [nameUpdateLoading, setNameUpdateLoading] = useState(false);
  const [emailUpdateLoading, setEmailUpdateLoading] = useState(false);
  const [usernameUpdateLoading, setUsernameUpdateLoading] = useState(false);
  const [kycLoading, setKYCLoading] = useState(false);
  const [basenameLoading, setBasenameLoading] = useState(false);
  const [ensNameLoading, setEnsNameLoading] = useState(false);

  const [remainingTime, setRemainingTime] = useState(0); // 60 seconds = 1 minute
  const [showResendLink, setShowResendLink] = useState(true);

  const { code } = useParams();

  const handleGetBaseName = async () => {
    try{
    if (address) {
      setBasenameLoading(true);
      const name = await getName({ address, chain: base });
      setBasenameLoading(false);
      if (name) {
        setUsername(name);
      }
      else {
        onOpen();
      }
    }
   }
   catch(e){
    toast({
      title: "We could not get you basename at the moment",
      status: "error",
      duration: 9000,
      position: "top-right",
      isClosable: true,
    });
   }
  }

  /*const handleGetENSName = async () => {
    if (address) {
      setEnsNameLoading(true);
      const name = ensName;
      setEnsNameLoading(false);
      if (name) {
        setUsername(name);
      }
      else {
        onEnsOpen();
      }
    }
  }*/

  const handleInitiateKYC = async () => {
    try {
      setKYCLoading(true);
      const userData = { email, firstName, lastName };
      const response = await InitiateKYC(userData, token);
      setKYCLoading(false);
      if (!response) {
        toast({
          title: response.error || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (response.error) {
        toast({
          title: response.message || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (response.success === true) {
        //redirect user
        window.location.href = response.data.verificationUrl;
        getUser();
      }
    } catch (error) {
      setKYCLoading(false);
      toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
    }
  };

  const handleUpdateEmail = async () => {
    try {
      if (!isConnected) {
        toast({
          title: "Please connect wallet",
          status: "info",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (email === "") {
        toast({
          title: "Email address cannot be empty",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      setEmailUpdateLoading(true);
      const userData = { email };
      const response = await UpdateUser(userData, token);
      setEmailUpdateLoading(false);
      if (!response) {
        toast({
          title: response.error || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (response.error) {
        toast({
          title: response.message || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (response.success === true) {
        toast({
          title: response.message || "Your email have been updated successfully",
          status: "success",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        setShowResendLink(false);
        setRemainingTime(90);
        getUser();
      }
    } catch (error) {
      setEmailUpdateLoading(false);
      toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
    }
  };

  const handleUpdateUsername = async () => {
    try {
      if (!isConnected) {
        toast({
          title: "Please connect wallet",
          status: "info",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (username === "") {
        toast({
          title: "Username cannot be empty",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      setUsernameUpdateLoading(true);
      const userData = { username };
      const response = await UpdateUser(userData, token);
      setUsernameUpdateLoading(false);
      if (!response) {
        toast({
          title: response.error || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (response.error) {
        toast({
          title: response.message || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (response.success === true) {
        toast({
          title: response.message || "Your username have been updated successfully",
          status: "success",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        getUser();
      }
    } catch (error) {
      setUsernameUpdateLoading(false);
      toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      })
    }
  };


  const handleUpdateName = async () => {
    try {
      if (!isConnected) {
        toast({
          title: "Please connect wallet",
          status: "info",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (firstName === "") {
        toast({
          title: "First name cannot be empty",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (lastName === "") {
        toast({
          title: "Last name cannot be empty",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      setNameUpdateLoading(true);
      const userData = { firstName, lastName };
      const response = await UpdateUser(userData, token);
      setNameUpdateLoading(false);
      if (!response) {
        toast({
          title: response.error || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (response.error) {
        toast({
          title: response.message || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (response.success === true) {
        toast({
          title: response.message || "Your name have been updated successfully",
          status: "success",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        getUser();
      }
    } catch (error) {
      setNameUpdateLoading(false);
      toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      })
    }
  };

  const handleResendEmailVerification = async () => {
    try {
      setLoading(true);
      const response = await ResendEmailVerification(token);
      setLoading(false);
      if (response.error) {
        toast({
          title: response.error || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (!response.success) {
        toast({
          title: response.message || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (response.success) {
        toast({
          title: response.message,
          status: "success",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        setShowResendLink(false);
        setRemainingTime(90);
        getUser();
        return;
      }
    } catch (error) {
      setLoading(false);
      toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
    }
  };

  const getUser = async () => {
    try {
      setLoading(true);
      const response = await GetUser(token);
      setLoading(false);
      if (!response) {
        toast({
          title: response.error || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (response.error) {
        toast({
          title: response.message || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (response.success === true) {
        setUser(response.data);
        setEmail(response.data.email);
        setUsername(response.data.username);
      }
    } catch (error) {
      setUser(null);
      setLoading(false);
      toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
    }
  };

  const handleVerifyEmail = async () => {
    try {
      setLoading(true);
      const response = await VerifyEmail({ longOtpCode: code }, token);
      setLoading(false);
      if (response.error) {
        toast({
          title: response.error || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (!response.success) {
        toast({
          title: response.message || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
      }
      if (response.success) {
        toast({
          title: response.message,
          status: "success",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        getUser();
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    if (token !== null) {
      getUser();
    } else {
      setUser(null);
    }
    if (code !== null && code !== undefined) {
      handleVerifyEmail();
    }
  }, [token]);


  socket.onmessage = (event) => {
    const message = JSON.parse(event.data);
    if (message.type === 'STAKING_SUCCESS') {
      console.log('Staking successful:', message.payload);
      // Handle successful staking notification
    }
  };
  socket.onclose = () => {
    console.log('WebSocket connection closed');
  };


  useEffect(() => {
    let timerId = null;
    if (remainingTime > 0) {
      timerId = setInterval(() => {
        setRemainingTime((prevTime) => prevTime - 1);
      }, 1000);
    } else {
      setShowResendLink(true);
    }
    return () => {
      clearInterval(timerId);
    };
  }, [remainingTime]);

  return (
    <>
      <OnchainKitProvider apiKey="ogGKQAZrFJUAuouSrWxIV02uDlzufNJG" chain={base}>
        <Modal isOpen={isOpen} onClose={onClose} size={'sm'} background="#752dcc">
          <ModalOverlay />
          <ModalContent background="#752dcc">
            <ModalHeader color="white"><Flex gap="2"> <Image src={BaseLogo} width="35" height="35" /><Heading mt="0.5" fontFamily="ManropeSemiBold" fontSize={{ sm: "xl", md: "2xl" }} color="white">Basename</Heading></Flex></ModalHeader>
            <ModalCloseButton color="white" />
            <ModalBody minH="180">
              <Box mt="3">
                <Text fontSize={{ sm: "md", md: "lg" }} color="white" fontFamily="ManropeRegular">You don't have a Basename yet</Text>
                <Heading fontSize={{ sm: "md", md: "lg" }} color="white" fontFamily="ManropeRegular" mt="2">Take the vibe a step ahead, secure your Basename, Basename gives you an onchain identity tied to your address</Heading>
              </Box>
              <Box mt="5" mb="10" textAlign="left">
                <a href="https://www.base.org/names" target="_blank">
                  <Button
                    variant={"outline"}
                    color="#ffffff"
                    _hover={{ color: "#000000", background: "#ffffff" }}
                    size="sm"
                  >
                    Get a Basename
                  </Button>
                </a>
              </Box>

            </ModalBody>
          </ModalContent>
        </Modal>

        <Modal isOpen={isEnsOpen} onClose={onEnsClose} size={'sm'} background="#752dcc">
          <ModalOverlay />
          <ModalContent background="#752dcc">
            <ModalHeader color="white"><Flex gap="2"> <Image src={EnsLogo} width="35" height="35" /><Heading mt="0.5" fontFamily="ManropeSemiBold" fontSize={{ sm: "xl", md: "2xl" }} color="white">Basename</Heading></Flex></ModalHeader>
            <ModalCloseButton color="white" />
            <ModalBody minH="180">
              <Box mt="3">
                <Text fontSize={{ sm: "md", md: "lg" }} color="white" fontFamily="ManropeRegular">You don't have an ENS name yet</Text>
                <Heading fontSize={{ sm: "md", md: "lg" }} color="white" fontFamily="ManropeRegular" mt="2">Take the vibe a step ahead, secure your ENS name, ENS name gives you an onchain identity tied to your address</Heading>
              </Box>
              <Box mt="5" mb="10" textAlign="left">
                <a href="https://ens.domains/" target="_blank">
                  <Button
                    variant={"outline"}
                    color="#ffffff"
                    _hover={{ color: "#000000", background: "#ffffff" }}
                    size="sm"
                  >
                    Get ENS name
                  </Button>
                </a>
              </Box>

            </ModalBody>
          </ModalContent>
        </Modal>

        <Box minHeight="700" pt={{ base: "30px", md: "40px", xl: "40px" }} width="100%" pb="20px" p="3">
          <Box width="100%" mt="5" mb="5">
            <Heading color="white" size="md">
              Profile
            </Heading>
          </Box>

          <SimpleGrid
            width="100%"
            columns={{ base: 1, sm: 1, md: 4, lg: 4 }}
            spacing={2}
            background="brandScheme.500"
            p="5"
            borderRadius={'5'} borderLeftWidth="2px" borderLeftColor="secondary.500"
          >
            <Box height="30">
              <Text color="white" fontSize={{ base: "sm", sm: "sm", md: "md", lg: "lg" }}>
                Username
              </Text>
            </Box>
            <Box>
              <Text color="gray.300" fontSize={{ base: "sm", sm: "sm", md: "md", lg: "md" }}>
                Choose a username
              </Text>
            </Box>
            <Box>
              <Input
                color="white"
                placeholder="Username"
                value={username}
                readOnly={user && user.username}
                onChange={(e) => {
                  setUsername(e.target.value);
                }}
              />
              {!user || !user.username &&
                <Flex gap="2">
                  <Button
                    mt="2"
                    colorScheme="secondary"
                    size="xs"
                    isLoading={basenameLoading}
                    onClick={handleGetBaseName}
                  >
                    Use Basename
                  </Button>
                  {/*
                  <Button
                    mt="2"
                    colorScheme="secondary"
                    size="xs"
                    isLoading={ensNameLoading}
                    onClick={handleGetENSName}
                  >
                    Use Basename
                  </Button>*/}
                </Flex>
              }
            </Box>
            <Box textAlign={{ sm: "right", md: "center" }} height="30">
              <Button
                isLoading={usernameUpdateLoading}
                disabled={user && user.username}
                loadingText="Wait..."
                onClick={handleUpdateUsername}
                variant={"outline"}
                color="#ffffff"
                _hover={{ color: "#000000", background: "#ffffff" }}
                size="sm">
                Update
              </Button>
            </Box>
          </SimpleGrid>

          <SimpleGrid
            width="100%"
            columns={{ base: 1, sm: 1, md: 4, lg: 4 }}
            spacing={2}
            background="brandScheme.500"
            p="5"
            mt="5"
            borderRadius={'5'} borderLeftWidth="2px" borderLeftColor="secondary.500"
          >
            <Box height="30">
              <Text color="white" fontSize={{ base: "sm", sm: "sm", md: "md", lg: "lg" }}>
                Full Name
              </Text>
            </Box>
            <Box>
              <Input
                color="white"
                placeholder="First Name"
                defaultValue={user ? user.firstName : ""}
                readOnly={user && (user.kycStatus === "pending" || user.kycStatus === "verified")}
                onChange={(e) => {
                  setFirstName(e.target.value);
                }}
              />

              {user && user.firstName && !user.kycVerified && user.kycStatus === "invalid" && showResendLink && (
                <Button
                  mt="2"
                  colorScheme="secondary"
                  size="xs"
                  onClick={handleInitiateKYC}
                  isLoading={kycLoading}
                  isLoadingText="Wait.."
                >
                  Verify Now
                </Button>
              )}

              {user && user.firstName && !user.kycVerified && user.kycStatus === "pending" && (
                <Tag fontSize="xs" colorScheme="orange" color="white" mt="1">
                  Waiting Approval
                </Tag>
              )}

              {user && user.firstName && !user.kycVerified && user.kycStatus === "failed" && (
                <>
                  <Text fontSize="xs" mt="1">
                    {user.kycComment}
                  </Text>
                  <Text><a href=""><u>Verify Manually</u></a></Text>
                </>
              )}
            </Box>
            <Box>
              <Input
                color="white"
                placeholder="Last Name"
                defaultValue={user ? user.lastName : ""}
                readOnly={user && (user.kycStatus === "pending" || user.kycStatus === "verified")}
                onChange={(e) => {
                  setLastName(e.target.value);
                }}
              />
            </Box>
            <Box textAlign={{ sm: "right", md: "center" }} height="30">
              <Button
                isLoading={nameUpdateLoading}
                disabled={user && (user.kycStatus === "pending" || user.kycStatus === "verified")}
                loadingText="Wait..."
                color="#ffffff"
                _hover={{ color: "#000000", background: "#ffffff" }}
                onClick={handleUpdateName}
                variant={"outline"}
                size="sm"
              >
                Update
              </Button>
            </Box>
          </SimpleGrid>


          <SimpleGrid
            width="100%"
            columns={{ base: 1, sm: 1, md: 4, lg: 4 }}
            spacing={2}
            background="brandScheme.500"
            p="5"
            mt="5"
            borderRadius={'5'} borderLeftWidth="2px" borderLeftColor="secondary.500"
          >
            <Box height="30">
              <Text color="white" fontSize={{ base: "sm", sm: "sm", md: "md", lg: "lg" }}>
                Email
              </Text>
            </Box>
            <Box>
              <Text color="gray.300" fontSize={{ base: "sm", sm: "sm", md: "md", lg: "md" }}>
                Your email needs to be verified to recieve updates
              </Text>
            </Box>
            <Box>
              <Input
                color="white"
                placeholder="Email"
                defaultValue={user ? user.email : ""}
                readOnly={user && user.emailVerified}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
              {user && user.email && !user.emailVerified && showResendLink && (
                <Button
                  mt="2"
                  colorScheme="secondary"
                  size="xs"
                  disabled={user && user.emailVerified}
                  onClick={(e) => {
                    e.preventDefault();
                    handleResendEmailVerification();
                  }}
                  isLoading={emailUpdateLoading}
                >
                  Verify Now
                </Button>
              )}
              {
                remainingTime > 0 && <Text>&nbsp;{remainingTime}s </Text>
              }
            </Box>
            <Box textAlign={{ sm: "right", md: "center" }} height="30">
              <Button
                disabled={user && user.emailVerified || !showResendLink}
                isLoading={emailUpdateLoading}
                showResendLink
                loadingText="Wait..."
                color="#ffffff"
                _hover={{ color: "#000000", background: "#ffffff" }}
                onClick={handleUpdateEmail}
                variant={"outline"}
                size="sm"
              >
                Update
              </Button>
            </Box>
          </SimpleGrid>

          <SimpleGrid
            width="100%"
            columns={{ base: 1, sm: 1, md: 4, lg: 4 }}
            spacing={2}
            background="brandScheme.500"
            p="5"
            mt="5"
            borderRadius={'5'} borderLeftWidth="2px" borderLeftColor="secondary.500"
          >
            <Box height="30">
              <Text color="white" fontSize={{ base: "sm", sm: "sm", md: "md", lg: "lg" }}>
                Payment Methods
              </Text>
            </Box>
            <Box>
              <Text color="gray.300" fontSize={{ base: "sm", sm: "sm", md: "md", lg: "md" }}>
                Manage your payment methods
              </Text>
            </Box>
            <Box>

            </Box>
            <Box textAlign={{ sm: "right", md: "center" }} height="30">
              <Link to="/app/profile/payment">
                <Button
                  variant={"outline"}
                  color="#ffffff"
                  _hover={{ color: "#000000", background: "#ffffff" }}
                  size="sm"
                >
                  Manage <FaAngleRight size="xs" color="white" />
                </Button>
              </Link>
            </Box>
          </SimpleGrid>
        </Box>
      </OnchainKitProvider>
    </>
  );
}