import { useEffect } from "react";
import { Heading } from "@chakra-ui/react";
import useCountDown from "./useCountDown";

const A_MINUTE = 1000 * 60;
const A_SECOND = 1000;
const AN_HOUR = 1000 * 60 * 60;

export default function Timer({ time, handler }) {
    const endTime = time;
    const [timeLeft, setEndTime] = useCountDown(endTime);

    useEffect(() => {
        if (timeLeft === 0) {
            handler();
        }
    }, [timeLeft, handler]);

    const minutes = String(Math.floor(timeLeft / A_MINUTE) % 60).padStart(2, "0");
    const seconds = String(Math.floor(timeLeft / A_SECOND) % 60).padStart(2, "0");
    const hours = String(Math.floor(timeLeft / AN_HOUR) % 24).padStart(2, "0");

    return (
        <Heading fontSize={{ sm: "lg", md: "2xl" }} color="white">
            {hours}:{minutes}:{seconds}
        </Heading>
    );
}