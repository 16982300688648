import React, { useState } from "react";
import {
  Box, Flex, Button, Stack, Modal, Image, VStack, Select,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton, useMediaQuery, useDisclosure
} from "@chakra-ui/react";
import { Icon } from "@chakra-ui/react";
import { useConnect, useAccount } from 'wagmi'
import { MdArrowForward } from "react-icons/md";
import Logo from "./Logo";
import { connections } from "variables/connections";
import SmartWalletLogo from "assets/images/baseimg.webp";
//import CoinbaseLogo from "assets/images/coinbase.svg";
import MetamaskLogo from "assets/images/metamask.png";
import WalletConnectLogo from "assets/images/walletconnect.png";

const NavBar = (props) => {

  const { isOpen: isConnectOpen, onOpen: onConnectOpen, onClose: onConnectClose } = useDisclosure();
  const [isMdOrSm] = useMediaQuery("(max-width: 960px)");
  const { handleLogout, handleLogin, handleConnect, loginLoading } = props;
  const defaultChain = localStorage.getItem("chainId") !== undefined && localStorage.getItem("chainId") !== null ? localStorage.getItem("chainId") : "";
  const [network, setNetwork] = useState(defaultChain);
  const { isConnected, address } = useAccount();
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const { connectors } = useConnect();

  const ethereumAddressDisplay = (ethereumAddress) => {
    // Assuming ethereumAddress is a string with a valid Ethereum address
    const displayedAddress =
      ethereumAddress.length >= 6
        ? ethereumAddress.slice(0, 3) + ".." + ethereumAddress.slice(-3)
        : ethereumAddress;
    return displayedAddress;
  };

  return (
    <>
      <Modal isOpen={isConnectOpen} onClose={onConnectClose} size={"xs"}>
        <ModalOverlay />
        <ModalContent background="brandScheme.500">
          <ModalHeader color="white" fontSize={{sm: 'md', md: "lg"}}>Choose Your Wallet</ModalHeader>
          <ModalCloseButton color="white" />
          <ModalBody>
            <VStack spacing="3" width="100%" mb="10">
              {connectors.map((connector) => {
                return (
                  <>
                    {
                      connector.id === "coinbaseWalletSDK" &&
                      <Button
                        key={connector.uid}
                        width="100%"
                        leftIcon={<Image src={SmartWalletLogo} boxSize="24px" />}
                        onClick={() => { handleConnect(connector, network); onConnectClose(); }}
                        bg="#0052ff"
                        color="white"
                        _hover={{ bg: '#003fcc' }}
                        _active={{ bg: '#002fb8' }}
                        borderRadius="8px"
                        padding="12px 20px" 
                        fontSize={{sm: 'sm', md: "md"}}>
                        Connect Smart Wallet
                      </Button>
                    }

                    {/*
                    connector.id === "coinbaseWalletSDK" &&
                    <Button
                      key={connector.uid}
                      width="100%"
                      leftIcon={<Image src={SmartWalletLogo} boxSize="24px" />}
                      onClick={() => { handleConnect(connector, network); onConnectClose(); }}
                      bg="#0052ff"
                      color="white"
                      _hover={{ bg: '#003fcc' }}
                      _active={{ bg: '#002fb8' }}
                      borderRadius="8px"
                      padding="12px 20px" >
                      Connect {connector.name} {console.log(connector)}
                    </Button>*/
                    }

                    {
                      connector.name === "MetaMask" &&
                      <Button
                        key={connector.uid}
                        width="100%"
                        leftIcon={<Image src={MetamaskLogo} boxSize="24px" />}
                        onClick={() => { handleConnect(connector, network); onConnectClose(); }}
                        bg="#f6851b"
                        color="white"
                        _hover={{ bg: '#e07617' }}
                        _active={{ bg: '#d06613' }}
                        borderRadius="8px"
                        padding="12px 20px"
                        fontSize={{sm: 'sm', md: "md"}}
                      >
                        Connect {connector.name}
                      </Button>
                    }

                    {
                      connector.name === "WalletConnect" &&
                      <Button
                        key={connector.uid}
                        leftIcon={<Image src={WalletConnectLogo} boxSize="24px" />}
                        onClick={() => { handleConnect(connector, network); onConnectClose(); }}
                        width="100%"
                        bg="#3b99fc"
                        color="white"
                        _hover={{ bg: '#337acc' }}
                        _active={{ bg: '#2b66b0' }}
                        borderRadius="8px"
                        padding="12px 20px"
                        fontSize={{sm: 'sm', md: "md"}}
                      >
                        Connect {connector.name}
                      </Button>
                    }
                  </>)
              })}
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>

      <NavBarContainer {...props}>
        {!isMdOrSm && (
          <Logo
            w="100px"
            color={["white", "white", "primary.500", "primary.500"]}
          />)}
        <MenuToggle toggle={toggle} isOpen={isOpen} />
        <MenuLinks
          isOpen={isOpen}
          onConnectOpen={onConnectOpen}
          handleLogout={handleLogout}
          isConnected={isConnected}
          address={address}
          ethereumAddressDisplay={ethereumAddressDisplay}
          loginLoading={loginLoading}
          setNetwork={setNetwork}
          network={network}
        />
      </NavBarContainer>
    </>
  );
};

const CloseIcon = () => (
  <svg width="24" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
    <title>Close</title>
    <path
      fill="white"
      d="M9.00023 7.58599L13.9502 2.63599L15.3642 4.04999L10.4142 8.99999L15.3642 13.95L13.9502 15.364L9.00023 10.414L4.05023 15.364L2.63623 13.95L7.58623 8.99999L2.63623 4.04999L4.05023 2.63599L9.00023 7.58599Z"
    />
  </svg>
);

const MenuIcon = () => (
  <svg
    width="24px"
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    fill="white"
  >
    <title>Menu</title>
    <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
  </svg>
);

const MenuToggle = ({ toggle, isOpen }) => {
  return (
    <Box display={{ base: "block", md: "none" }} onClick={toggle}>
      {isOpen ? <CloseIcon /> : <MenuIcon />}
    </Box>
  );
};

const MenuLinks = ({
  isOpen,
  onConnectOpen,
  handleLogout,
  isConnected,
  address,
  ethereumAddressDisplay,
  loginLoading,
  setNetwork,
  network
}) => {

  return (
    <Box
      display={{ base: isOpen ? "block" : "none", md: "block" }}
      flexBasis={{ base: "100%", md: "auto" }}
    >
      <Stack
        spacing={8}
        align="center"
        justify={["center", "space-between", "flex-end", "flex-end"]}
        direction={["column", "row", "row", "row"]}
        pt={[4, 4, 0, 0]}
      >
        {isConnected && (
          <Button
            onClick={handleLogout}
            className="banner-btn"
            size="md"
            rounded={"full"}
            color="white"
            fontFamily="ManropeLight"
            background="brand.500"
            _hover={{
              bg: ["primary.100", "primary.100", "primary.600", "primary.600"],
            }}
          >
            {ethereumAddressDisplay(address)}
            <Icon
              as={MdArrowForward}
              width="20px"
              height="20px"
              color="white"
            />
          </Button>
        )}

        {!isConnected && (
          <>
            <Select
              placeholder="Select Network"
              fontSize={{ sm: "sm", md: "md" }}
              defaultValue={network}
              onChange={(e) => {
                setNetwork(e.target.value);
              }}
            >
              {connections.map((connection, key) => (
                <option key={key} value={connection.chainId}>
                  {connection.chainName}
                </option>
              ))}
            </Select>
            <Button
              onClick={onConnectOpen}
              isLoading={loginLoading}
              loadingText="Wait.."
              className="banner-btn"
              fontSize={{ sm: "sm", md: "md" }}
              size="md"
              pl="7"
              pr="7"
              rounded={"full"}
              color="white"
              background="brand.500"
              fontFamily="ManropeLight"
              _hover={{
                bg: ["primary.100", "primary.100", "primary.600", "primary.600"],
              }}
            >
              Connect
              <Icon
                as={MdArrowForward}
                width="20px"
                height="20px"
                color="white"
              />
            </Button>
          </>
        )}
      </Stack>
    </Box>
  );
};

const NavBarContainer = ({ children, ...props }) => {
  return (
    <Box
      width={{ sm: "96%", md: "100%" }}
      background="#01001e"
      borderRadius="10px"
      p="2"
      mx="2"
      mt="2"
    >
      <Flex
        as="nav"
        align="center"
        justify="space-between"
        wrap="wrap"
        w="100%"
        pt={2}
        pb={2}
        pl={3}
        pr={3}
        bg={["primary.500", "primary.500", "primary.500", "primary.500"]}
        color={["#fff", "#fff", "#fff", "#fff"]}
        {...props}
      >
        {children}
      </Flex>
    </Box>
  );
};

export default NavBar;
