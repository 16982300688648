import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  Box,
  Text,
  SimpleGrid,
  Button,
  Icon,
  Heading,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Table,
  Thead,
  Tbody,
  Tag,
  Tr,
  Th,
  Td,
  TableContainer,
  Skeleton,
  SkeletonText,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Stack,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { FiArrowLeft } from "react-icons/fi";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { MdDelete, MdEditNote } from "react-icons/md";
import Card from "components/card/Card.js";
import { GetRoles, DeleteRole } from "services/roleService";

export default function Roles() {
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [loading, setLoading] = useState(false);

  const [roles, setRoles] = useState([]);
  const [roleId, setRoleId] = useState("");
  const userState = useSelector((state) => state?.user?.value);
  const token = userState.hasOwnProperty("token") ? userState.token : null;
  const priviledges = userState.hasOwnProperty("priviledges")
    ? userState.priviledges
    : [];

  const toast = useToast();

  const getRoles = async () => {
    try {
      /*if (!priviledges.includes("Manage Roles")) {
        toast({
          title: "You are not allowed",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }*/
      setLoading(true);
      const response = await GetRoles(token);
      setLoading(false);
      if (response.error) {
        toast({
          title: response.error || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (!response.success) {
        toast({
          title: response.message || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (response.success) {
        setRoles(response?.data);
      }
    } catch (error) {
      setLoading(false);
      toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
    }
  };

  const deleteRole = async () => {
    try {
      setLoading(true);
      if (!priviledges.includes("Manage Roles")) {
        toast({
          title: "You are not allowed",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      const response = await DeleteRole(roleId, token);
      setLoading(false);
      if (response.error) {
        toast({
          title: response.error || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (!response.success) {
        toast({
          title: response.message || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (response.success) {
        toast({
          title: response.message || "Role deleted successfully",
          status: "success",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        getRoles();
        onClose();
      }
    } catch (error) {
      toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
      setLoading(false);
    }
  };

  const getPriviledges = (priviledge) => {
    const priviledges =
      priviledge !== null && priviledge !== "" ? JSON.parse(priviledge) : [];
    return priviledges.map((priv, index) => {
      return (
        <Tag fontSize="13" key={index} background="brand.500" color="white" ml="1">
          {priv}
        </Tag>
      );
    });
  };

  useEffect(() => {
    getRoles();
  }, []);

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} size={"md"}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Icon
              as={FiArrowLeft}
              onClick={onClose}
              width="20px"
              height="20px"
            />
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <SimpleGrid>
              <Box textAlign="center">
                <Heading
                  fontSize={{ base: "md", sm: "lg", md: "lg", lg: "xl" }}
                >
                  Are you sure you want to delete this?
                </Heading>
              </Box>
            </SimpleGrid>
          </ModalBody>

          <ModalFooter>
            <Button
              variant="primary"
              background="secondary.500"
              isLoading={loading}
              loadingText="Please Wait"
              onClick={(e) => {
                e.preventDefault();
                deleteRole();
              }}
            >
              Proceed
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Box pt={{ base: "30px", md: "40px", xl: "40px" }} width="100%" mb="70">

        <SimpleGrid
          columns={{ sm: 1, md: 2 }}
          w="100%"
          p="2"
          spacing="2"
          mb="4"
        >
          <Box>
            {" "}
            <Text
              color={"brand.500"}
              fontSize="18px"
              mb="4px"
              fontWeight="600"
              lineHeight="100%"
            >
              Roles
            </Text>
          </Box>
          <Box textAlign="right">

          </Box>
        </SimpleGrid>

        <Box width="100%" textAlign="right">
          <Button
            colorScheme="teal"
            background="brand.500"
            size="sm"
            onClick={() => {
              navigate("/admin/settings/addrole");
            }}
          >
            Add Role
          </Button>
        </Box>

        {loading ? (
          <Loading />
        ) : (
          <TableContainer>
            <Table variant='striped' fontSize={{ sm: "md", md: "lg" }}>
              <Thead>
                <Tr>
                  <Th>Role Name</Th>
                  <Th>Role Abbreviation</Th>
                  <Th>Priviledges</Th>
                  <Th>Action</Th>
                </Tr>
              </Thead>
              <Tbody>
                {roles !== null &&
                  roles.map((role) => (
                    <Tr>
                      <Td>{role.roleName}</Td>
                      <Td>{role.roleAbbreviation}</Td>
                      <Td>{getPriviledges(role.priviledges)}</Td>
                      <Td>
                        {" "}
                        <Menu>
                          <MenuButton
                            as={Button}
                            variant="primary"
                            background="brand.500"
                            rightIcon={<ChevronDownIcon />}
                          >
                            Action
                          </MenuButton>
                          <MenuList>
                            <MenuItem
                              onClick={() => {
                                navigate("/admin/settings/addrole/" + role._id, {
                                  state: { roleId: role._id },
                                });
                              }}
                            >
                              Edit &nbsp;{" "}
                              <Icon
                                as={MdEditNote}
                                width="20px"
                                height="20px"
                                color="brand.500"
                              />{" "}
                            </MenuItem>
                            <MenuItem
                              onClick={() => {
                                setRoleId(role._id);
                                onOpen();
                              }}
                            >
                              Delete &nbsp;{" "}
                              <Icon
                                as={MdDelete}
                                width="20px"
                                height="20px"
                                color="brand.500"
                              />{" "}
                            </MenuItem>
                          </MenuList>
                        </Menu>
                      </Td>
                    </Tr>
                  ))}
              </Tbody>
            </Table>
          </TableContainer>
        )}
      </Box>
    </>
  );
}

const Loading = () => {
  return (
    <>
      <Card align="center" p="5px">
        <Stack>
          <Skeleton height="5px" />
          <SkeletonText mt="2" noOfLines={3} spacing="3" />
          <Skeleton height="5px" />
        </Stack>
      </Card>

      <Card align="center" p="5px" mt="5">
        <Stack>
          <Skeleton height="5px" />
          <SkeletonText mt="2" noOfLines={3} spacing="3" />
          <Skeleton height="5px" />
        </Stack>
      </Card>
    </>
  );
};
