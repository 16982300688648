import React, { useEffect, useState } from "react";
import {
  Box,
  Heading,
  SimpleGrid,
  Skeleton,
  SkeletonText,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Text,
  Button,
  useToast,
  useDisclosure
} from "@chakra-ui/react";
import Paginate from 'components/navigation/Paginate';
import TradeTable from "views/admin/trades/components/TradeTable";
import DateFilter from "components/filter/DateFilter";
import { GetBuyTrades } from "services/tradeService";
import { useSelector } from "react-redux";
const { DateTime } = require("luxon");

export default function ActiveBuyTrades() {
  const userState = useSelector((state) => state?.user?.value);
  const token = userState.hasOwnProperty("token") ? userState.token : null;
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [date, setDate] = useState(DateTime.now().toFormat('yyyy-MM-dd'));
  const [loading, setLoading] = useState(false);
  const [trades, setTrades] = useState(null);
  const [tradeId, setTradeId] = useState(null);

  const [pageCount, setPageCount] = useState(0);
  const [sortOrder, setSortOrder] = useState("desc");
  const [from, setFrom] = useState(0);
  const [limit, setLimit] = useState(12);
  const [orderBy, setOrderBy] = useState("createdAt");
  const [finished, setFinished] = useState(false);

  const getActiveBuyTrades = async () => {
    try {
      const pagination = {
        from,
        limit,
        orderBy,
        sortOrder
      };
      const filter = { disputed: true, settled: false, tradeAdmin:null}
      setLoading(true);
      const response = await GetBuyTrades(filter, pagination, token);
      setLoading(false);
      if (!response) {
        return;
      }
      if (response.error) {
        toast({
          title: response.error || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (!response.success) {
        toast({
          title: response.message || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (response.success === true) {
        setTrades(response.data.trades);
        setPageCount(response.data.count / limit);
        if (response.data?.trades.length === 0) {
          setFinished(true);
        }
        return;
      }
    } catch (error) {
      toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
      setLoading(false);
    }
  };


  useEffect(() => {
    if (token !== null) {
      getActiveBuyTrades();
    } else {
      setTrades(null);
    }
  }, [token]);


  return (
    <>
    <Modal isOpen={isOpen} onClose={onClose} size={'sm'} background="brand.500">
        <ModalOverlay />
        <ModalContent background="brand.500">
          <ModalHeader color="white">Assign Dispute</ModalHeader>
          <ModalCloseButton color="white" />
          <ModalBody>

            <Box style={{ marginTop: '10px', marginBottom: '20px' }} textAlign="center">
             
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>


      <Box pt={{ base: "30px", md: "40px", xl: "40px" }} p="3" width="100%">
        <Box width="100%" mt="5" mb="5">
          <Heading color="brand.500" size="md">
            Disputed Buy Trades
          </Heading>
        </Box>
        <DateFilter date={date} setDate={setDate} initFilterSubmit={getActiveBuyTrades} />
        {loading ? (
          <LoadingTrades />
        ) : (
          <TradeTable trades={trades} pageCount={pageCount} tradeType="Active Buy Trades" action={"Assign"} onOpen={onOpen}  setTradeId={setTradeId} />
        )}
        <Box mt="4" width="100%" background="brand.500" p="5" borderRadius="5">
          <Paginate pageCount={pageCount} setFrom={setFrom} getRecords={getActiveBuyTrades} />
        </Box>
      </Box>
    </>
  );
}

const LoadingTrades = () => {
  return (
    <>
      <SimpleGrid
        width="100%"
        mt="10"
        mb="20"
        columns={{ sm: "1", md: "2", lg: "3" }}
        spacing="2"
      >
        <Box>
          <Skeleton height="5px" />
          <SkeletonText mt="2" noOfLines={3} spacing="3" />
          <Skeleton height="5px" />
        </Box>
        <Box>
          <Skeleton height="5px" />
          <SkeletonText mt="2" noOfLines={3} spacing="3" />
          <Skeleton height="5px" />
        </Box>
        <Box>
          <Skeleton height="5px" />
          <SkeletonText mt="2" noOfLines={3} spacing="3" />
          <Skeleton height="5px" />
        </Box>
      </SimpleGrid>
    </>
  );
};
