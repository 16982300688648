import { FallbackProvider, JsonRpcProvider, JsonRpcSigner, BrowserProvider } from 'ethers';

// Convert a viem Wallet Client to an ethers.js Provider
function clientToProvider(client) {
  const { chain, transport } = client;
  const network = {
    chainId: chain.id,
    name: chain.name,
    ensAddress: chain.contracts?.ensRegistry?.address,
  };
  if (transport.type === 'fallback') {
    const providers = transport.transports.map(
      ({ value }) => new JsonRpcProvider(value?.url, network)
    );
    if (providers.length === 1) return providers[0];
    return new FallbackProvider(providers);
  }
  const provider = new BrowserProvider(transport, network);
  return provider;
}

// Convert a viem Wallet Client to an ethers.js Signer
function clientToSigner(client, account) {
  const { chain, transport } = client;
  const network = {
    chainId: chain.id,
    name: chain.name,
    ensAddress: chain.contracts?.ensRegistry?.address,
  };
  const provider = new BrowserProvider(transport, network);
  const signer = new JsonRpcSigner(provider, account.address);
  return signer;
}

/** Hook to get a viem Wallet Client's ethers.js Signer */
export function useEthersSigner(client, account) {
  if (!client || !account || !account.address) return
  return clientToSigner(client, account)
}

/** Hook to get a viem Client's ethers.js Provider */
export function useEthersProvider(client) {
  if (!client) return
  return clientToProvider(client)
}
