import React from "react";
import {
  SimpleGrid,
  HStack,
  VStack,
  Box,
  Text,
  Heading,
  Stack,
  Button,
  Center,
  Image,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { Icon } from "@chakra-ui/react";
import { MdArrowForward } from "react-icons/md";
import Illustration from "assets/images/illustration.png";
import NavBar from "components/navigation/NavBar.js";

const HeadingArea = () => {
  return (
    <Box
      h="200px"
      p={5}
      w={{ base: "100%", md: "100%", lg: "100%" }}
      background="none"
    >
      <Center width="100%">
        <VStack align="stretch" minH="40px">
          <BannerText />
        </VStack>
      </Center>
    </Box>
  );
};

const BannerText = () => {
  return (
    <Box width="100%" minH="30px" background="none">
      <Box width="100%">
        <Heading
          mt="8"
          color="white"
          textAlign="start"
          as="h1"
          fontSize={{ base: "xl", sm: "2xl", md: "4xl", lg: "4xl" }}
          fontFamily="Spantran"
          fontWeight="500"
        >
          Your Gateway to Decentralized Crypto and Fiat Trading
        </Heading>
        <Text
          mt={3}
          mb={1}
          color="white"
          textAlign="start"
          fontSize={{ base: "md", sm: "lg", md: "xl", lg: "xl" }}
          fontFamily="ManropeLight"
        >
          Buy and Sell Crypto Securely with Decentralized Escrow across Multiple Blockchain Networks

        </Text>
      </Box>
      <Stack spacing={2} direction={"row"} mt="7" align="center">

        <Link to="/app/ads">
          <Button
            className="banner-btn"
            rounded={"full"}
            color="white"
            fontFamily="ManropeLight"
            background="brand.500"
            fontSize={{ sm: "sm", md: "md" }}
            _hover={{
              bg: ["primary.100", "primary.100", "primary.600", "primary.600"],
            }}
          >
            Launch App &nbsp; <Icon as={MdArrowForward} width="20px" height="20px" color="white" />
          </Button>
        </Link>
        {/*
        <Link to="/auth/login">
          <Button rounded={"full"} px={6}>
            Login
          </Button>
        </Link>*/}
      </Stack>
    </Box>
  );
};


const Banner = () => {
  return (
    <Box
      w="100%"
      background="transparent"
      pb={{ sm: 0, md: 7 }}
    >
      <HStack background="none">
        <NavBar zIndex="10" />
      </HStack>
      <SimpleGrid
        columns={{ sm: 1, md: 2 }}
        minH={{ base: "250px", sm: "250px", md: "400px" }}
        p={{sm: 0, md: 20}}
        background="none"
      >
        <HeadingArea />
        <Box height="400" display={{ sm: "none", md: "block" }}>
          <Center width="100%" height="400">
            <Image src={Illustration} objectFit="cover" />
          </Center>
        </Box>
      </SimpleGrid>
    </Box>
  );
};

export default Banner;