import { Outlet } from "react-router-dom";
import {
  Box
} from "@chakra-ui/react";
import React from "react";

const TradeLayout = () => {
  return (
    <Box p="4" width="100%">
      <Outlet />
    </Box>
  );
};

export default TradeLayout;
